import React, {Component} from "react";
import {Switch} from "react-router-dom";
import PrivateRoute from "./router/privateRoute";
import PublicRoute from "./router/publicRoute";
import history from "./history";
import EduResearchPortal from './components/eduResearchPortal';
import LandingPage from './components/eduResearchLandingPage';
import AccountExpired from "./components/AccountExpired";

class EduResearch extends Component {
    render() {
        return (
            <div>
                <Switch history={history}>
                    <PrivateRoute exact path="/account.expired" component={AccountExpired} />
                    <PrivateRoute path='/portal' component={EduResearchPortal}/>
                    <PublicRoute path='/' component={LandingPage}/>
                </Switch>
            </div>
        );
    }
}

export default EduResearch;
