import React from "react";
import {Grid } from "@material-ui/core";
import Container from '@material-ui/core/Container';


export const Content = props => (
    <React.Fragment>

        <div className={props.classes.landingPageFeatures}>

            <div className={props.classes.landingPageFeaturesTitleContainer}>
                <div className={props.classes.landingPageFeaturesTitle}>
                    Trail Expired
                </div>
                <div className={props.classes.landingPageFeatureContentParagraph}>
                    Your account trail period is expired. To active your account, Please contact Admin.
                </div>
            </div>
        </div>

         <div className={props.classes.footer} >  

            <Grid container
              direction="row"
              justify="flex-start"
              >
                <Grid item xs={12} sm={12}>
                    <div className={props.classes.footerContent} >
                        <Container >
                         © 2020 Terawe Corp. All Rights Reserved. |   <a href={`mailto:${"mxrsup@terawe.com"}`}>mxrsup@terawe.com</a>
                        </Container>
                    </div>
                </Grid>
            </Grid>
        </div>
    </React.Fragment>
);