import { UserAgentApplication, Logger, LogLevel } from "msal";

export const requiresInteraction = errorMessage => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }

    return (
        errorMessage.indexOf("consent_required") > -1 ||
        errorMessage.indexOf("interaction_required") > -1 ||
        errorMessage.indexOf("login_required") > -1
    );
};

export const fetchMsGraph = async (url, accessToken) => {
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    return response.json();
};

export const fetchSubscription = async (url, accessToken) => {
    let subscriptions = {value:[]}
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    }).then(res => res.json())
        await Promise.all(response.value.map(async(subscription)=>{
            let graph_profile_id = sessionStorage.getItem('graph_profile_id');
            let roleAssignmentsURL = `https://management.azure.com/subscriptions/${subscription.subscriptionId}/providers/Microsoft.Authorization/roleAssignments?api-version=2015-07-01`;
            var tokenResponse = await msalApp.acquireTokenSilent(GRAPH_REQUESTS.ACTIVE_DIRECTORY)
            let roleAssignmentRes = await fetch(roleAssignmentsURL, { headers: { Authorization: `Bearer ${tokenResponse.accessToken}`} }).then(res => res.json());
            if(roleAssignmentRes.value.length > 0 ){
                let roleAssignmentsFiltered = roleAssignmentRes.value.filter(roleAssignments => roleAssignments.properties.principalId === graph_profile_id);
                if(roleAssignmentsFiltered.length === 0){
                    //Account admin - Account Admin wont be available in roleassignments hence results in array of length 0
                    subscriptions.value.push(subscription);
                }else{
                    for(let i=0; i<roleAssignmentsFiltered.length ; i++){
                        let roleURL = `https://management.azure.com/${roleAssignmentsFiltered[i].properties.roleDefinitionId}?api-version=2015-07-01`;
                        let roleRes = await fetch(roleURL, { headers: { Authorization: `Bearer ${tokenResponse.accessToken}`} }).then(res => res.json());
                        if(roleRes.properties.roleName === 'Owner' || roleRes.properties.roleName === 'Contributor'){
                            subscriptions.value.push(subscription);
                            break;
                        }
                    }
                }
            }
        }))
    return subscriptions;
}

export const deployPostMethod = async (url, accessToken, template) => {

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(template),
    });

    return response.json();
};

export const fetchMsResourceGroup = async (url, accessToken) => {
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        },
    });

    return response.json();
};

export const deployTemplate = async (url, accessToken, template) => {
    const response = await fetch(url, {
        method: "put",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(template),
    });
    return response.json();
};

export const deployStatus = async (url, accessToken) => {
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
        },
    });

    return response.json();
};

export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11;
};

export const GRAPH_SCOPES = {
    OPENID: "openid",
    PROFILE: "profile",
    USER_READ: "User.Read",
    USER_IMPERSONATION: "https://management.azure.com/user_impersonation",
    // USER_IMPERSONATION: "api://a42ea19a-7c15-41ea-8276-215aa58c0f17/user_impersonation",
    // USER_IMPERSONATION: "user_impersonation",
};

export const GRAPH_ENDPOINTS = {
    ME: "https://graph.microsoft.com/v1.0/me",
    AZURE: "https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize",
    SUBSCRIPTION: "https://management.azure.com/subscriptions?api-version=2018-02-01"
};

export const GRAPH_REQUESTS = {
    LOGIN: {
        scopes: [
            GRAPH_SCOPES.OPENID,
            GRAPH_SCOPES.PROFILE,
            GRAPH_SCOPES.USER_READ
        ]
    },
    ACTIVE_DIRECTORY: {
        scopes: [GRAPH_SCOPES.USER_IMPERSONATION]
    },
    AZURE_SCOPE: {
        scopes: [
            GRAPH_SCOPES.USER_IMPERSONATION
        ]
    },
};
// https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=id_token&scope=openid%20profile%20User.Read&client_id=4a306d7b-6786-40ae-8d0d-8e9a9162d0bf&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F&state=15b0c20d-6fc5-47e4-92c5-4beab4a6544a&nonce=0d3088c8-0954-40ee-a1ba-e69751110640&client_info=1&x-client-SKU=MSAL.JS&x-client-Ver=1.2.1&client-request-id=92bfaeaf-9c03-4738-ab77-8fad14ba54fc&response_mode=fragment

// https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?
//client_id=c44b4083-3bb0-49c1-b47d-974e53cbdf3c
//&response_mode=form_post
//&response_type=code+id_token
//&scope=https%3a%2f%2fmanagement.core.windows.net%2f%2fuser_impersonation+openid+email+profile
//&state=OpenIdConnect.AuthenticationProperties%3d4iHQH7yvditHouJnOq6mH8bjuzsY1L8oHkqoDE1ZhjF4RYF5UYKTBupxYPKh3VboUhJ-oVJITl12bneX4V_vNg03pa9yZbR4IlDGpmB9AntJUi6M-23FRfA9XjhdwOQ5rHLAwbmeqiQcz1om3Oe0iguPI3vva73ROBSmA6aDL_7t6jCqjHTfH6lPIO-5F2ueNfrbYYYzgtQPKuZh-eICxC2jbIduoJH36Ud3Mf-T3RRGR6ZNcNo34aqOWpzJ7FfPen0ZmmZk0Xx3Gy4opH9w0HS36JzhmEin-rp8XyAqlAIqPvKfoTMTL5uYuNFNuyS3FNIbLd0fSmWmS58uj4gvZUY4dcE0PvND8OWsBTrAAO1QoUP-rUkX4vY9qSTTrSV_Sj0rJWQOPep7cVgEsBzP7BWzfxADKhdC6AAqMRo7wa3S_E8cJ1rQP-kmy20bSxnhs1Yp4gTukmF65vj0ggyn1uNu2RaZdN2PyLEJFPSIrJPHegF0ygfa0FpUUxI3gNNBn0W3WK4wWewhoPWIWBYdznBC1YaymoJLfU7psgkjSlU
//&nonce=637212089353668357.ZTQ5ZTNhYTUtNzk4OC00NDc4LWFhNGUtOGM1OTBiY2VkYWEyMzBkOGI0NGUtNzlhOC00MWRjLWE4MWUtOTJkOGYyYWM0NDhl
//&redirect_uri=https%3a%2f%2fportal.azure.com%2fsignin%2findex%2f%3ffeature.refreshtokenbinding%3dtrue%26feature.snivalidation%3dtrue%26feature.usemsallogin%3dtrue
//&site_id=501430
//&client-request-id=6fa86b6f-2898-46b3-b271-66f8ba667bbe
//&x-client-SKU=ID_NET
//&x-client-ver=1.0.40306.1554

export const msalApp = new UserAgentApplication({
    auth: {
        clientId: "a42ea19a-7c15-41ea-8276-215aa58c0f17", // only for AD Users
        // clientId: "f7e61760-2a02-4d3a-9161-26dc6dee937f", // All MS Users
        authority: "https://login.microsoftonline.com/common",
        validateAuthority: true,
        postLogoutRedirectUri: window.location.href ,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: isIE()
    },
    system: {
        navigateFrameWait: 0,
        logger: new Logger((logLevel, message) => {
            console.log(message);
        }, {
            level: LogLevel.Verbose,
            piiLoggingEnabled: true
        }),
    }
});
