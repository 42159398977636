import {Grid} from "@material-ui/core";
import React from "react";
import SearchIcon from '@material-ui/icons/Search';
import TextField from "@material-ui/core/TextField";

export const Headline = props => (
    <React.Fragment>
        <div className={props.classes.headingAppBarSpace} />
        <Grid container
            direction="row"
            justify="flex-start"
            alignItems="center">
            <Grid item xs={6} sm={6}>
                <div className={props.classes.heading}>
                    Deployment List
                </div>
            </Grid>
            <Grid item xs={6} sm={6}>
                <div className={props.classes.blueprintSearchBarDiv}>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <SearchIcon />
                        </Grid>
                        <Grid item>
                        
                            <TextField
                                id="input-with-icon-grid"
                                label="Search by name"
                                onChange={event => {
                                    props.handleSearch(event.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    </React.Fragment>
);