import React from "react";
import {CssBaseline} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {compose} from "@material-ui/system";
import { dashboardStylesCore } from "../../styles"
import { Headline } from "./headline";
import { Content } from "./content";
import PropTypes from "prop-types";
import AuthProvider from "../Authentication/AuthProvider";
import {Redirect, withRouter} from "react-router-dom";

class Index extends React.Component {

    static propTypes = {
        account: PropTypes.object,
        accountStatus: PropTypes.string,
        emailMessages: PropTypes.object,
        error: PropTypes.string,
        graphProfile: PropTypes.object,
        onSignIn: PropTypes.func.isRequired,
        onSignOut: PropTypes.func.isRequired,
        onRequestEmailToken: PropTypes.func.isRequired,
        fetchData:PropTypes.bool
    };

    constructor(props) {
        super(props);
        
        this.state = {
            messageState: "",
            open: false,
            firstName: "",
            lastName: "",
            contact: "",
            email: "",
            JobTitle:"",
            Organization: "",
            TypeofIndustry: "",
            Location: "",
            Purpose: "",
            error: false,
            emailError: true,
            loadingState: false,
            check: false,
            alertOpen: true,
        };
    }
    handleClickOpen = () => {
        this.setState({ open: true });

    };
    handleAlertClose = () => {
        this.setState({ alertOpen: false });
    }

    handleAlertOpen = () => {
        this.setState({ alertOpen: true, messageState: "" });
    }
    
    handleClickClose = () => {
        this.setState({
            messageState: "",
            open: false,
            firstName: "",
            lastName: "",
            contact: "",
            email: "",
            JobTitle:"",
            Organization: "",
            TypeofIndustry: "",
            Location: "",
            Purpose: "",
            error: false,
            emailError: true,
            loadingState: false,
            check:false });
    };

    handleClose = async () => {
        this.setState({ loadingState: true });
        let temp = (this.state.email).split("@");
        const bodyParams = {
            "email": this.state.email,
            "name": this.state.firstName + " " + this.state.lastName,
            "loginTime": Date.now(),
            "organization": temp[1],
            "Location": "--",
            "purpose": this.state.Purpose
        };
        await fetch("/api/customer/user", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyParams),
        }).then(res => {
            return res.text();
        }).then(val => {
            if (val) {
                const tempData = JSON.parse(val);
                let status = tempData.status;

                if (tempData.trailDetails)
                    status = (!(tempData.status === "Active" && tempData.trailDetails.status === "Active") ? status : ((Date.now()) <= (tempData.trailDetails.startTime + (tempData.trailDetails.trailPeriod * 10))) ? status : "TrailExpired")

                if (status!== "Active")
                    sessionStorage.clear();

                this.setState({
                    messageState: status
                })

                /*  const response = JSON.parse(val);
                  if (response.status === "Pending") {
                      this.setState({ messageState: "Pending"});
                  }
                  else if (response.status === "Active") {
                      this.setState({ messageState: "Active"});
                  }*/
                this.setState({ loadingState: false });
                this.setState({ alertOpen: true });
            }
        })


         this.setState({  
             open: false,
             firstName: "",
             lastName: "",
             contact: "",
             email: "",
             JobTitle:"",
             Organization: "",
             TypeofIndustry: "",
             Location: "",
             Purpose: "",
             error: false,
             emailError: true,
             loadingState: false,
             check:false });
    };



    handleChange = (firstName1, lastName1, JobTitle1, Organization1, TypeofIndustry1, email1,contact1,Location1,Purpose1) => {
        
        if (firstName1 !== "")
            this.setState({ firstName: firstName1 })
        if (lastName1 !== "")
            this.setState({ lastName: lastName1 })
        if (contact1 !== "")
            this.setState({ contact: contact1 })
        if (email1 !== "") {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            this.setState({ email: email1 });
            this.setState({ emailError: pattern.test(email1) });
        }
            
        if (JobTitle1 !== "")
            this.setState({ JobTitle: JobTitle1 })
        if (Organization1 !== "")
            this.setState({ Organization: Organization1 })
        if (TypeofIndustry1 !== "")
            this.setState({ TypeofIndustry: TypeofIndustry1 })
        if (Location1 !== "")
            this.setState({ Location: Location1 })
        if (Purpose1 !== "")
            this.setState({ Purpose: Purpose1 })

        }

    handleSubmit = () => {
        if (this.state.emailError === false || this.state.firstName === "" || this.state.lastName === "" || this.state.email === "" || this.state.Purpose === "") {

            this.setState({ error: true });
        }
        else {
            this.handleClose().then();
        }
    }



    render() {

        return (
            <React.Fragment>
                {
                    (!this.props.accountStatus) || this.props.accountStatus === "Pending" || this.props.accountStatus === "TrailExpired"|| this.props.accountStatus === "Not Found" || !sessionStorage.getItem('msal.idtoken') ? (
                    <div>
                        <CssBaseline />
                            <main className={this.props.classes.layout}>
                                <Headline handleAlertOpen={this.handleAlertOpen} classes={this.props.classes} onSignIn={this.props.onSignIn} />
                                <Content fetchData={this.props.fetchData} handleAlertClose={this.handleAlertClose} alertState={this.state.alertOpen} messageState={this.state.messageState === "" ? this.props.accountStatus : this.state.messageState} loadingState={this.state.loadingState} classes={this.props.classes} emailError={this.state.emailError} error={this.state.error} email={this.state.email} Purpose={this.state.Purpose} firstName={this.state.firstName} lastName={this.state.lastName} contact={this.state.contact} handleChange={this.handleChange} handleSubmit={this.handleSubmit} state={this.state.open} handleClickOpen={this.handleClickOpen} handleClickClose={this.handleClickClose} />
                        </main>
                    </div>
                ) : (
                    <Redirect to={`/portal`} />
                )
            }
            </React.Fragment>
        );
    }


}

export default AuthProvider(compose(
    withRouter,
    withStyles(dashboardStylesCore)
)(Index));
