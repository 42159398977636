import React from "react";
import {CssBaseline} from "@material-ui/core";
import {Redirect, withRouter} from 'react-router-dom';
import {withStyles} from "@material-ui/core/styles";
import {compose} from "@material-ui/system";
import { dashboardStylesCore } from "../../styles"
import { Headline } from "./headline";
import { Content } from "./content";
import PropTypes from "prop-types";
import AuthProvider from "../Authentication/AuthProvider";

class Index extends React.Component {

    static propTypes = {
        account: PropTypes.object,
        emailMessages: PropTypes.object,
        error: PropTypes.string,
        graphProfile: PropTypes.object,
        onSignIn: PropTypes.func.isRequired,
        onSignOut: PropTypes.func.isRequired,
        onRequestEmailToken: PropTypes.func.isRequired
    };


    render() {
        return(
            <React.Fragment>
            {
                !this.props.account && (sessionStorage.getItem("accountStatus") && sessionStorage.getItem("accountStatus") === "TrailExpired")? (
                    <div>
                        <CssBaseline/>
                        <main className={this.props.classes.layout}>
                            <Headline classes={this.props.classes} onSignIn={this.props.onSignIn}/>
                            <Content classes={this.props.classes} />
                        </main>
                    </div>
                ) : (
                    <Redirect to={`/portal`} />
                )
            }
            </React.Fragment>
        );
    }
}

export default AuthProvider(compose(
    withRouter,
    withStyles(dashboardStylesCore)
)(Index));
