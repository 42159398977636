import React, {useEffect, useRef} from "react";
import {CssBaseline, Paper, Grid, FormControl, RadioGroup, FormControlLabel} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {dashboardStyles, BlueRadio, NextButton, BackButton} from "../../styles";
import { Headline } from "./headline";
import subscriptionImage from "../../assert/images/subscription.png"
import {Redirect} from "react-router-dom";
import {
    fetchMsGraph,
    GRAPH_ENDPOINTS,
    GRAPH_REQUESTS,
    isIE,
    msalApp,
    requiresInteraction
} from "../Authentication/auth-utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import history from '../../history';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function Index() {
    const classes = dashboardStyles();
    const subscriptionId = localStorage.getItem("sid");

    const [subscriptionIdValue, setSubscriptionIdValue] = React.useState(subscriptionId && subscriptionId !== "undefined" && subscriptionId !== "null" ? subscriptionId : "");
    const [subscriptions, setSubscriptions] = React.useState([]);
    const [next, setNext] = React.useState(false);
    const [back, setBack] = React.useState(false);
    const [subscriptionFlag, setSubscriptionFlag] = React.useState(true);

    const [pageRedirectionFlag, setPageRedirectionFlag] = React.useState(false);
    const account = msalApp.getAccount();
    const ref = useRef();
    
    useEffect(() => {

        if (!localStorage.getItem("templateId")) {
            setPageRedirectionFlag(true);
            return
        }

        msalApp.acquireTokenSilent(
            GRAPH_REQUESTS.AZURE_SCOPE).then(async(response) => {
                sessionStorage.setItem('accessTokenAD', response.accessToken);
            try{
                // let res = await fetchSubscription(
                let res = await fetchMsGraph(
                    GRAPH_ENDPOINTS.SUBSCRIPTION,
                    response.accessToken
                )
                setSubscriptionFlag(false);
                setSubscriptions(res.value);
            }
            catch(error){
                console.log(error)
            };
        }).catch(error => {
            console.log(error.errorCode);
            // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
            // due to consent or interaction required ONLY
            if (requiresInteraction(error.errorCode)) {
                return isIE()
                    ? msalApp.acquireTokenRedirect(GRAPH_REQUESTS.ACTIVE_DIRECTORY)
                    : msalApp.acquireTokenPopup(GRAPH_REQUESTS.ACTIVE_DIRECTORY);
            } else {
                console.error('Non-interactive error:', error.errorCode)
            }
        });
    },[setSubscriptions]);

    const handleChange = event => {
        setSubscriptionIdValue(event.target.value);
    };

    const handleNext = () => {
        console.log(subscriptionIdValue)
        let blueprintName =  localStorage.getItem("blueprintName")
        if(blueprintName === "HPC Cache Client" || blueprintName === "Data Ingest"){
            msalApp.acquireTokenSilent(GRAPH_REQUESTS.ACTIVE_DIRECTORY).then(async(response) => {
                let hpcCacheList = await fetch(
                    "https://management.azure.com/subscriptions/" + subscriptionIdValue + "/providers/Microsoft.StorageCache/caches?api-version=2020-03-01",
                    {
                        method:'get',
                        headers:{
                            Authorization: 'Bearer '+response.accessToken
                        }
                    }
                ).then(res => res.json())

                if(hpcCacheList.value.length){
                    setNext(true);
                }
                else{
                    setNext(true);

                    // confirmAlert({
                    //     title: "Unable to deploy Blueprint "+blueprintName,
                    //     message: "This blueprint requires a pre-existing Azure HPC Cache deployment in the subscription. Please create one via the Cache Files for AI & HPC Workloads blueprint or change subscription",
                    //     buttons: [
                    //         {
                    //             label: 'Deploy Azure HPC Cache',
                    //             onClick: () => window.location.href='/portal/blueprint/Cache-Files-for-AI-&-HPC-Workloads'
                    //         },
                    //         {
                    //             label: 'cancel'
                    //         }
                    //     ]
                    // });
                }
            }).catch(error => {
                console.log(error.name);
                if (requiresInteraction(error.errorCode)) {
                    return isIE()
                        ? msalApp.acquireTokenRedirect(GRAPH_REQUESTS.ACTIVE_DIRECTORY)
                        : msalApp.acquireTokenPopup(GRAPH_REQUESTS.ACTIVE_DIRECTORY);
                } else {
                    console.error('Non-interactive error:', error.errorCode)
                }
            });
            
        }
        else{
            setNext(true);
        }   
    };

    const handleBack = () => {
        setBack(true);
        history.goBack();
    };

    if(next) {
        localStorage.setItem("sid", subscriptionIdValue);
        subscriptions.forEach(subscription => {
            if(subscription["subscriptionId"] === subscriptionIdValue){
                localStorage.setItem("sname", subscription["displayName"]);
                // localStorage.setItem("slocation", subscription[""])
            }
        });
        history.push('/deploy/setparams');
        return <Redirect to={{pathname: '/deploy/setparams', state: {from: ref.location}}}/>;
    }

    if(back) {
        //return <Redirect to={`/`} />;
    }

    return (
        pageRedirectionFlag ?
            <Redirect to="/" /> :
        <div>
            <CssBaseline/>
            <main className={classes.layout}>
                <Headline classes={classes} />
                <Paper elevation={3}>
                    <div className={classes.deploymentTitle}>
                        Subscription
                    </div>
                    <Grid
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="stretch"
                    >
                        <Grid item xs={6} sm={6}>
                            <div className={classes.subscriptionForm}>
                                {
                                    subscriptionFlag ?
                                        <div>
                                            <CircularProgress />
                                            <span className={classes.fetchingSubscription}>Fetching subscription</span>
                                        </div>
                                        :
                                        <FormControl component="fieldset">
                                            {/*<FormLabel component="legend">Select your subscription</FormLabel>*/}
                                            {
                                                subscriptions.length === 0 ?
                                                    <div className={classes.subscriptionMessage}>You don't have any subscriptions associated with this email <b>{account.userName}</b>. Either sign up on <a href="https://portal.azure.com">portal.azure.com</a> to get a new subscription, or ask your administrator to make you an owner for a subscription.</div>
                                                    :
                                                    <RadioGroup value={subscriptionIdValue} onChange={handleChange}>
                                                        {
                                                            subscriptions.map( (subscription) =>
                                                                    <FormControlLabel key={subscription["subscriptionId"]} value={subscription["subscriptionId"]} disabled={subscription.state === "Enabled" ? false : true} control={<BlueRadio /> } label= {subscription.state === "Enabled" ? subscription.displayName : subscription.displayName + " (Disabled)"} />
                                                            )
                                                        }
                                                    </RadioGroup>
                                            }
                                        </FormControl>
                                }
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <img src={subscriptionImage} alt="subscription" className={classes.subscriptionImage}/>
                        </Grid>
                    </Grid>
                </Paper>

                <div className={classes.subscriptionButtons}>
                    <BackButton className={classes.subscriptionButton} onClick={handleBack}>
                        <ArrowBackIcon className={classes.subscriptionButtonBackIcon} />
                        Back
                    </BackButton>
                    <NextButton className={classes.subscriptionButton} onClick={handleNext} disabled={subscriptionIdValue === ""}>
                        Next
                        <ArrowForwardIcon className={classes.subscriptionButtonNextIcon}  />
                    </NextButton>
                </div>

            </main>
        </div>
    )
}
