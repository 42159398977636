import React from "react";
import {
    TextField,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

export const ValueFilter = props => (

    <Autocomplete
        multiple
        id={props.value[0]}
        style={{ width: '200px', backgroundColor: 'white', paddingLeft: '10px', }}
        getOptionSelected={(option, value) => option === value}
        options={props.filterValue}
        onChange={(event, newValue) => {
            props.valueFunction(newValue, props.index, "value");
        }}
        classes={props.classes}
        getOptionLabel={(option) => option}
        value={props.value}

        renderInput={(params) => (
            <TextField
                {...params}
                value={props.value}
                variant="outlined"
                label=""
                placeholder={(props.value).length > 0 ? "" :"Select"}
                />
            )}
        />
);
export const Filter = props => (
    <Autocomplete 
        id={props.value}
        classes={props.classes}
        getOptionSelected={(option, value) => option === value}
        onChange={(event, newValue) => {
            props.keyFunction(newValue, props.index, "key", props.value);
        }}
        value={props.value}
        style={{ width: '230px', backgroundColor: 'white', paddingLeft: '10px', borderRadius: '5px',  }}
        options={props.filterKey}
        renderInput={(params) => <TextField placeholder="Choose...." variant="outlined" value={props.key} {...params}  />}
        /> 
);

