import React, {useEffect, useRef} from "react";
import { CssBaseline} from "@material-ui/core";
import { Headline } from "./headline";
import {CustomTableCell, CustomTableRow, dashboardStyles} from "../../styles";
import {msalApp} from "../Authentication/auth-utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from '@material-ui/core/TablePagination';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'; 
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Button from '@material-ui/core/Button'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Redirect } from "react-router-dom";
import history from '../../history';

export default function Index() {
    const classes = dashboardStyles();
    const account = msalApp.getAccount();
    const [searchData, setSearchData] = React.useState("");
    const [deploymentList, setDeploymentList] = React.useState([]);
    const [totalList, setTotalList] = React.useState(0);
    const [deploymentListFlag, setDeploymentListFlag] = React.useState(true);
    const [sort, setSort] = React.useState("descending");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [next, setNext] = React.useState(false);
    const ref = useRef();

    const handleChangePage = (event, newPage) => {
        deploymentGetUser(rowsPerPage, newPage, searchData);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
        deploymentGetUser(parseInt(event.target.value), 0, searchData);
    };

    useEffect(()=> {
        deploymentGetUser(rowsPerPage, 0, searchData);
    },[setDeploymentList, setTotalList, setDeploymentListFlag]);

    //Redirect to resource management
    const redirectToResourceMgmt = val => {
        localStorage.setItem("selectedDeploymentRM", JSON.stringify(val));
        setNext(true);
    };

    if(next){
        history.push('/deployment.list/resources');
        return <Redirect to={{pathname: '/deployment.list/resources', state: {from: ref.location}}}/>;
    };
    
    

    function deploymentGetUser(rowsPerPage, newPage, searchData, sortOption) {
       
        setDeploymentListFlag(true);

        const bodyParams = {
            "Email": account.userName,
            "Skip": rowsPerPage * newPage,
            "Limit": rowsPerPage,
            "DeploymentName": searchData,
            "SubscriptionId": "--",
            "Status": "all",
            "Date": sortOption ? sortOption : sort
        };
        fetch("/api/azure/deployment.get.user", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyParams),
        }).then((res) => {
            return res.text();
        }).then((val) => {
            if (val) {
                setDeploymentListFlag(false);
                try {
                    val = JSON.parse(val);
                    // console.log(val);
                    
                    setDeploymentList(val.details.result);
                    setTotalList(parseInt(val.totalCount));
                }
                catch (e) {
                    console.log("Error : deployment.get.user");
                }
            }
        });
    }

    function search(searchdata) {
        setSearchData(searchdata);
        setPage(0);
        deploymentGetUser(rowsPerPage, 0, searchdata)
    }

    function handleSort() {
        sort === "ascending" ? setSort("descending") : setSort("ascending");
        deploymentGetUser(rowsPerPage, page, searchData, sort === "ascending" ? "descending" : "ascending");    
    }

    return (
        
        <div  className={classes.backgroundVector} >
            <CssBaseline/>
            <main className={classes.layout}>
                <Headline classes={classes} handleSearch={search}/>
                {
                    deploymentListFlag ?
                        <div>
                            <CircularProgress />
                            <span className={classes.fetchingBlueprint}>Fetching deployment list</span>
                        </div>
                        :
                        deploymentList.length ?
                        <div>
                            <TableContainer className={classes.tableContainer}>
                                <Table>
                                    <TableHead>
                                        <TableRow >
                                            <CustomTableCell>Deployment Name</CustomTableCell>
                                            {/* <CustomTableCell>Email</CustomTableCell> */}
                                            <CustomTableCell>Subscription Id</CustomTableCell>
                                            <CustomTableCell>Status</CustomTableCell>
                                            <CustomTableCell ><Button style={{ color: 'white', textTransform: 'none', "&:hover": { backgroundColor: "#424e58" }}} onClick={ handleSort }>Created At (UTC){sort === "ascending" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</Button></CustomTableCell>
                                            {/* <CustomTableCell>Portal Link</CustomTableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                        deploymentList.map(
                                            (val, index) => (

                                                <CustomTableRow hover key={index}  onClick={()=>redirectToResourceMgmt(val)}>
                                                    <CustomTableCell component="th" scope="row">{val.deploymentName}</CustomTableCell>
                                                    {/* <CustomTableCell>{val.email}</CustomTableCell> */}
                                                    <CustomTableCell>{val.subscriptionId}</CustomTableCell>
                                                    <CustomTableCell>{val.status}</CustomTableCell>
                                                    <CustomTableCell>{val.createdAt}</CustomTableCell>
                                                    {/* <CustomTableCell className={classes.deploymentListTableLink}><a href={val.portalLink} target="_blank" rel="noopener noreferrer">Link</a></CustomTableCell> */}
                                                </CustomTableRow>
                                            )
                                        )
                                    }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5,10,25]}
                                component="div"
                                count={totalList}
                                page={page}
                                onChangePage={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </div>
                        :
                        <p>
                            Your deployment list is empty.
                        </p>
                }
            </main>
        </div>
    );
}