import React, { Component } from "react";
import { BrowserRouter, Switch} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { Theme } from "../styles";
import PrivateRoute from "../router/privateRoute";
import Layout from "./Layout";
import Blueprint from "./Blueprint";
import Deploy from "./Deploy";
import DeployParams from "./DeployParams";
import Summary from "./Summary";
import Status from "./Status"
import history from "../history";
import DeploymentList from "./DeploymentList";
import ResourceManagement from "./ResourceManagement"
import CustomBlueprint from "./CustomBlueprint";
import Help from "./Help";
import CostManagement from "./CostManagement";
import BluePrintDetails from "./BluePrintDetails";

class EduResearchPortal extends Component{
    render() {
        return (
            <ThemeProvider theme={Theme}>
                <BrowserRouter basename="/portal">
                    <Layout>
                        <Switch history={history}>
                            <PrivateRoute exact path="/" component={Blueprint} />
                            <PrivateRoute exact path="/blueprint/:name" component={BluePrintDetails} />
                            <PrivateRoute exact path="/deploy" component={Deploy} />
                            <PrivateRoute exact path="/deploy/summary" component={Summary} />
                            <PrivateRoute exact path="/deploy/setparams/" component={DeployParams} />
                            <PrivateRoute exact path="/deploy/status" component={Status} />
                            <PrivateRoute path="/deploy/setparams/:sid" component={DeployParams} />
                            <PrivateRoute path="/deploy/:sid" component={Deploy} />
                            
                            <PrivateRoute path="/help" component={Help} />

                            <PrivateRoute path="/deployment.list/resources" component={ResourceManagement} />
                            <PrivateRoute path="/deployment.list" component={DeploymentList} />

                            <PrivateRoute path="/user.blueprints.list" component={CustomBlueprint} />
                            <PrivateRoute path="/costanalysis" component={CostManagement} />
                        </Switch>
                    </Layout>
                </BrowserRouter>
            </ThemeProvider>
        );
    }
}

export default EduResearchPortal;
