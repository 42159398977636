import React, {Component, Fragment} from 'react';
import {Link, withRouter} from 'react-router-dom';
import { compose } from 'recompose'
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from "@material-ui/core/Typography";
import AccountCircle from '@material-ui/icons/AccountCircle';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import {withStyles} from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HistoryIcon from '@material-ui/icons/History';
import {MenuList} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {dashboardStylesCore} from "../../styles"
import Grid from "@material-ui/core/Grid";
import AssessmentTwoToneIcon from '@material-ui/icons/AssessmentTwoTone';
import {
    GRAPH_REQUESTS, isIE,
    msalApp, requiresInteraction,
} from "../Authentication/auth-utils";
import AuthProvider from "../Authentication/AuthProvider";
import PropTypes from "prop-types";
import Logo from "../../assert/images/Logo.png";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

// Profile Menu
import Button from '@material-ui/core/Button';
import Popover from "@material-ui/core/Popover";

class Layout extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            account: null,
            name: "user",
            mobileOpen: false,
        };
    }

    async componentDidMount() {
        const account = msalApp.getAccount();

        this.setState({
            account: account,
            name: account ? account.name : "User",
            email: account ? account.userName : "--",
            anchorEl: null,
            organization:""
        });
        
        msalApp.acquireTokenSilent(GRAPH_REQUESTS.LOGIN).then(response => {
            
            fetch("/api/azure/organization.get", {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ AccessToken : response.accessToken })
            }).then((res)=>{
                return res.text();
            }).then((val)=> {
                try {
                    let tempdata = JSON.parse(val);
                    this.state.organization = tempdata[0].displayName;
                } catch (err) {
                    this.state.organization = "MaRaaS";
                }
            })
   
        }).catch(error => {
            console.log(error.name);
            if (requiresInteraction(error.errorCode)) {
                return isIE()
                    ? msalApp.acquireTokenRedirect(GRAPH_REQUESTS.ACTIVE_DIRECTORY)
                    : msalApp.acquireTokenPopup(GRAPH_REQUESTS.ACTIVE_DIRECTORY);
            } else {
                console.error('Non-interactive error:', error.errorCode)
            }
        });
        
    }

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    };

    static propTypes = {
        account: PropTypes.object,
        emailMessages: PropTypes.object,
        error: PropTypes.string,
        graphProfile: PropTypes.object,
        onSignIn: PropTypes.func.isRequired,
        onSignOut: PropTypes.func.isRequired,
        onRequestEmailToken: PropTypes.func.isRequired,
        permission: PropTypes.string
    };
    
    render() {
        const { classes, location: { pathname }, container, children} = this.props;
        const { mobileOpen } = this.state;
        let id = window.location.href.split("/");
        let path = "/" + id[4] + "/" + id[5];

        const handleClick = (event) => {
            this.setState({ anchorEl: event.currentTarget });
        };

        const handleClose = () => {
            this.setState({ anchorEl: null });
        };
        
        let costManagementFlag = sessionStorage.getItem("orgPermission") && JSON.parse(sessionStorage.getItem("orgPermission")).indexOf("cost_management") !== -1 && sessionStorage.getItem("permission") && JSON.parse(sessionStorage.getItem("permission")).indexOf("cost_management") !== -1;
        
        const drawer = (
            <div className={classes.navBar}>
                <div className={classes.toolbar} />
                <Divider />
                <MenuList>

                    <MenuItem className={classes.menu} component={Link} to="/" selected={path === pathname || '/' === pathname || '/deploy' === pathname || '/deploy/setparams' === pathname || '/deploy/summary' === pathname || '/deploy/status' === pathname}>
                        <Grid container >
                            <DescriptionIcon className={classes.menuIcon}/>
                            <span className={classes.menuName}>Blueprints</span>
                        </Grid>
                    </MenuItem>

                    <MenuItem className={classes.menu} component={Link} to="/deployment.list" selected={'/deployment.list' === pathname}>
                        <Grid container >
                            <HistoryIcon className={classes.menuIcon}/>
                            <span className={classes.menuName}>Deployments</span>
                        </Grid>
                    </MenuItem>

                    <MenuItem className={classes.menu} component={Link} to="/user.blueprints.list" selected={'/user.blueprints.list' === pathname}>
                        <Grid container >
                            <FileCopyIcon className={classes.menuIcon}/>
                            <span className={classes.menuName}>Custom Blueprints</span>
                        </Grid>
                    </MenuItem>
                    {
                        costManagementFlag?
                            <MenuItem className={classes.menu} component={Link} to="/costanalysis" selected={'/costanalysis' === pathname}>
                                <Grid container >
                                    <AssessmentTwoToneIcon className={classes.menuIcon} />
                                    <span className={classes.menuName}>Cost Analysis</span>
                                </Grid>
                            </MenuItem> :
                            ""
                    }
                </MenuList>

            </div>
        );


        return (
            <Fragment>
                <CssBaseline />
                <div className={classes.root}>
                    <AppBar position="fixed" className={classes.appBar} elevation={3}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={this.handleDrawerToggle}
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                            <span className={classes.appBarLogoPlaceholder}>
                                <img src={Logo} alt={"MxRaas"} className={classes.layoutLogo}/>
                                {/* <div className={classes.layoutPreview} >(Preview)</div> */}
                            </span>
                            <div className={classes.appBarMenu}>
                                {/*<IconButton aria-label="show 11 new notifications" color="inherit">*/}
                                {/*    <NotificationBadge badgeContent={7}>*/}
                                {/*        <NotificationsIcon className={classes.appBarNotificationIcon}/>*/}
                                {/*    </NotificationBadge>*/}
                                {/*</IconButton>*/}
                                <Button component={Link} to="/help" ><HelpOutlineIcon/></Button>
                                <Button
                                    aria-controls="customized-menu"
                                    aria-haspopup="true"
                                    // variant="contained"
                                    onClick={handleClick}
                                >
                                    <AccountCircle className={classes.appBarProfileIcon}/>
                                    <Typography variant="body2" className={classes.appBarProfileName}>
                                        {this.state.name}
                                    </Typography>
                                </Button>

                                <Popover 
                                    id="profile"
                                    open={Boolean(this.state.anchorEl)}
                                    anchorEl={this.state.anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-evenly"
                                        alignItems="stretch"
                                        style={{padding:"10px"}}
                                    >
                                        {/*Resource Group*/}
                                        <Grid style={{marginRight:"40px",paddingRight:"20px",paddingTop:"10px"}} item xs={12} sm={7}>
                                            {this.state.organization}
                                        </Grid>
                                        <Grid style={{textAlign:"right", "&:hover": {backgroundColor: "#424e58",}}} item xs={12} sm={3} onClick={this.props.onSignOut}>
                                          <Button>sign out</Button>  
                                        </Grid>
                                        
                                    </Grid>
                                    <Grid container
                                          direction="row"
                                          justify="space-evenly"
                                          alignItems="stretch"
                                    >
                                    <Grid style={{textAlign:"center",paddingLeft:"20px"}} item xs={12} sm={2}>
                                        <AccountCircle style={{fontSize:60}} className={classes.appBarProfileIcon}/>
                                    </Grid>
                                    <Grid  style={{textAlign:"left",paddingLeft:"25px",paddingTop:"5px"}} item xs={12} sm={10}>
                                        <Grid
                                            container
                                            direction="column"
                                            alignItems="stretch"
                                        >
                                            {/*Resource Group*/}
                                            <Grid style={{paddingRight:"100px",paddingBottom:"7px"}} item xs={12} sm={12}>
                                                {this.state.name}
                                            </Grid>
                                            <Grid   style={{paddingRight:"100px"}} item xs={12} sm={12}>
                                                {this.state.email}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    </Grid>
                                    
                                </Popover>
                                
                                
                              {/*  <StyledMenu
                                    id="customized-menu"
                                    anchorEl={this.state.anchorEl}
                                    keepMounted
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={handleClose}
                                >
                                    <StyledMenuItem>
                                        <ListItemText primary={this.state.email} />
                                    </StyledMenuItem>
                                    <StyledMenuItem>
                                        <ListItemText primary={this.state.organization} />
                                    </StyledMenuItem>
                                    <StyledMenuItem>
                                        <ListItemText primary="Sign out" onClick={this.props.onSignOut}/>
                                    </StyledMenuItem>
                                </StyledMenu>*/}

                                {/*<IconButton*/}
                                {/*    aria-label="account of current user"*/}
                                {/*    aria-controls="menu-appbar"*/}
                                {/*    aria-haspopup="true"*/}
                                {/*    color="inherit"*/}
                                {/*    onClick={this.props.onSignOut}*/}
                                {/*>*/}
                                {/*    <AccountCircle className={classes.appBarProfileIcon}/>*/}
                                {/*    <Typography variant="body2" className={classes.appBarProfileName}>*/}
                                {/*        {this.state.name}*/}
                                {/*    </Typography>*/}
                                {/*</IconButton>*/}
                            </div>
                            {/*<img src={Logo} alt="logo" className={classes.logo}/>*/}
                        </Toolbar>
                    </AppBar>
                    <nav className={classes.drawer} aria-label="mailbox folders">
                        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                        <Hidden smUp implementation="css">
                            <Drawer
                                container={container}
                                variant="temporary"
                                open={mobileOpen}
                                onClose={this.handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                            >
                                {drawer}
                            </Drawer>
                        </Hidden>
                        <Hidden xsDown implementation="css">
                            <Drawer
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                variant="permanent"
                                open
                            >
                                {drawer}
                            </Drawer>
                        </Hidden>
                    </nav>
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        {children}
                    </main>
                </div>
            </Fragment>
        );
    }
}

export default AuthProvider(compose(
    withRouter,
    withStyles(dashboardStylesCore)
)(Layout));