import React from "react";
import {Grid} from "@material-ui/core";
import { GrayDots } from "./grayDots";
import Container from '@material-ui/core/Container';
import embarrassingly from "../../assert/images/embarrassingly.png";
import memory from "../../assert/images/memory.png";
import standalone from "../../assert/images/standalone.png";
import shared from "../../assert/images/shared.png";
import Safeguard from "../../assert/images/Safeguard.png";
import { DeployButton } from "../../styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Alert, AlertTitle } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';

function alertMessageHTML(severity, title, message, props){
    return <Alert severity={severity} action={
        <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
                props.handleAlertClose();
            }}
        >
            <CloseIcon fontSize="inherit" />
        </IconButton>
    }
    >
        <AlertTitle>{title}</AlertTitle>
        {message}
    </Alert>;
}

export const Content = props => (
    <React.Fragment>
        <div style={{ opacity: props.fetchData ? .3 : 1 }} >
            <div className={props.classes.landingPageContent} >
                
                {
                    props.fetchData ? <div style={{ opacity: 1, position: 'absolute', display: 'flex', justifyContent: 'center', alignContent: 'center', width: '100%', marginTop: '200px' }}  ><CircularProgress style={{color:'blue'}} />  </div> : props.alertState ?
                        
                        props.messageState === "Pending" ?
                            alertMessageHTML(
                                "warning",
                                "Pending", 
                                "Your trial request has been successfully submitted. We will be in touch with you shortly.", 
                                props
                            )
                             :
                            props.messageState === "Active" ?
                                alertMessageHTML(
                                    "success",
                                    "Active",
                                    <span>You already part of MxRaaS. You can signin and expolre the portal. For any questions, please contact (<a href={`mailto:${"msedu@terawe.com"}`}>msedu@terawe.com</a>)</span>,
                                    props
                                )
                                 :
                                    props.messageState === "TrailExpired" ?
                                        alertMessageHTML(
                                            "warning",
                                            "TrailExpired",
                                            <span>Your trial Period has been Expired. For any questions, please contact (<a href={`mailto:${"msedu@terawe.com"}`}>msedu@terawe.com</a>)</span>,
                                            props
                                        )
                                         :
                                    props.messageState === "Not Found" ?
                                        alertMessageHTML(
                                            "warning",
                                            "Access Denied",
                                            <span>You do not have access to this Portal. Please request access by clicking the 'Request Trial' button below.</span>,
                                            props
                                        )
                                         : 
                                        <div /> : 
                        <div />
                    }
              
            {
             
            }
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
                    style={{ opacity: props.fetchData ? .3 : 1 }}
            >
               
                <Container >

                <Grid item sm={6} xs={12}>
                <div className={props.classes.landingPagecontainer} >
                    <div className={props.classes.landingPageHeadline} >
                        Academic   <br/>researchers face a
                        <br/>
                        
                    common problem
                
                    </div>
                    <div className={props.classes.landingPageHeadParagraphHolder}>
                        <div className={props.classes.landingPageHeadParagraph}>
                            Dealing with the intricacies of IT infrastructure <b> <i>distracts </i></b>them from doing their research. This applies to researchers at all levels who need to make use of Azure to conduct their research.
                        </div>
                            </div>
                          
                            <div>
                                <DeployButton onClick={() => props.handleClickOpen()} style={{ backgroundColor: '#1D9FF1', fontSize: '16px', transform: 'revert', paddingTop: '10px', padding: '25px', marginLeft: '20px', borderRadius: '25px' }} className={props.classes.landingPageHeadParagraph}>Request Trial</DeployButton>
                            <Dialog
                                open={props.state}
                                onClose={props.handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                >
                                    <div style={{ marginLeft: 'auto', paddingTop: '10px', paddingRight: '10px', cursor: 'pointer' }} onClick={() => { props.handleClickClose() }}>  <CloseIcon /></div>
                                    <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center', color:'#1D9FF1' }}>Request Trial</DialogTitle>
                                <DialogContent>
                                        <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
                                                Request trial to access the portal. Fill the form below and provide reason for trial access. Do provide your work or school Microsoft account for email. We will be in touch.
                                         </DialogContentText>

                                        
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-evenly"
                                            alignItems="stretch">

                                            <Grid item xs={12} sm={6}>
                                                <TextField id="standard-basic" label="First name"
                                                    name="First_name"
                                                    onChange={event => {
                                                        props.handleChange(event.target.value,"","","","","","","","");
                                                    }}
                                                    className={props.classes.paramsFormFieldForCheck} />
                                               
                                                {
                                                    props.error ? props.firstName === "" ? <div style={{ paddingLeft: '15px', color: 'red', fontSize: '10px' }}>First Name is Required</div>:<div/> :<div/>
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                    <TextField id="standard-basic" label="Last name"
                                                        type="text"
                                                        name="Last_name"
                                                        onChange={event => {
                                                        props.handleChange("",event.target.value, "", "", "","","","","");
                                                    }}
                                                        className={props.classes.paramsFormFieldForCheck}
                                                />
                                                {
                                                    props.error ? props.lastName === "" ? <div style={{ paddingLeft: '15px', color: 'red', fontSize: '10px' }}>Last Name is Required</div> : <div /> : <div />
                                                }
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField id="standard-basic" label="Job Title"
                                                    name="Job Title"
                                                    onChange={event => {
                                                        props.handleChange("","", event.target.value, "", "", "","","","");
                                                    }}
                                                    className={props.classes.paramsFormFieldForCheck} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField id="standard-basic" label="Organization"
                                                    name="Organization"
                                                    onChange={event => {
                                                        props.handleChange("", "","", event.target.value,  "", "", "", "", "");
                                                    }}
                                                    className={props.classes.paramsFormFieldForCheck} />
                                            </Grid>


                                            <Grid item xs={12} sm={6}>
                                                <TextField id="standard-basic" label="Type of Industry"
                                                    name="Type of Industry"
                                                    onChange={event => {
                                                        props.handleChange("", "","","", event.target.value, "",  "", "", "");
                                                    }}
                                                    className={props.classes.paramsFormFieldForCheck} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField id="standard-basic" label="Email"
                                                    type="email"
                                                    name="Email"
                                                    onChange={event => {
                                                        props.handleChange("", "","","","",event.target.value, "", "","");
                                                    }}
                                                    className={props.classes.paramsFormFieldForCheck} />
                                                {
                                                    props.error ? props.email === "" ? <div style={{ paddingLeft: '15px', color: 'red', fontSize: '10px' }}>Email is Required</div> : !props.emailError ? <div style={{ paddingLeft: '15px', color: 'red', fontSize: '10px' }}>Vaild Email Required</div> :<div /> : <div />
                                                }
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField id="standard-basic" label="Contact #"
                                                    type="text"
                                                    name="Contact #"
                                                    onChange={event => {
                                                        props.handleChange("", "", "", "", "", "", event.target.value,"","");
                                                    }}
                                                    className={props.classes.paramsFormFieldForCheck} />
                                               
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField id="standard-basic" label="Location/Country"
                                                    name="Location/Country"
                                                    onChange={event => {
                                                        props.handleChange("", "", "", "", "", "", "", event.target.value,"");
                                                    }}
                                                    className={props.classes.paramsFormFieldForCheck} />
                                            </Grid>

                                            <Grid item xs={12} >
                                                <TextField id="standard-basic" label="Purpose"
                                                    name="Purpose"
                                                    multiline
                                                    onChange={event => {
                                                        props.handleChange("", "", "", "", "", "", "", "", event.target.value);
                                                    }}
                                                    className={props.classes.paramsFormFieldForCheck} />
                                                {
                                                    props.error ? props.Purpose === "" ? <div style={{ paddingLeft: '15px', color: 'red', fontSize: '10px' }}>Purpose is Required</div> : <div /> : <div />
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                            </Grid>

                                            <Grid item xs={12} sm={12} style={{ marginTop: '20px', textAlign: 'center', borderRadius: '2' }}>
                                                {
                                                    props.loadingState ? <CircularProgress /> :
                                                        <DeployButton onClick={props.handleSubmit} style={{ borderRadius: '25px' }}>
                                                            Submit
                                                        </DeployButton>
                                                }

                                            </Grid>
                                            </Grid>

                                </DialogContent>
                                <DialogActions>
                                    
                                   
                                </DialogActions>
                            </Dialog>
                                </div>
                    </div>
                </Grid>
                </Container>
                
            </Grid>

           

        </div>

            <Grid item sm={12} xs={12} style={{ opacity: props.fetchData ? .3 : 1 }}>
                    <div className={props.classes.landingPageGreyDotHolder}>
                        <GrayDots classes={props.classes} />
                    </div>
                    
                    <div className={props.classes.landingPageFeaturesCard}>
                        <div className={props.classes.landingPageFeatures}>
                            
                            <div className={props.classes.landingPageFeaturesTitleContainer}>
                                <div className={props.classes.landingPageBlueBox}/>
                                <div className={props.classes.landingPageFeaturesTitle}>
                                    Blueprint-based Solutions
                                </div>
                            </div>
                            <div>
                                <div className={props.classes.landingPageFeatureContentHeadingtop}>
                                    Fortunately, it is now possible to shrink the procurement-deployment-use cycle from months to minutes using a blueprint-based approach that:
                                </div>
                            </div>
                            <br/>
                            <div>

                            <div className={props.classes.landingPageFeatureContentList}>
                                    <div className={props.classes.landingPageFeatureOrangeCircle}>
                                     <img src={embarrassingly} alt="vm" className={props.classes.landingPageFeatureContentIcon}/>
                                    </div>
                                    <div className={props.classes.landingPageFeatureContentContainer}>
                                        <div className={props.classes.landingPageFeatureContentHeading}>
                                        Optimal use of budgetary allocations
                                            <br/>
                                            <div className={props.classes.landingPageFeatureContentParagraph}>

                                                Blueprints ensure funds are squarely directed at research: now researchers can expect to optimize known operational and capital spends at the outset, and on an ongoing basis, with assurance and accuracy.

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={props.classes.landingPageFeatureContentList}>
                                    <div className={props.classes.landingPageFeatureOrangeCircle}>
                                     <img src={memory} alt="vm" className={props.classes.landingPageFeatureContentIcon}/>
                                    </div>
                                    <div className={props.classes.landingPageFeatureContentContainer}>
                                        <div className={props.classes.landingPageFeatureContentHeading}>
                                        Accelerate time to research
                                            <br/>
                                            <div className={props.classes.landingPageFeatureContentParagraph}>

                                                Blueprints are poised to systematically change the conversation: now researchers can expect to focus exclusively on their research end-to-end - from conceiving and prototyping their ideas, to full-scale modeling and simulation that rapidly produces meaningful results.

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={props.classes.landingPageFeatureContentList}>
                                   <div className={props.classes.landingPageFeatureBlueCircle}>
                                       <img src={standalone} alt="vm" className={props.classes.landingPageFeatureContentIcon}/>
                                </div>
                                   <div className={props.classes.landingPageFeatureContentContainer}>
                                       <div className={props.classes.landingPageFeatureContentHeading}>
                                       Amplify researcher productivity
                                           <br/>
                                           <div className={props.classes.landingPageFeatureContentParagraph}>
                                               Blueprints ensure researcher productivity: now researchers can expect to start from the vantage point of a relevant and ready-to-run use case - a development environment, execution environments for isolated to cluster-oriented workloads (e.g., serial, parallel and/or containerized), and more...
                                           </div>
                                       </div>
                                   </div>
                                </div>
                                <div className={props.classes.landingPageFeatureContentList}>
                                   <div className={props.classes.landingPageFeatureBlueCircle}>
                                       <img src={shared} alt="vm" className={props.classes.landingPageFeatureContentIcon}/>
                                </div>
                                   <div className={props.classes.landingPageFeatureContentContainer}>
                                       <div className={props.classes.landingPageFeatureContentHeading}>
                                       Collaborate across multiple disciplines
                                           <br/>
                                           <div className={props.classes.landingPageFeatureContentParagraph}>
                                               Blueprints are customized to the nitty-gritty details of the discipline: now researchers can expect to start from the vantage point of their discipline, as blueprints feature those applications and toolchains demanded by their practice.
                                           </div>
                                       </div>
                                   </div>
                                </div>

                                <div className={props.classes.landingPageFeatureContentList}>
                                   <div className={props.classes.landingPageFeatureBlueCircle}>
                                       <img src={Safeguard} alt="vm" className={props.classes.landingPageFeatureContentIcon}/>
                                </div>
                                   <div className={props.classes.landingPageFeatureContentContainer}>
                                       <div className={props.classes.landingPageFeatureContentHeading}>
                                       Safeguard data with secure environments and geofencing 
                                           <br/>
                                           <div className={props.classes.landingPageFeatureContentParagraph}>
                                           Blueprints ensure confidentiality and location awareness: now researchers can rest assured that their data benefits from the most secure of environments while respecting geographic boundaries as required   
                                           </div>
                                       </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={props.classes.greenbgsection}>
                        
                            {/*<div className={props.classes.landingPageSectionCardHolder}>*/}
                                <div className={props.classes.greenbgsectionContnet}>
                                    The blueprints are designed to require minimal input – input that results in the deployment of ready-to-use platforms on Microsoft Azure in minutes.
                                </div>
                            {/*</div>*/}
                       
                    </div>
                </Grid>

       
            <div className={props.classes.beneficts} style={{ opacity: props.fetchData ? .3 : 1 }}>
            <Container >
            <Grid container
              direction="row"
              justify="flex-start"
              >
            <Grid item xs={12} sm={6}>
            <div className={props.classes.benefictssection} >
                 <h1 className={props.classes.benefictheding}> Benefits</h1>
                
                 

                 <div className={props.classes.benefictssectionContainer} >
                 <div className={props.classes.IconTop}>
                 
                        <div className={props.classes.benefictssectionContainerSubheding}>
                        <b>Industry-standard components </b>
                        </div>
                </div>  
                        <div className={props.classes.benefictssectionDescription} >
                        From Intel and AMD CPUs, to NVIDIA GPUs and Mellanox InfiniBand interconnect fabrics.
                        </div>              
                 </div>


                 <div className={props.classes.benefictssectionContainersecondsection} >
                 <div className={props.classes.IconTop}>
                 
                        <div className={props.classes.benefictssectionContainerSubheding}>
                        <b>Always leading edge </b>
                        </div>
                </div>  
                        <div className={props.classes.benefictssectionDescription} >
                        Count on your Azure-based AI and HPC solutions to be leading edge not only when you first sign up, but to remain this way as you use it. 
                        </div>              
                 </div>

                 <div className={props.classes.benefictssectionContainersecondsection} >
                 <div className={props.classes.IconTop}>
                 
                        <div className={props.classes.benefictssectionContainerSubheding}>
                        <b>Benchmark proven </b>
                        </div>
                </div>  
                        <div className={props.classes.benefictssectionDescription} >
                        Application benchmarks prove performance on Azure matches or exceeds that obtainable on premises.
                        </div>              
                 </div>


            </div>
            </Grid>
            <Grid item xs={12} sm={6}>
            <div className={props.classes.benefictssectionImage}>
                        
           
                </div>
            </Grid>
        </Grid>
        </Container>
         </div>
    


  
            <div className={props.classes.consection} style={{ opacity: props.fetchData ? .3 : 1 }}>  

            <Grid container
              direction="row"
              justify="flex-start"
              >
            <Grid item xs={12} sm={12}>
            <div className={props.classes.consectionShape} >
            <Container >
                 Researchers are accustomed to backing up claims they make with evidence. Across all disciplines now, application benchmark results are reaching an inescapable conclusion: performance on Azure matches or exceeds that obtainable on premise.                                 
                 </Container>
            </div>
            </Grid>
            
        </Grid>
       
            </div>
        </div>

         <div className={props.classes.footer} >  

<Grid container
  direction="row"
  justify="flex-start"
  >
<Grid item xs={12} sm={12}>
<div className={props.classes.footerContent} >
<Container >
© 2020 Terawe Corp. All Rights Reserved. |   <a href={`mailto:${"msedu@terawe.com"}`}>msedu@terawe.com</a>
</Container>
</div>
</Grid>

</Grid>

            </div>
           
    </React.Fragment>
);