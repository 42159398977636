import React, {useEffect, useRef} from "react";
import {CssBaseline, Paper, Grid } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {dashboardStyles, NextButton, BackButton} from "../../styles";
import { Headline } from "../Deploy/headline";
import vmImage from "../../assert/images/vm.png";
import { Redirect } from "react-router-dom";
import history from '../../history';

export default function Index() {
    const classes = dashboardStyles();
    const subscriptionId = localStorage.getItem("sid");

    const [deployementDetails, setDeployementDetails] = React.useState({});
    const [next, setNext] = React.useState(false);
    const [back, setBack] = React.useState(false);
    const [pageRedirectionFlag, setPageRedirectionFlag] = React.useState(false);

    const ref = useRef();
    useEffect(() => {
        let depDetails = JSON.parse(localStorage.getItem(subscriptionId));
        if (!isEmpty(depDetails))
            setDeployementDetails(depDetails);
        else
            setPageRedirectionFlag(true);
        // console.log(depDetails)
    },[setDeployementDetails, subscriptionId]);

    const handleNext = () => {
        setNext(true);
    };

    const handleBack = () => {
        setBack(true);
        history.goBack();
    };

    if (next) {
        history.push('/deploy/status');
        return <Redirect to={{pathname: '/deploy/status', state: {from: ref.location}}}/>;
    }

    if(back) {
       // return <Redirect to={{pathname: '/deploy/setparams', state: {from: ref.location}}}/>;
    }

    const isEmpty = (obj) => {

        // null and undefined are "empty"
        if (obj == null) return true;

        // Assume if it has a length property with a non-zero value
        // that that property is correct.
        if (obj.length > 0) return false;
        if (obj.length === 0) return true;

        // If it isn't an object at this point
        // it is empty, but it can't be anything *but* empty
        // Is it empty?  Depends on your application.
        if (typeof obj !== "object") return true;

        // Otherwise, does it have any properties of its own?
        // Note that this doesn't handle
        // toString and valueOf enumeration bugs in IE < 9
        for (var key in obj) {
            if (hasOwnProperty.call(obj, key)) return false;
        }

        return true;
    };

    return (
        pageRedirectionFlag ?
            <Redirect to="/" /> :
            <div>
            <CssBaseline/>
            <main className={classes.layout}>
                <Headline classes={classes} />
                <Paper elevation={3}>
                    <div className={classes.deploymentTitle}>
                        Summary
                    </div>
                    {sessionStorage.getItem("resource") === "hpc-cache" ?
                    <div>
                        <Grid
                            container
                            direction="row"
                            justify="space-evenly"
                            alignItems="stretch"
                        >
                            <Grid item xs={12} sm={12}>
                                <div className={classes.summaryHeading}>
                                    Blueprint Name :
                                    <span className={classes.summaryParagraph}>
                                        &nbsp; HPC Cache
                                    </span>
                                </div>
                                <div className={classes.summaryHeading2}>
                                    Selected Subscription :
                                    <span className={classes.summaryParagraph}>
                                        &nbsp; {localStorage.getItem("sname")}
                                    </span>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Grid item xs={1} sm={1}>
                                <div className={classes.paramsTitleIconPlaceholder}>
                                    <img src={vmImage} alt="subscription" className={classes.summaryResourceIcon}/>
                                </div>
                            </Grid>

                            <Grid item xs={11} sm={11}>
                                <div className={classes.summaryResourceTitle}>
                                    HPC Cache
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="stretch"
                            >
                                <Grid item xs={6} sm={6}>
                                    <div className={classes.summaryHeading}>
                                        Resource Group :
                                        <span className={classes.summaryParagraph}>
                                        &nbsp; {deployementDetails.resource_group}
                                    </span>
                                    </div>
                                </Grid>

                                <Grid item xs={6} sm={6}>
                                    <div className={classes.summaryHeading}>
                                        Location :
                                        <span className={classes.summaryParagraph}>
                                        &nbsp; {deployementDetails.location}
                                    </span>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="stretch"
                            >
                                <Grid item xs={6} sm={6}>
                                    <div className={classes.summaryHeading2}>
                                        Cache Name :
                                        <span className={classes.summaryParagraph}>
                                            &nbsp; {deployementDetails.cache_name}
                                            </span>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <div className={classes.summaryHeading2}>
                                        Cache Size :
                                        <span className={classes.summaryParagraph}>
                                            &nbsp; {deployementDetails.cache_size}
                                            </span>
                                    </div>
                                </Grid>
                                </Grid>
                        </Grid>
                    </div>
                    :sessionStorage.getItem("resource") === "data-ingest" ?
                    <div>
                        <Grid
                            container
                            direction="row"
                            justify="space-evenly"
                            alignItems="stretch"
                        >
                            <Grid item xs={12} sm={12}>
                                <div className={classes.summaryHeading}>
                                    Blueprint Name :
                                    <span className={classes.summaryParagraph}>
                                        &nbsp; Data Ingest
                                    </span>
                                </div>
                                <div className={classes.summaryHeading2}>
                                    Selected Subscription :
                                    <span className={classes.summaryParagraph}>
                                        &nbsp; {localStorage.getItem("sname")}
                                    </span>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Grid item xs={1} sm={1}>
                                <div className={classes.paramsTitleIconPlaceholder}>
                                    <img src={vmImage} alt="subscription" className={classes.summaryResourceIcon}/>
                                </div>
                            </Grid>

                            <Grid item xs={11} sm={11}>
                                <div className={classes.summaryResourceTitle}>
                                    Data Ingest
                                </div>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Grid
                                container
                                direction="row"
                                justify="space-evenly"
                                alignItems="stretch"
                            >
                                <Grid item xs={6} sm={6}>
                                    <div className={classes.summaryHeading}>
                                        Cache Name :
                                        <span className={classes.summaryParagraph}>
                                            &nbsp; {deployementDetails.data ? deployementDetails.data.hpc_cache : ""}
                                            </span>
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <div className={classes.summaryHeading}>
                                        Data Path :
                                        <span className={classes.summaryParagraph}>
                                            &nbsp; {deployementDetails.data ? deployementDetails.data.data_path : ""}
                                            </span>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="stretch"
                            >
                                <Grid item xs={6} sm={6}>
                                    <div className={classes.summaryHeading2}>
                                        Storage Account Name :
                                        <span className={classes.summaryParagraph}>
                                        &nbsp; {deployementDetails.data ? deployementDetails.data.storage_account_name : ""}
                                    </span>
                                    </div>
                                </Grid>

                                {/* <Grid item xs={6} sm={6}>
                                    <div className={classes.summaryHeading2}>
                                        Location :
                                        <span className={classes.summaryParagraph}>
                                        &nbsp; {deployementDetails.location}
                                    </span>
                                    </div>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        
                    </div>
                    :
                    <div>
                        <Grid
                            container
                            direction="row"
                            justify="space-evenly"
                            alignItems="stretch"
                        >
                            <Grid item xs={12} sm={12}>
                                <div className={classes.summaryHeading}>
                                    Blueprint Name :
                                    <span className={classes.summaryParagraph}>
                                        &nbsp; Virtual Machines
                                    </span>
                                </div>
                                <div className={classes.summaryHeading2}>
                                    Selected Subscription :
                                    <span className={classes.summaryParagraph}>
                                        &nbsp; {localStorage.getItem("sname")}
                                    </span>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Grid item xs={1} sm={1}>
                                <div className={classes.paramsTitleIconPlaceholder}>
                                    <img src={vmImage} alt="subscription" className={classes.summaryResourceIcon}/>
                                </div>
                            </Grid>

                            <Grid item xs={11} sm={11}>
                                <div className={classes.summaryResourceTitle}>
                                    Virtual Machines
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="stretch"
                            >
                                <Grid item xs={6} sm={6}>
                                    <div className={classes.summaryHeading}>
                                        Resource Group :
                                        <span className={classes.summaryParagraph}>
                                        &nbsp; {deployementDetails.resource_group}
                                    </span>
                                    </div>
                                </Grid>

                                <Grid item xs={6} sm={6}>
                                    <div className={classes.summaryHeading}>
                                        Location :
                                        <span className={classes.summaryParagraph}>
                                        &nbsp; {deployementDetails.location}
                                    </span>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                        <div className={classes.summaryHeading2}>
                            Deployment Name :
                            <span className={classes.summaryParagraph}>
                                &nbsp; {sessionStorage.getItem("resource") !== "batch" ? deployementDetails.deployment_name : deployementDetails.data ? deployementDetails.data.deployment_name : "--"}
                                </span>
                        </div>
                    </Grid>
                    </div>
                    
                    }
                </Paper>

                <div className={classes.subscriptionButtons}>
                    <BackButton className={classes.subscriptionButton} onClick={handleBack}>
                        <ArrowBackIcon className={classes.subscriptionButtonBackIcon} />
                        Back
                    </BackButton>
                    <NextButton className={classes.subscriptionButton} onClick={handleNext}>
                        Finish
                        <ArrowForwardIcon className={classes.subscriptionButtonNextIcon}  />
                    </NextButton>
                </div>

            </main>
        </div>
    )
}
