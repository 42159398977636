import {Grid } from "@material-ui/core";
import React from "react";
import {DeployButton} from "../../styles";
import Logo from "../../assert/images/Logo.png";

export const Headline = props => (
    <React.Fragment>
        <div className={props.classes.headingAppBarSpace}/>
        <Grid container
              direction="row"
              justify="flex-start"
              alignItems="center">
            <Grid item xs={12} mx={6} sm={6}>
                <img src={Logo} alt={"LOGO"} className={props.classes.headingLogo}/>
                {/* <div className={props.classes.mainPagePreview}>(Preview)</div> */}
            </Grid>
            <Grid item xs={12} mx={6} sm={6}>
                <div className={props.classes.landingPageSignInButton}>
                    <DeployButton onClick={() => { props.handleAlertOpen(); props.onSignIn(); }}>
                        Sign in
                    </DeployButton>
                </div>
            </Grid>
        </Grid>
    </React.Fragment>
);