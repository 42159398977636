import React from "react";
import greyDot from "../../assert/images/greyDot.png";

const Dot = props => (
    <img src={greyDot} alt="landing_image" className={props.classes.landingPageGreyDot}/>
);

const Dots = props => (
    <React.Fragment>
        <Dot classes={props.classes}/>
        <Dot classes={props.classes}/>
        <Dot classes={props.classes}/>
    </React.Fragment>
)

export const GrayDots = props => (
    <React.Fragment>
        <Dots classes={props.classes} />
        <br/>
        <Dots classes={props.classes} />
        <br/>
        <Dots classes={props.classes} />
        <br/>
        <Dots classes={props.classes} />
    </React.Fragment>
);
