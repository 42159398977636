import React, {useEffect} from "react";
import {CssBaseline, Grid, LinearProgress, Paper} from "@material-ui/core";
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {dashboardStyles} from "../../styles";
import {Headline} from "../Deploy/headline";
import {GRAPH_REQUESTS, msalApp,} from "../Authentication/auth-utils";
import ImageDetails from "../../assert/config/Images.json";
import {Redirect} from "react-router-dom";

export default function Index() {
    const classes = dashboardStyles();
    const subscriptionId = localStorage.getItem("sid");

    const [errorDetails, setErrorDetails] = React.useState([]);
    const [status, setStatus] = React.useState("Deployment Initiated");
    const [internalError] = React.useState(false);
    
    const [pageRedirectionFlag, setPageRedirectionFlag] = React.useState(false);

    const renderStatus = (param) => {
        switch(param) {
            case "Deployment Initiated":
            case "Deployment Running":
            // case "Deployment Accepted":
                return (
                    <div className={classes.statusLoader}>
                        <LinearProgress />
                    </div>
                );

            case "Deployment Failed":
                return (
                    <div>
                        <ErrorIcon className={classes.statusError} />
                    </div>
                );

            case "Deployment Accepted":
                return (
                    <div>
                        <CheckCircleIcon className={classes.statusSuccess} />
                    </div>
                );

            default:
                return (
                    <div>
                        <ErrorIcon className={classes.statusWarning} />
                    </div>
                );
        }
    };

    const isEmpty = (obj) => {

        // null and undefined are "empty"
        if (obj == null) return true;

        // Assume if it has a length property with a non-zero value
        // that that property is correct.
        if (obj.length > 0)    return false;
        if (obj.length === 0)  return true;

        // If it isn't an object at this point
        // it is empty, but it can't be anything *but* empty
        // Is it empty?  Depends on your application.
        if (typeof obj !== "object") return true;

        // Otherwise, does it have any properties of its own?
        // Note that this doesn't handle
        // toString and valueOf enumeration bugs in IE < 9
        for (let key in obj) {
            if (hasOwnProperty.call(obj, key)) return false;
        }

        return true;
    };
        
    useEffect(() => {
        msalApp.acquireTokenSilent(GRAPH_REQUESTS.ACTIVE_DIRECTORY).then(response => {
            sessionStorage.setItem('accessTokenAD', response.accessToken);
        });

        let customImageDetails = {};
        let userAccounts={};
        let customImageFlg = false;
        let deploymentDetails = JSON.parse(localStorage.getItem(subscriptionId));
        if (!isEmpty(deploymentDetails)) {


            let deploymentDetails1 = JSON.parse(localStorage.getItem(subscriptionId));
            // console.log(deploymentDetails1)

            const today = new Date();
            const randomValue = today.getFullYear().toString().substring(2, 4)+ "" + (today.getMonth() + 1) + "" + today.getDate() + "" + today.getHours() + "" + today.getMinutes() ;

            const deploymentName = sessionStorage.getItem("resource") === "simple" ? deploymentDetails1.deployment_name 
                :sessionStorage.getItem("resource") === "batch" ? deploymentDetails1.data.deployment_name 
                :sessionStorage.getItem("resource") === "hpc-cache" ? deploymentDetails1.cache_name:'';
            const selectedBlueprint = sessionStorage.getItem("selectedBlueprintDetails");
            const selectedBlueprintDetails = JSON.parse(selectedBlueprint);

            async function fetchTemplateData() {
                const account = msalApp.getAccount();
                const fields = "ArmTemplate";

                await fetch("/api/azure/template.get?fields=" + fields + "&userName=all," + account.userName + "&status=Published&id=" + localStorage.getItem("templateId"), {
                    method: "get",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).then((res) => {
                    return res.text();
                }).then((val) => {
                    if (val) {
                        // console.log(val)
                        let res = JSON.parse(val);
                        let tempData = res.result;
                        if (tempData.length > 0) {
                            if (tempData[0].armTemplate) {
                                let armTemplate = JSON.parse(tempData[0].armTemplate);

                                // armTemplate["properties"]["parameters"]["location"]["value"] = deploymentDetails1.location;

                                if (sessionStorage.getItem("resource") === "simple") {
                                    armTemplate["properties"]["parameters"]["location"]["value"] = deploymentDetails1.location;

                                    if(sessionStorage.getItem("customImage") === "false" || deploymentDetails1.custom_image === undefined){
                                        // console.log(deploymentDetails1)
                                        // debugger
                                        armTemplate["properties"]["parameters"]["adminUsername"]["value"] = deploymentDetails1.admin_name;
                                        armTemplate["properties"]["parameters"]["adminPublicKey"]["value"] = deploymentDetails1.ssh_public_key;

                                        armTemplate["properties"]["parameters"]["networkInterfaceName"]["value"] = "mx-" + randomValue + "-ni";
                                        armTemplate["properties"]["parameters"]["networkSecurityGroupName"]["value"] = "mx-" + randomValue + "-nsg";
                                        armTemplate["properties"]["parameters"]["virtualNetworkName"]["value"] = "mx-" + randomValue + "-vnet";
                                        armTemplate["properties"]["parameters"]["publicIpAddressName"]["value"] = "mx-" + randomValue + "-ip";
                                        armTemplate["properties"]["parameters"]["virtualMachineName"]["value"] = "mx" + randomValue + "vm";
                                        armTemplate["properties"]["parameters"]["virtualMachineRG"]["value"] = "mx-" + randomValue + "-rg";
                                        armTemplate["properties"]["parameters"]["diagnosticsStorageAccountName"]["value"] = "mx" + randomValue + "strg";
                                        armTemplate["properties"]["parameters"]["diagnosticsStorageAccountId"]["value"] = "Microsoft.Storage/storageAccounts/mx" + randomValue + "storage";


                                        armTemplate["properties"]["template"]["resources"][0]["tags"] = {
                                            "bluePrint": selectedBlueprintDetails.id,
                                            "deploymentName": deploymentName
                                        };
                                        armTemplate["properties"]["template"]["resources"][1]["tags"] = {
                                            "bluePrint": selectedBlueprintDetails.id,
                                            "deploymentName": deploymentName
                                        };
                                        armTemplate["properties"]["template"]["resources"][2]["tags"] = {
                                            "bluePrint": selectedBlueprintDetails.id,
                                            "deploymentName": deploymentName
                                        };
                                        armTemplate["properties"]["template"]["resources"][3]["tags"] = {
                                            "bluePrint": selectedBlueprintDetails.id,
                                            "deploymentName": deploymentName
                                        };
                                        armTemplate["properties"]["template"]["resources"][4]["tags"] = {
                                            "bluePrint": selectedBlueprintDetails.id,
                                            "deploymentName": deploymentName
                                        };
                                        armTemplate["properties"]["template"]["resources"][5]["tags"] = {
                                            "bluePrint": selectedBlueprintDetails.id,
                                            "deploymentName": deploymentName
                                        };

                                        if (deploymentDetails1.vm_type)
                                            armTemplate["properties"]["parameters"]["virtualMachineSize"]["value"] = deploymentDetails1.vm_type;

                                        if((deploymentDetails1.os).includes('win')){
                                            armTemplate["properties"]["parameters"]["networkSecurityGroupRules"]["value"].push({
                                                "name": "RDP",
                                                "properties": {
                                                    "priority": 1000,
                                                    "access": "Allow",
                                                    "direction": "Inbound",
                                                    "destinationPortRange": "3389",
                                                    "protocol": "Tcp",
                                                    "sourceAddressPrefix": "*",
                                                    "sourcePortRange": "*",
                                                    "destinationAddressPrefix": "*"
                                                }
                                            })
                                        }
                                        
                                        armTemplate["properties"]["template"]["resources"][4]["properties"]["osProfile"] =
                                            (deploymentDetails1.password && deploymentDetails1.password !== "") ?
                                                (deploymentDetails1.os).includes('win') ?
                                                    {
                                                        "computerName": "[parameters('virtualMachineName')]",
                                                        "adminUsername": "[parameters('adminUsername')]",
                                                        "adminPassword": "[parameters('adminPassword')]",
                                                        "windowsConfiguration": {
                                                            "enableAutomaticUpdates": true,
                                                            "provisionVmAgent": true
                                                        }
                                                    } :
                                                    {
                                                        "computerName": "[parameters('virtualMachineName')]",
                                                        "adminUsername": "[parameters('adminUsername')]",
                                                        "adminPassword": "[parameters('adminPassword')]"
                                                    }
                                                :
                                                {
                                                    "computerName": "[parameters('virtualMachineName')]",
                                                    "adminUsername": "[parameters('adminUsername')]",
                                                    "linuxConfiguration": {
                                                        "disablePasswordAuthentication": true,
                                                        "ssh": {
                                                            "publicKeys": [
                                                                {
                                                                    "path": "[concat('/home/', parameters('adminUsername'), '/.ssh/authorized_keys')]",
                                                                    "keyData": "[parameters('adminPublicKey')]"
                                                                }
                                                            ]
                                                        }
                                                    }
                                                };

                                        
                                        if ((deploymentDetails1.password && deploymentDetails1.password !== "")) {
                                            delete (armTemplate["properties"]["template"]["parameters"]["adminPublicKey"]);
                                            armTemplate["properties"]["template"]["parameters"]["adminPassword"] = {
                                                "type": "secureString"
                                            };
                                            delete (armTemplate["properties"]["parameters"]["adminPublicKey"]);
                                            armTemplate["properties"]["parameters"]["adminPassword"] = {
                                                "value": deploymentDetails1.password
                                            };
                                        }

                                        // armTemplate["properties"]["template"]["resources"][4]["properties"]["storageProfile"]["imageReference"] =
                                        //     (deploymentDetails1.os).includes('win') ?
                                        //         {
                                        //             "publisher": "MicrosoftWindowsDesktop",
                                        //             "offer": "Windows-10",
                                        //             "sku": "rs5-pro",
                                        //             "version": "latest"
                                        //         } :
                                        //         deploymentDetails1.os === 'ubuntu' ?
                                        //             {
                                        //                 "publisher": "Canonical",
                                        //                 "offer": "UbuntuServer",
                                        //                 "sku": "18.04-LTS",
                                        //                 "version": "latest"
                                        //             } :
                                        //             {
                                        //                 "publisher": "OpenLogic",
                                        //                 "offer": "CentOS",
                                        //                 "sku": "7.7",
                                        //                 "version": "latest"
                                        //             };

                                        armTemplate["properties"]["template"]["resources"][4]["properties"]["storageProfile"]["imageReference"] =
                                        {
                                            "publisher": ImageDetails.osDetails[deploymentDetails1.os].publisher,
                                            "offer": ImageDetails.osDetails[deploymentDetails1.os].offer,
                                            "sku": ImageDetails.osDetails[deploymentDetails1.os].sku,
                                            "version": ImageDetails.osDetails[deploymentDetails1.os].version
                                        }

                                    } 
                                    else 
                                    {

                                        if(localStorage.getItem("blueprintName") === "HPC Cache Client"){
                                            armTemplate["properties"]["template"]["resources"][0]["tags"] = {
                                                "bluePrint": selectedBlueprintDetails.id,
                                                "deploymentName": deploymentName
                                            };
                                            armTemplate["properties"]["template"]["resources"][1]["tags"] = {
                                                "bluePrint": selectedBlueprintDetails.id,
                                                "deploymentName": deploymentName
                                            };
                                            armTemplate["properties"]["template"]["resources"][2]["tags"] = {
                                                "bluePrint": selectedBlueprintDetails.id,
                                                "deploymentName": deploymentName
                                            };
                                            armTemplate["properties"]["template"]["resources"][3]["tags"] = {
                                                "bluePrint": selectedBlueprintDetails.id,
                                                "deploymentName": deploymentName
                                            };
                                            
                                            armTemplate["properties"]["template"]["resources"][4]["tags"] = {
                                                "bluePrint": selectedBlueprintDetails.id,
                                                "deploymentName": deploymentName
                                            };
                                            armTemplate["properties"]["template"]["resources"][5]["tags"] = {
                                                "bluePrint": selectedBlueprintDetails.id,
                                                "deploymentName": deploymentName
                                            };

                                            armTemplate["properties"]["parameters"]["networkInterfaceName"]["value"] ="mx-" + randomValue + "-ni";
                                            armTemplate["properties"]["parameters"]["networkSecurityGroupName"]["value"] = "mx-" + randomValue + "-nsg"
                                            armTemplate["properties"]["template"]["variables"]["subnetRef"] = deploymentDetails1.subnet_to_mount
                                            armTemplate["properties"]["parameters"]["publicIpAddressName"]["value"] = "mx-" + randomValue + "-ip"
                                            armTemplate["properties"]["parameters"]["virtualMachineName"]["value"] = "mx" + randomValue + "vm"
                                            armTemplate["properties"]["parameters"]["virtualMachineComputerName"]["value"] = "mx" + randomValue + "vm"
                                            armTemplate["properties"]["parameters"]["virtualMachineSize"]["value"] = deploymentDetails1.vm_type
                                            armTemplate["properties"]["parameters"]["adminUsername"]["value"] = deploymentDetails1.admin_name
                                            armTemplate["properties"]["template"]["variables"]["adminPasswordOrKey"] = deploymentDetails1.password ? deploymentDetails1.password :  deploymentDetails1.ssh_public_key
                                            armTemplate["properties"]["parameters"]["diagnosticsStorageAccountName"]["value"] = "mx" + randomValue + "strg"
                                            armTemplate["properties"]["template"]["variables"]["imageName"] = ImageDetails.customImageDetails[deploymentDetails1.custom_image].name + "-image" + Math.floor(Math.random() * 100).toFixed();
                                            
                                            let uploadStrgName = 'mx' + Math.floor(Math.random() * 10000).toFixed();
                                            customImageDetails = {
                                                "resourceGroup": deploymentDetails1.resource_group,
                                                "subscriptionId": localStorage.getItem("sid"),
                                                "storageName": uploadStrgName,
                                                "token": sessionStorage.getItem('accessTokenAD'),
                                                "uploadFileId": ImageDetails.customImageDetails[deploymentDetails1.custom_image].blobUri,
                                                "location": deploymentDetails1.location,
                                                "containerName": "images",
                                                "blobName": ImageDetails.customImageDetails[deploymentDetails1.custom_image].blobName
                                            };
                                            customImageFlg = true;

                                            armTemplate["properties"]["template"]["variables"]["osDiskVhdUri"] = "https://" + uploadStrgName + ".blob.core.windows.net/images/" + ImageDetails.customImageDetails[deploymentDetails1.custom_image].blobName;

                                            deploymentDetails1.password && deploymentDetails1.password !== "" ?
                                                armTemplate["properties"]["template"]["resources"][4]["properties"]["osProfile"] = {
                                                    "computerName": "[parameters('virtualMachineName')]",
                                                    "adminUsername": "[parameters('adminUsername')]",
                                                    "adminPassword": "[variables('adminPasswordOrKey')]",
                                                }
                                                :
                                                armTemplate["properties"]["template"]["resources"][4]["properties"]["osProfile"] = {
                                                    "computerName": "[parameters('virtualMachineName')]",
                                                    "adminUsername": "[parameters('adminUsername')]",
                                                    "linuxConfiguration": {
                                                        "disablePasswordAuthentication": true,
                                                        "ssh": {
                                                            "publicKeys": [
                                                                {
                                                                    "path": "[concat('/home/', parameters('adminUsername'), '/.ssh/authorized_keys')]",
                                                                    "keyData": "[variables('adminPasswordOrKey')]"
                                                                }
                                                            ]
                                                        }
                                                    }
                                                }

                                        }
                                        else{
                                            armTemplate["properties"]["template"]["resources"][0]["tags"] = {
                                                "bluePrint": selectedBlueprintDetails.id,
                                                "deploymentName": deploymentName
                                            };
                                            armTemplate["properties"]["template"]["resources"][1]["tags"] = {
                                                "bluePrint": selectedBlueprintDetails.id,
                                                "deploymentName": deploymentName
                                            };
                                            armTemplate["properties"]["template"]["resources"][2]["tags"] = {
                                                "bluePrint": selectedBlueprintDetails.id,
                                                "deploymentName": deploymentName
                                            };
                                            armTemplate["properties"]["template"]["resources"][3]["tags"] = {
                                                "bluePrint": selectedBlueprintDetails.id,
                                                "deploymentName": deploymentName
                                            };
                                            
                                            armTemplate["properties"]["template"]["resources"][4]["tags"] = {
                                                "bluePrint": selectedBlueprintDetails.id,
                                                "deploymentName": deploymentName
                                            };
                                            armTemplate["properties"]["template"]["resources"][5]["tags"] = {
                                                "bluePrint": selectedBlueprintDetails.id,
                                                "deploymentName": deploymentName
                                            };

                                            if(localStorage.getItem("blueprintName") === "Visualize Reservoir Simulation"){
                                                armTemplate["properties"]["template"]["resources"][7]["tags"] = {
                                                    "bluePrint": selectedBlueprintDetails.id,
                                                    "deploymentName": deploymentName
                                                };
                                                armTemplate["properties"]["parameters"]["virtualMachineComputerName"]["value"] = "mx" + randomValue + "vm"

                                            }
    
                                            armTemplate["properties"]["parameters"]["diagnosticsStorageAccountName"]["value"] = "mx" + randomValue + "strg"
                                            armTemplate["properties"]["parameters"]["diagnosticsStorageAccountKind"]["value"] = "Storage"
                                            armTemplate["properties"]["parameters"]["diagnosticsStorageAccountType"]["value"] = "Standard_LRS"
                                            armTemplate["properties"]["parameters"]["publicIpAddressName"]["value"] = "mx-" + randomValue + "-ip"
                                            armTemplate["properties"]["parameters"]["publicIpAddressType"]["value"] = "Dynamic"
                                            armTemplate["properties"]["parameters"]["publicIpAddressSku"]["value"] = "Basic"
                                            armTemplate["properties"]["parameters"]["networkSecurityGroupName"]["value"] = "mx-" + randomValue + "-nsg"
                                            armTemplate["properties"]["parameters"]["virtualNetworkName"]["value"] = "mx-" + randomValue + "-vnet"
                                            armTemplate["properties"]["parameters"]["addressPrefixes"]["value"] = ["10.0.1.0/24"]
                                            armTemplate["properties"]["parameters"]["subnets"]["value"] =[{
                                                "name": "default",
                                                "properties": {
                                                    "addressPrefix": "10.0.1.0/24"
                                                }}]
                                            armTemplate["properties"]["parameters"]["networkInterfaceName"]["value"] ="mx-" + randomValue + "-ni";
                                            armTemplate["properties"]["template"]["variables"]= {"nsgId" : "[resourceId('Microsoft.Network/networkSecurityGroups', parameters('networkSecurityGroupName'))]"}
                                            armTemplate["properties"]["template"]["variables"]["vnetId"] = "[resourceId(resourceGroup().name,'Microsoft.Network/virtualNetworks', parameters('virtualNetworkName'))]"
                                            armTemplate["properties"]["template"]["variables"]["subnetName"] = "default"
                                            armTemplate["properties"]["template"]["variables"]["subnetRef"] = "[concat(variables('vnetId'), '/subnets/', variables('subnetName'))]"
                                            armTemplate["properties"]["parameters"]["virtualMachineName"]["value"] = "mx" + randomValue + "vm"
                                            armTemplate["properties"]["parameters"]["virtualMachineSize"]["value"] = deploymentDetails1.vm_type
                                            armTemplate["properties"]["parameters"]["adminUsername"]["value"] = deploymentDetails1.admin_name
                                            armTemplate["properties"]["template"]["variables"]["adminPasswordOrKey"] = deploymentDetails1.password ? deploymentDetails1.password :  deploymentDetails1.ssh_public_key
                                            armTemplate["properties"]["template"]["variables"]["authenticationType"] = deploymentDetails1.password === undefined ? "sshPublicKey" : "password"
                                            armTemplate["properties"]["template"]["variables"]["imageName"] = ImageDetails.customImageDetails[deploymentDetails1.custom_image].name + "-image"
    
                                            armTemplate["properties"]["template"]["$schema"]="https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#"
    
                                            armTemplate["properties"]["template"]["resources"][0]["apiVersion"] = "2020-03-01"
                                            armTemplate["properties"]["template"]["resources"][2]["apiVersion"] = "2020-03-01"
    
                                            if(localStorage.getItem("blueprintName") !== "Visualize Reservoir Simulation"){
                                                armTemplate["properties"]["template"]["resources"][4] = {
                                                    "apiVersion": "2019-12-01",
                                                    "type": "Microsoft.Compute/virtualMachines",
                                                    "name": "[parameters('virtualMachineName')]",
                                                    "location": "[parameters('location')]",
                                                    "dependsOn": [
                                                        "[parameters('diagnosticsStorageAccountName')]",
                                                        "[parameters('networkInterfaceName')]",
                                                        "[variables('imageName')]"
                                                    ],
                                                    "tags": {
                                                        "bluePrint": selectedBlueprintDetails.id,
                                                        "deploymentName": deploymentName
                                                    },
                                                    "properties": {
                                                        "hardwareProfile": {
                                                            "vmSize": "[parameters('virtualMachineSize')]"
                                                        },
                                                        "storageProfile": {
                                                            "imageReference": {
                                                                "id": "[resourceId('Microsoft.Compute/images', variables('imageName'))]"
                                                            }
                                                        },
                                                        "networkProfile": {
                                                            "networkInterfaces": [
                                                                {
                                                                    "id": "[resourceId('Microsoft.Network/networkInterfaces', parameters('networkInterfaceName'))]"
                                                                }
                                                            ]
                                                        },
                                                        "diagnosticsProfile": {
                                                            "bootDiagnostics": {
                                                                "enabled": true,
                                                                "storageUri": "[reference(resourceId('Microsoft.Storage/storageAccounts', parameters('diagnosticsStorageAccountName'))).primaryEndpoints.blob]"
                                                            }
                                                        }
                                                    }
                                                }
        
                                                deploymentDetails1.password && deploymentDetails1.password !== "" ?
                                                    (deploymentDetails1.custom_image).includes('win') ?
                                                        armTemplate["properties"]["template"]["resources"][4]["properties"]["osProfile"] = {
                                                            "computerName": "[parameters('virtualMachineName')]",
                                                            "adminUsername": "[parameters('adminUsername')]",
                                                            "adminPassword": "[variables('adminPasswordOrKey')]",
                                                            "windowsConfiguration": {
                                                                "enableAutomaticUpdates": true,
                                                                "provisionVmAgent": true
                                                            }
                                                        }:
                                                        armTemplate["properties"]["template"]["resources"][4]["properties"]["osProfile"] = {
                                                            "computerName": "[parameters('virtualMachineName')]",
                                                            "adminUsername": "[parameters('adminUsername')]",
                                                            "adminPassword": "[variables('adminPasswordOrKey')]",
                                                        }
                                                    :
                                                    armTemplate["properties"]["template"]["resources"][4]["properties"]["osProfile"] = {
                                                        "computerName": "[parameters('virtualMachineName')]",
                                                        "adminUsername": "[parameters('adminUsername')]",
                                                        "linuxConfiguration": {
                                                            "disablePasswordAuthentication": true,
                                                            "ssh": {
                                                                "publicKeys": [
                                                                    {
                                                                        "path": "[concat('/home/', parameters('adminUsername'), '/.ssh/authorized_keys')]",
                                                                        "keyData": "[variables('adminPasswordOrKey')]"
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    }
                                            }
    
                                            armTemplate["properties"]["template"]["resources"][1] = {
                                                "apiVersion": "2020-03-01",
                                                "type": "Microsoft.Network/networkSecurityGroups",
                                                "name": "[parameters('networkSecurityGroupName')]",
                                                "location": "[parameters('location')]",
                                                "tags": {
                                                    "bluePrint": selectedBlueprintDetails.id,
                                                    "deploymentName": deploymentName
                                                },
                                                "properties": {
                                                    "securityRules": [
                                                        {
                                                            "name": "SSH",
                                                            "properties": {
                                                                "priority": 300,
                                                                "protocol": "TCP",
                                                                "access": "Allow",
                                                                "direction": "Inbound",
                                                                "sourceAddressPrefix": "*",
                                                                "sourcePortRange": "*",
                                                                "destinationAddressPrefix": "*",
                                                                "destinationPortRange": "22"
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
    
                                            if((deploymentDetails1.custom_image).includes('win')){
                                                armTemplate["properties"]["template"]["resources"][1].properties.securityRules.push({
                                                    "name": "RDP",
                                                    "properties": {
                                                        "priority": 1000,
                                                        "access": "Allow",
                                                        "direction": "Inbound",
                                                        "destinationPortRange": "3389",
                                                        "protocol": "Tcp",
                                                        "sourceAddressPrefix": "*",
                                                        "sourcePortRange": "*",
                                                        "destinationAddressPrefix": "*"
                                                    }
                                                })
                                            }
    
                                            let uploadStrgName = 'mx' + Math.floor(Math.random() * 10000).toFixed();
                                            customImageDetails = {
                                                "resourceGroup": deploymentDetails1.resource_group,
                                                "subscriptionId": localStorage.getItem("sid"),
                                                "storageName": uploadStrgName,
                                                "token": sessionStorage.getItem('accessTokenAD'),
                                                "uploadFileId": ImageDetails.customImageDetails[deploymentDetails1.custom_image].blobUri,
                                                "location": deploymentDetails1.location,
                                                "containerName": "images",
                                                "blobName": ImageDetails.customImageDetails[deploymentDetails1.custom_image].blobName
                                            };
                                            customImageFlg = true;
                                            // uploadStorageAccount(deploymentDetails1, uploadStrgName, customImage);
    
                                            armTemplate["properties"]["template"]["variables"]["imageName"] = ImageDetails.customImageDetails[deploymentDetails1.custom_image].id + Math.floor(Math.random() * 100).toFixed();
                                            armTemplate["properties"]["template"]["variables"]["osDiskVhdUri"] = "https://" + uploadStrgName + ".blob.core.windows.net/images/" + ImageDetails.customImageDetails[deploymentDetails1.custom_image].blobName;
    
                                            armTemplate["properties"]["template"]["resources"][6] = {
                                                "type": "Microsoft.Compute/images",
                                                "apiVersion": "2019-12-01",
                                                "name": "[variables('imageName')]",
                                                "location": "[parameters('location')]",
                                                "tags": {
                                                    "bluePrint": selectedBlueprintDetails.id,
                                                    "deploymentName": deploymentName
                                                },
                                                "properties": {
                                                    "storageProfile": {
                                                        "osDisk": {
                                                            "osType": ImageDetails.customImageDetails[deploymentDetails1.custom_image].osType,
                                                            "osState": ImageDetails.customImageDetails[deploymentDetails1.custom_image].osState,
                                                            "blobUri": "[variables('osDiskVhdUri')]",
                                                            "storageAccountType": "[parameters('diagnosticsStorageAccountType')]"
                                                        }
                                                    },
                                                    "hyperVGeneration": "V1"
                                                }
                                            }
                                        }

                                        
                                    }
                                } else if(sessionStorage.getItem("resource") === "batch") {
                                    let imageCount = 0;
                                    armTemplate["properties"]["parameters"]["location"]["value"] = deploymentDetails1.location;

                                    armTemplate["properties"]["parameters"]["batchAccountName"]["value"] = deploymentDetails1.data.batch_name
                                    let uploadStrgName = 'mx' + Math.floor(Math.random() * 10000).toFixed();
                                    deploymentDetails1.pool_section.forEach((pool, index) => {
                                        // Set Batch Pool Account Credentials
                                        userAccounts = pool.adminType === "password" ?
                                            [{
                                                "name": deploymentDetails1.data[`admin_name_${index}`],
                                                "elevationLevel": "Admin",
                                                "password": deploymentDetails1.data[`password_${index}`]
                                            }]:
                                            [{
                                                "name": deploymentDetails1.data[`admin_name_${index}`],
                                                "elevationLevel": "Admin",
                                                "linuxUserConfiguration": {
                                                    "uid": index,
                                                    "gid": index,
                                                    "sshPrivateKey": deploymentDetails1.data[`ssh_public_key${index}`]
                                                }
                                            }];

                                        if (pool.imageType === "customImage") {
                                            let customImage = pool.customImage;

                                            customImageDetails = {
                                                "resourceGroup": deploymentDetails1.resource_group,
                                                "subscriptionId": localStorage.getItem("sid"),
                                                "storageName": uploadStrgName,
                                                "token": sessionStorage.getItem('accessTokenAD'),
                                                "uploadFileId": ImageDetails.customImageDetails[customImage].blobUri,
                                                "location": deploymentDetails1.location,
                                                "containerName": "images",
                                                "blobName": ImageDetails.customImageDetails[customImage].blobName
                                            };
                                            customImageFlg = true;
                                            // uploadStorageAccount(deploymentDetails1, uploadStrgName, customImage);

                                            let imageName = ImageDetails.customImageDetails[customImage].name + Math.floor(Math.random() * 100).toFixed();
                                            armTemplate["properties"]["template"]["variables"][`imageName${index}`] = imageName;
                                            armTemplate["properties"]["template"]["variables"][`osDiskVhdUri${index}`] = "https://" + uploadStrgName + ".blob.core.windows.net/images/" + ImageDetails.customImageDetails[customImage].blobName;

                                            armTemplate["properties"]["template"]["resources"][2 + imageCount] = {
                                                "type": "Microsoft.Compute/images",
                                                "apiVersion": "2019-12-01",
                                                "name": `[variables('imageName${index}')]`,
                                                "location": "[parameters('location')]",
                                                "tags": {
                                                    "bluePrint": selectedBlueprintDetails.id,
                                                    "deploymentName": deploymentName
                                                },
                                                "properties": {
                                                    "storageProfile": {
                                                        "osDisk": {
                                                            "osType": ImageDetails.customImageDetails[customImage].osType,
                                                            "osState": ImageDetails.customImageDetails[customImage].osState,
                                                            "blobUri": `[variables('osDiskVhdUri${index}')]`,
                                                            "storageAccountType": ImageDetails.customImageDetails[customImage].storageAccountType
                                                        }
                                                    },
                                                    "hyperVGeneration": "V1"
                                                }
                                            }
                                            imageCount++;
                                            armTemplate["properties"]["template"]["resources"][2 + imageCount] = {
                                                "type": "Microsoft.Batch/batchAccounts/pools",
                                                "apiVersion": "2019-04-01",
                                                "name": `[concat(parameters('batchAccountName'), '/${deploymentDetails1.data[`pool_name_${index}`]}')]`,
                                                "dependsOn": [
                                                    "[resourceId('Microsoft.Batch/batchAccounts', parameters('batchAccountName'))]",
                                                    `[variables('imageName${index}')]`
                                                ],
                                                "properties": {
                                                    "displayName": `${deploymentDetails1.data[`pool_name_${index}`]}`,
                                                    "vmSize": pool.vmSize.toUpperCase(),
                                                    "interNodeCommunication": "Disabled",
                                                    "maxTasksPerNode": 1,
                                                    "taskSchedulingPolicy": {
                                                        "nodeFillType": "Spread"
                                                    },
                                                    "deploymentConfiguration": {
                                                        "virtualMachineConfiguration": {
                                                            "imageReference": {
                                                                "id": `/subscriptions/${localStorage.getItem("sid")}/resourceGroups/${deploymentDetails1.resource_group}/providers/Microsoft.Compute/images/${imageName}`
                                                            },
                                                            "nodeAgentSkuId": ImageDetails.customImageDetails[customImage].nodeAgentSkuId
                                                        }
                                                    },
                                                    "scaleSettings": {
                                                        "fixedScale": {
                                                            "targetDedicatedNodes": 1,
                                                            "targetLowPriorityNodes": 0,
                                                            "resizeTimeout": "PT15M"
                                                        }
                                                    }
                                                }
                                            }
                                            armTemplate["properties"]["template"]["resources"][2 + imageCount]["properties"]["userAccounts"] = userAccounts;
                                            imageCount++;
                                        } else {
                                            let osType = pool.os;
                                            armTemplate["properties"]["template"]["resources"][2 + imageCount] = {
                                                "type": "Microsoft.Batch/batchAccounts/pools",
                                                "apiVersion": "2020-05-01",
                                                "name": `[concat(parameters('batchAccountName'), '/${deploymentDetails1.data[`pool_name_${index}`]}')]`,
                                                "dependsOn": [
                                                    "[resourceId('Microsoft.Batch/batchAccounts', parameters('batchAccountName'))]"
                                                ],
                                                "properties": {
                                                    "displayName": `${deploymentDetails1.data[`pool_name_${index}`]}`,
                                                    "vmSize": pool.vmSize.toUpperCase(),
                                                    "interNodeCommunication": "Disabled",
                                                    "maxTasksPerNode": 1,
                                                    "taskSchedulingPolicy": {
                                                        "nodeFillType": "Spread"
                                                    },
                                                    "deploymentConfiguration": {
                                                        "virtualMachineConfiguration": {
                                                            "imageReference": {
                                                                "publisher": ImageDetails.osDetails[osType].publisher,
                                                                "offer": ImageDetails.osDetails[osType].offer,
                                                                "sku": ImageDetails.osDetails[osType].sku,
                                                                "version": ImageDetails.osDetails[osType].version,
                                                            },
                                                            "nodeAgentSkuId": ImageDetails.osDetails[osType].nodeAgentSkuId
                                                        }
                                                    },
                                                    "scaleSettings": {
                                                        "fixedScale": {
                                                            "targetDedicatedNodes": 1,
                                                            "targetLowPriorityNodes": 0,
                                                            "resizeTimeout": "PT15M"
                                                        }
                                                    }
                                                }
                                            };
                                            armTemplate["properties"]["template"]["resources"][2 + imageCount]["properties"]["userAccounts"] = userAccounts;
                                            imageCount++;
                                        }
                                    })

                                    let strgName = 'mx' + Math.floor(Math.random() * 10000).toFixed();

                                    armTemplate["properties"]["template"]["variables"]["storageName"] = strgName;

                                    armTemplate["properties"]["template"]["resources"][0]["apiVersion"] = "2020-05-01";
                                    armTemplate["properties"]["template"]["resources"][0]["dependsOn"] = [strgName];
                                    armTemplate["properties"]["template"]["resources"][0]["tags"] = {
                                        "bluePrint": selectedBlueprintDetails.id,
                                        "deploymentName": deploymentName
                                    };
                                    armTemplate["properties"]["template"]["resources"][0]["properties"]["autoStorage"] = {
                                        "storageAccountId": `/subscriptions/${localStorage.getItem("sid")}/resourceGroups/${deploymentDetails1.resource_group}/providers/Microsoft.Storage/storageAccounts/${strgName}`
                                    };

                                    armTemplate["properties"]["template"]["resources"][0]["properties"]["encryption"] = {"keySource": "Microsoft.Batch"}

                                    armTemplate["properties"]["template"]["resources"][1] = {
                                        "name": strgName,
                                        "type": "Microsoft.Storage/storageAccounts",
                                        "apiVersion": "2019-06-01",
                                        "location": "[parameters('location')]",
                                        "tags": {
                                            "bluePrint": selectedBlueprintDetails.id,
                                            "deploymentName": deploymentName
                                        },
                                        "properties": {},
                                        "kind": "Storage",
                                        "sku": {
                                            "name": "Standard_LRS"
                                        }
                                    }
                                }else if(sessionStorage.getItem("resource") === "hpc-cache"){
                                    armTemplate["properties"]["parameters"]["location"]["value"] = deploymentDetails1.location;
                                    
                                    let maximum_throughput = deploymentDetails1.maximum_throughput;
                                    let maximum_throughput1 = maximum_throughput.replace('Up to ', 'Standard_')
                                    let maximum_throughput2 = maximum_throughput1.replace(' GB/s', 'G')

                                    armTemplate["properties"]["parameters"]["virtualNetworkName"]["value"] = "mx-" + randomValue + "-vnet";
                                    armTemplate["properties"]["parameters"]["storageCacheName"]["value"] = deploymentDetails1.cache_name;
                                    armTemplate["properties"]["parameters"]["cacheSizeGB"]["value"] = Math.pow(2, 10)*parseInt(deploymentDetails1.cache_size);
                                    armTemplate["properties"]["parameters"]["storageCacheSkuName"]["value"] = maximum_throughput2;

                                    armTemplate["properties"]["template"]["resources"][0]["tags"] = {
                                        "bluePrint": selectedBlueprintDetails.id,
                                        "deploymentName": deploymentName
                                    };
                                    armTemplate["properties"]["template"]["resources"][1]["tags"] = {
                                        "bluePrint": selectedBlueprintDetails.id,
                                        "deploymentName": deploymentName
                                    };
                                }else if(sessionStorage.getItem("resource") === "data-ingest"){
                                    // console.log(deploymentDetails1)
                                    if(deploymentDetails1.data.storage_account_type === "new"){
                                        armTemplate["properties"]["template"]["variables"]["storageName"] = deploymentDetails1.data.storage_account_name;
                                        armTemplate["properties"]["parameters"]["location"]["value"] = deploymentDetails1.data.location;
                                        armTemplate["properties"]["template"]["resources"][0]["tags"] = {
                                            "bluePrint": selectedBlueprintDetails.id,
                                            "deploymentName": deploymentName
                                        };
                                        armTemplate["properties"]["template"]["resources"][1]["tags"] = {
                                            "bluePrint": selectedBlueprintDetails.id,
                                            "deploymentName": deploymentName
                                        };
                                        armTemplate["properties"]["template"]["resources"][2]["tags"] = {
                                            "bluePrint": selectedBlueprintDetails.id,
                                            "deploymentName": deploymentName
                                        };
                                        armTemplate["properties"]["template"]["resources"][3]["tags"] = {
                                            "bluePrint": selectedBlueprintDetails.id,
                                            "deploymentName": deploymentName
                                        };
                                        armTemplate["properties"]["template"]["resources"][4]["tags"] = {
                                            "bluePrint": selectedBlueprintDetails.id,
                                            "deploymentName": deploymentName
                                        };
                                    }else if(deploymentDetails1.data.storage_account_type === "existing"){
                                        // console.log("existing")
                                    }
                                }

                                let bodyParams = {
                                    "aadToken": sessionStorage.getItem("accessTokenAD"),
                                    "graphToken": sessionStorage.getItem("msal.idtoken"),
                                    "tenantId": account.idToken.tid,
                                    "subscriptionId": localStorage.getItem("sid"),
                                    "armTemplateJson": JSON.stringify(armTemplate),
                                    "resourceGroupName": deploymentDetails1.resource_group,
                                    "deploymentName": sessionStorage.getItem("resource") === "simple" ? deploymentDetails1.deployment_name 
                                    :sessionStorage.getItem("resource") === "batch" ? deploymentDetails1.data.deployment_name 
                                    :sessionStorage.getItem("resource") === "hpc-cache" ? deploymentDetails1.cache_name
                                    :sessionStorage.getItem("resource") === "data-ingest" ? deploymentDetails1.data.storage_account_name /*+ "mxdataingest"*/
                                    :''
                                };

                                if(customImageFlg){
                                    bodyParams["customImageData"] = customImageDetails;
                                }

                                bodyParams["userDeploymentDetails"] = {
                                    "armCredentials": sessionStorage.getItem("accessTokenAD"),
                                    "graphCredentials": sessionStorage.getItem("msal.idtoken"),
                                    "tenantId": account.idToken.tid,
                                    "subscriptionId": localStorage.getItem("sid"),
                                    "email": account.userName,
                                    "fullName": account.name,
                                    "deploymentName": sessionStorage.getItem("resource") === "simple" ? deploymentDetails1.deployment_name 
                                    :sessionStorage.getItem("resource") === "batch" ? deploymentDetails1.data.deployment_name 
                                    :sessionStorage.getItem("resource") === "hpc-cache" ? deploymentDetails1.cache_name
                                    :sessionStorage.getItem("resource") === "data-ingest" ? deploymentDetails1.data.storage_account_name
                                    :'',
                                    "deploymentId": "-",
                                    "adminUsername": deploymentDetails1.admin_name,
                                    "isPassword": !!deploymentDetails1.password,
                                    "adminPassword": deploymentDetails1.password ?? "--",
                                    "sshPublicKey": deploymentDetails1.ssh_public_key ?? "--"
                                };
                                if (sessionStorage.getItem("resource") === "batch") {
                                    bodyParams["userDeploymentDetails"]["type"] = "batch";
                                    bodyParams["userDeploymentDetails"]["batchName"] = deploymentDetails1.data.batch_name;
                                    let poolArray = [];
                                    deploymentDetails1.pool_section.forEach((pool, index) => {
                                        poolArray.push({
                                            "poolName":deploymentDetails1.data[`pool_name_${index}`],
                                            "userName": deploymentDetails1.data[`admin_name_${index}`],
                                            "isPassword":  !!deploymentDetails1.data[`password_${index}`],
                                            "passwordOrKey": pool.adminType === 'ssh'?deploymentDetails1.data[`ssh_public_key${index}`]:deploymentDetails1.data[`password_${index}`]
                                        });
                                    });
                                    bodyParams["userDeploymentDetails"]['poolCredentials'] = poolArray;
                                }else if(sessionStorage.getItem("resource") === "simple"){
                                    bodyParams["userDeploymentDetails"]["type"] = "simple";
                                }else if(sessionStorage.getItem("resource") === "hpc-cache"){
                                    bodyParams["userDeploymentDetails"]["type"] = "hpc-cache";
                                }

                                msalApp.acquireTokenSilent(GRAPH_REQUESTS.ACTIVE_DIRECTORY).then(response => {
                                    fetch("/api/azure/deployment.create", {
                                        method: "post",
                                        headers: {
                                            "Content-Type": "application/json",
                                            "auth-token": response.accessToken
                                        },
                                        body: JSON.stringify(bodyParams)
                                    }).then((res) => {
                                        return res.text();
                                    }).then((data) => {
                                        data = JSON.parse(data);
    
                                        // localStorage.clear();   //Temp fix - commented because in deployment accepted screen the blueprint name is null
                                        sessionStorage.removeItem("customImage");
                                        sessionStorage.removeItem("resource");
                                        sessionStorage.removeItem("selectedBlueprintDetails");
    
                                        if (data.code === "Deployment.Status.Failed") {
                                            setStatus("Deployment Failed");
                                            setErrorDetails([data]);
                                        } else {
                                            setStatus("Deployment Accepted");
                                            setInterval(function(){ setPageRedirectionFlag(true); }, 5000);
                                        }
                                    });
                                });

                                
                            }
                        }
                    }
                });
            }
            fetchTemplateData();
        } else {
            setPageRedirectionFlag(true);
        }
    },[]);



    return(
        pageRedirectionFlag ?
        <Redirect to="/" />:
        <div>
            <CssBaseline/>
            <main className={classes.layout}>
                <Headline classes={classes} />
                <Paper elevation={3}>
                    <div className={classes.statusTitle}>
                        Deployment Status
                    </div>
                    <div className={classes.statusLoaderPlaceholder}>
                        {renderStatus(status)}
                    </div>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <div className={classes.statusDeployment}>
                                {status}
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            {
                                status === "Deployment Failed" || internalError === true ?
                                    errorDetails.length>0 ? <div className={classes.errorTableContainer}>
                                        <table id="error_table" className={classes.errorTable}>
                                            <tbody>
                                            <tr className={classes.errorTableHeading}>
                                                <th>Error Code</th>
                                                <th>Message</th>
                                            </tr>
                                            {
                                                errorDetails.map( error => {
                                                    return (
                                                        <tr key={error.code}>
                                                            <td>
                                                                {error.code}
                                                            </td>
                                                            <td>
                                                                {error.message}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>:
                                        <div className={classes.statusParagraph}>
                                            Once deployment is successful, we will send you an email.
                                        </div>
                                    :
                                    <div className={classes.statusParagraph}>
                                        You can also check your selected subscription later to access the provisioned resources. Once deployment is completed, We will send you a confirmation email with the access info.
                                    </div>
                            }
                        </Grid>
                    </Grid>
                </Paper>

            </main>
        </div>
    )
}
