import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Badge, Button, IconButton, Typography, Radio} from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from "@material-ui/icons/Close";
import Banner from "../assert/images/Baner.png";
import research from "../assert/images/research.png";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const drawerWidth = 237;

const styles = theme => ({
    backgroundVector: {
        height: '85%',
        // position: 'absolute',
    },
    root: {
        flexGrow: 1,
        display: 'flex',
        // backgroundColor: theme.palette.background.paper,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: '#fff',
        color: '#000',
    },
    appBarLogoPlaceholder: {
        background: '#ffffff',
        color: '#fff',
        position: 'absolute',
        left: '0px',
        width: drawerWidth,
        height: '100%',
    },
    appBarLogo: {
        height: '100%',
        textAlign: 'center',
        paddingTop: '15px',
    },
    appBarMenu: {
        marginLeft: 'auto',
    },
    appBarProfileIcon: {
        fontSize: '35px',
    },
    appBarNotificationIcon: {
        fontSize: '35px',
    },
    appBarProfileName: {
        paddingLeft: '5px',
        fontWeight: '500',
    },
    menuIcon: {
        marginLeft: theme.spacing(2),
    },
    menuName: {
        marginLeft: theme.spacing(1),
    },
    menu: {
        margin: '5px 7px 0px 7px',
        padding:'12px 0 12px 0',
        color: "#fff",
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    navBar: {
        backgroundColor: "#2c343b",
        height: "100%",
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    logo:{
        marginRight: 'auto',
        marginLeft: 0,
        width: 250,
        height: 55,
    },
    Headline: {
        marginTop: theme.spacing(2),
    },
    list: {
        marginTop: theme.spacing(3),
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    layout: {
        width: 'auto',
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: "100%",
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    poolSectionPaper :{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        padding: theme.spacing(3),  
    },
    paper: {
        marginTop: theme.spacing(3),
        // marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(0),
            // marginBottom: theme.spacing(6),
            padding: theme.spacing(5),
        },
    },
    heading:{
        fontSize: '35px',
        fontWeight: 'bold',
        marginBottom: '10px',
        [theme.breakpoints.down(600 + theme.spacing(3) * 2)]: {
            marginLeft: '0px',
            textAlign:'center',
        },
    },
    headingAppBarSpace: {
        marginTop: '20px',
    },
    headingLogo: {
        width: '200px',
        marginLeft: '125px',
        [theme.breakpoints.down(600 + theme.spacing(3) * 2)]: {
            marginLeft: '70px',
            textAlign:'center',
        },
    },
    layoutLogo: {
        width: '200px',
        paddingLeft: '25px',
        paddingTop: '5px',
    },
    layoutPreview: {
        // textAlign: 'center',
        fontSize: '10px',
        float: 'right',
        color: 'black',
        position:'absolute',
        left:'200px',
        top:'42px',
    },

    //LandingPage CSS
    landingPageLogo:{
        fontSize: '25px',
        fontWeight: 'bold',
        margin: '10px 10px 10px 20%',
    },
    landingPageSignInButton:{
        float: 'right',
        margin: '10px 20% 10px 100px',

        [theme.breakpoints.down(600 + theme.spacing(3) * 2)]: {
            float: 'left',
            margin: '30px 0 0px 150px',
        },
    },
    landingPageContent:{
        marginTop: '30px',
        backgroundImage:`url(${Banner})`,
        backgroundSize:'cover',
    },
    landingPageHeadline:{
        width: '100%',
        textAlign: 'left',
        fontSize: '45px',
        fontWeight: 'bold',
        lineHeight: '110%',
        marginTop: '20%',
        color:'#fff',
        padding:'10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '25px',
        },
    },
    landingPageHeadline2:{
        color: '#1A73E7',
        textAlign: 'left',
        padding:'20px',

    },
    greenbgsection:{
        background:'#06818B',
        padding:'100px 100px',
        fontSize:'30px',
        textAlign:'center',
        color:'#fff',
        [theme.breakpoints.down('xs')]: {
            padding:'30px 30px',
            fontSize:'12x',
        },

    },

    maxwidth:{
        width:'100%',
    },
    benefictssection:{
        padding:'20px',
        background:'rgba(0, 0, 0, 0.6);',
        float: 'left',
        marginTop:'20px',
    },
    landingPageFeatureContentHeadingtop:{
        paddingBottom:'20px',
        fontSize:'18px',

    },
    beneficts:{
        backgroundImage:`url(${research})`,
        backgroundSize:'cover',
        padding:'100px 0  100px 0',
        color:'#fff',
        [theme.breakpoints.down('sm')]: {
            padding:'20px 0  20px 0',


        },
    },
    benefictssectionContainer:{
        padding:'10px 0 0 0',
        float:'left',
        color:'#fff',
    },
    benefictssectionContainerSubheding:{
        padding:'10px 0 1px 20px',
        float:'left',
        fontSize:'22px',
    },

    benefictssectionContainersecondsection:{
        marginTop:'30px',
        float:'left',
    },

    benefictheding:{
        padding:'5px 0 0 20px',
    },

    benefictssectionDescription:{
        padding:'5px 0 0 20px',
        fontSize:'20px',
    },
    consectionShape:{
        textAlign:'center',
        fontSize:'25px',
        padding:'100px 0 80px 0',
        background:'#F5F2FE',
        [theme.breakpoints.down('sm')]: {
            fontSize:'15px',
            padding:'30px 0 30px 0',
        },
    },

    footerContent:{
        padding:'20px',
        textAlign:'center',
    },

    landingPagecontainer:{
        background:'rgba(0, 0, 0, 0.6);',
        paddingBottom:'70px',
        marginBottom:'200px',
        marginTop:'150px',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
    },

    BlueprintParagraph:{
        marginBottom:'30%',
        paddingBottom:'10%',
        color:'#000',
        padding:'20px',
    },

    landingPageHeadParagraph:{
        fontSize: '20px',
        color:'#fff',
        paddingBottom:'10px',
        paddingLeft:'20px',
    },

    landingPageLandingImageHolder:{
        width: '100%',
        textAlign: 'center',
    },

    landingPageLandingImage:{
        width: '75%',
    },

    landingPageGreyDotHolder:{
        position: 'absolute',
        zIndex: 3,
        right: '205px',
        marginTop: '-110px',
    },

    landingPageGreyDot:{
        width: '10px',
        marginRight:'20px',
        marginTop: '20px',
    },
    
    landingPageFeaturesCard: {
        backgroundColor: '#fff',
        position: 'relative',
        zIndex: 2,
        width: '98%',
        border: '1px solid gray',
        borderRadius: '0px 20px 20px 0px',
        borderLeft: '1px solid #fff',
        marginTop: '-35px',
    },

    landingPageBackground: {
        backgroundColor: '#e6e3ef',
        position: 'absolute',
        zIndex: 2,
        width: '100%',
        height: '550px',
        marginTop: '125px',
    },

    landingPageFeatures: {
        margin: '6% 14% 7% 5%',
    },
    landingPageFeaturesCardName: {
        fontSize: '17px',
        fontWeight: '500',
    },
    landingPageFeaturesTitleContainer: {
        marginTop: '20px',
        marginBottom: '60px',
    },
    landingPageFeaturesTitle: {
        display: 'inline-block',
        verticalAlign: 'middle',
        fontSize: '40px',
        fontWeight: '700',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
        },

    },
    landingPageFeatureContentIcon: {
        width: '80px',
        [theme.breakpoints.down('sm')]: {
            width: '40px',
        },
    },
    landingPageFeatureYellowCircle: {
        width: '73px',
        height: '73px',
        borderRadius: '100px',
        textAlign: 'center',
        verticalAlign: 'middle',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    landingPageFeatureOrangeCircle: {
        width: '73px',
        height: '73px',
        borderRadius: '100px',
        display: 'table-cell',
        textAlign: 'center',
        verticalAlign: 'top',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height:'auto',
            float:'left',
        },
    },
    landingPageFeatureSkyBlueCircle: {
        width: '73px',
        borderRadius: '100px',
        display: 'table-cell',
        textAlign: 'center',
        verticalAlign: 'top',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height:'auto',
            float:'left',
        },
    },
    landingPageFeatureBlueCircle: {
        width: '73px',
        height: '73px',
        borderRadius: '100px',
        display: 'table-cell',
        textAlign: 'center',
        verticalAlign: 'top',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height:'auto',
            float:'left',
        },
    },
    IconTop:{
        width:'100%',
        float:'left',
    },
    topiconitem:{
        float:'left',
    },
    mainPagePreview: {
        width: '250px',
        marginLeft: '165px',
        textAlign: 'center',
    },
    optional: {
        fontSize: '10px',
    },

    featureicon:{
        width:'40px',
        paddingTop:'10px',
    },

    landingPageFeatureContentList: {
        marginBottom: '40px',
    },
    landingPageFeatureContentContainer: {
        display: 'table-cell',
        verticalAlign: 'middle',
    },
    landingPageFeatureContentHeading:{
        marginLeft: '30px',
        fontSize: '25px',
        fontWeight: '700',
    },
    landingPageFeatureContentParagraph:{
        fontWeight: 'normal',
        marginTop: '10px',
        fontSize: '20px',
    },
    landingPageBackground2: {
        backgroundColor: '#1CB0BE',
        position: 'absolute',
        zIndex: 2,
        width: '100%',
        height: '400px',
        marginTop: '550px',
    },
    landingPageSectionCardContent:{
        // display: 'inline-block',
        // verticalAlign: 'middle',
        margin: '11% 20% 0% 20%',
        zIndex: 3,
        color: '#fff',
        textAlign: 'center',
        fontSize: '25px',
        fontWeight: '500',
        lineHeight: '175%',
    },

    //Blueprint CSS
    submitBlueprint: {
        float: 'right',
        fontSize: '17px',
        fontWeight: '500',
        marginBottom: '10px',
        marginRight: '25px',
        cursor: 'pointer',
    },
    submitBlueprintFormFields: {
        marginBottom: theme.spacing(2),
    },
    blueprintMenuHolder: {
        width: '90%',
        position: 'relative',
    },
    blueprintMenuPaper: {
        // margin: '15px 40px 0px 5px',
        margin: '15px 65px 0px 5px',
        minHeight: theme.spacing(15),
        cursor: 'pointer',
    },
    blueprintMenuPaperSelected: {
        // margin: '15px 40px 0px 5px',
        margin: '15px 65px 0px 5px',
        minHeight: theme.spacing(15),
        backgroundColor: "#1e9ff2 !important",
        color: "#fff !important",
        cursor: 'pointer',
    },
    blueprintDetailsPaper: {
        // margin: '15px 40px 0px 5px',
        margin: '15px 25px 30px 30px',
    },
    blueprintMenuHeading: {
        fontSize: '15px',
        fontWeight: '500',
        margin: '15px 15px 10px 0px',
    },
    blueprintMenuParagraph: {
        fontSize: '12px',
        marginBottom: '20px',
    },

    blueprintIconBox: {
        width: '50px',
        height: '50px',
        border: '1px solid gray',
        borderRadius: '10px 0px 10px 0px',
        backgroundColor: '#fff',
        display: 'table-cell',
        textAlign: 'center',
        verticalAlign: 'middle',
    },
    blueprintIconHolder: {
        margin: '20px 0px 0px 10px',
    },
    blueprintIcon: {
        width: '25px',
    },
    blueprintHeadingIconCircle: {
        width: '45px',
        height: '45px',
        backgroundColor: "#e8f2ff",
        borderRadius: '100px',
        display: 'table-cell',
        textAlign: 'center',
        verticalAlign: 'middle',
        paddingTop:'10px'
    },
    blueprintHeadingIcon: {
        width: '20px',
    },
    trianglePosition: {
        float: 'right',
        margin: '-20px',
    },
    triangleRight: {
        width: 0,
        height: 0,
        borderTop: '8px solid transparent',
        borderLeft: '14px solid #1e9ff2',
        borderBottom: '8px solid transparent',
    },
    blueprintDetailsHeading: {
        fontSize: '25px',
        fontWeight: 'bold',
        padding: '20px 15px 20px 15px',
    },
    blueprintDetailsParagraph: {
        fontSize: '14px',
        padding: '10px 15px 20px 25px',
        lineHeight: '200%',
    },
    blueprintDetailsParagraphStyle: {
        marginBottom: '10px',
    },
    blueprintDetailsDeployButton: {
        textAlign: 'right',
        padding: '10px 30px 50px 0px'
    },

    // Deploy Page CSS
    deployHeading: {
        fontSize: '30px',
        fontWeight: 'bold',
        marginBottom: '30px',
    },
    deploymentTitle: {
        fontSize: '20px',
        fontWeight: '500',
        padding: '25px 0px 25px 25px',
    },
    subscriptionForm: {
        padding: '10px 25px 25px 25px',
    },
    subscriptionImage: {
        width: '85%',
        float: 'right',
        margin: '10px 50px 25px 10px',
    },
    subscriptionButtons: {
        textAlign: 'right',
        padding: '20px 0px 50px 0px'
    },
    subscriptionButton: {
        marginLeft: '10px',
    },
    addPoolButton: {
        marginLeft: '10px',
    },
    addPoolButtonAddIcon: {
        marginRight: '10px',
    },
    subscriptionButtonNextIcon: {
        marginLeft: '10px',
    },
    subscriptionButtonBackIcon: {
        marginRight: '10px',
    },
    paramsPaperBackground: {
        backgroundColor: '#f5f5f5',
        display: 'inline-block',
        margin: '10px 10px 10px 10px',
        width: '-webkit-fill-available',
    },
    paramsTitleIconPlaceholder: {
        padding: '25px 0px 25px 25px',
    },
    paramsTitleIcon: {
        width: '50px',
    },
    paramsTitle: {
        fontSize: '30px',
        fontWeight: '500',
        padding: '15px 0px 25px 0px',
    },
    paramsFormField: {
        marginLeft: '16px !important',
        marginRight: '24px !important',
        marginTop: '24px !important',
        marginBottom: '24px !important',
        width: '90%',
        backgroundColor: '#fff',
    },
    paramsFormFieldForAutentication: {
        marginLeft: '16px !important',
        marginRight: '24px !important',
        marginTop: '24px !important',
        marginBottom: '48px !important',
        width: '90%',
        backgroundColor: '#fff',
    },
    paramsFormFieldForCheck: {
        marginLeft: '16px !important',
        marginRight: '24px !important',
        marginTop: '24px !important',
        width: '90%',
        backgroundColor: '#fff',
        borderColor: 'red',
    },
    paramsAddResourceGroupHolder: {
        display: "flex",
        alignItems: "center",
        height: '90%',
        marginLeft: '6%',
    },
    paramsAdministratorTitle: {
        fontSize: '20px',
        fontWeight: '500',
        padding: '20px 0px 25px 25px',
    },
    poolSectionTitle: {
        fontSize: '20px',
        fontWeight: '500',
        padding: '20px 0px 25px 25px',
    },
    ImageTypeSelection: {
        padding: '25px 0px 0px 25px',
    },
    paramsAdministratorRadioButtonHolder: {
        padding: '0px 0px 0px 25px',
    },
    paramsAdministratorRadioButtons: {
        padding: '0px 0px 25px 25px',
    },
    summaryHeading: {
        fontSize: '17px',
        fontWeight: '600',
        padding: '0px 0px 10px 25px',
    },
    summaryHeading2: {
        fontSize: '17px',
        fontWeight: '600',
        padding: '0px 0px 50px 25px',
    },
    summaryParagraph: {
        fontSize: '17px',
        fontWeight: '300',
    },
    summaryResourceIcon: {
        width: '45px',
    },
    summaryResourceTitle: {
        fontSize: '25px',
        fontWeight: '600',
        padding: '15px 0px 25px 0px',
    },
    statusTitle: {
        fontSize: '22px',
        fontWeight: '600',
        padding: '25px 0px 25px 25px',
    },
    statusDeployment: {
        fontSize: '30px',
        fontWeight: '700',
        margin: '15px 0px 15px 0px',
    },
    statusParagraph: {
        fontSize: '16px',
        fontWeight: '300',
        marginBottom: '35%',
        paddingLeft: '10%',
        paddingRight: '10%',
    },
    statusLoaderPlaceholder: {
        marginTop: '5%',
        width: '100%',
        textAlign: '-webkit-center',
    },
    statusLoader: {
        width: '10%',
    },
    statusError: {
        fontSize: '80px',
        color: 'red',
    },
    statusWarning: {
        fontSize: '80px',
        color: '#ff922c',
    },
    statusSuccess: {
        fontSize: '80px',
        color: 'green',
    },
    errorTableContainer:{
        width: '100%',
    },
    errorTable : {
        // marginTop: '20px',
        width : '80%',
        margin: '3% 10% 5% 10%',
        borderCollapse : 'collapse',
        border: '1px solid #424e58',
        '& th' : {
            border: '1px solid #424e58',
        },
        '& td' : {
            border: '1px solid #424e58',
            padding: '10px 10px 10px 10px'
        }
    },
    errorTableHeading: {
        fontWeight: "600",
    },
    fetchingSubscription: {
        margin: '20px',
        textAlign: 'center',
    },
    fetchingBlueprint: {
        margin: '20px',
        textAlign: 'center',
    },
    subscriptionMessage: {
        // margin: '45px',
        lineHeight: '220%',
        textAlign: 'justify',
        textJustify: 'inter-word',
    },
    priceLabel: {
        fontSize: '20px',
        fontWeight: '500',
        padding: '40px 0px 25px 25px',
    },
    priceLabelEstimate: {
        fontSize: '10px',
        position: 'absolute'
    },
    loadingPrice: {
        padding: '40px 0px 25px 25px',
    },
    deploymentListTable: {
        '&table': {
            width: '100%'
        },
        '&td, th': {
            border: '1px solid #dddddd',
            textAlign: 'left',
            padding: '8px',
            wordWrap: 'break-word'
        },
        '&tr:nth-child(even)': {
            backgroundColor: '#dddddd',
        }
    },
    deploymentListTableLink: {
        textAlign: 'center',
    },
    blueprintSearchBarDiv: {
        marginLeft: '200px'
    },
    blueprintMenuTitle: {
        overflow: 'auto'
    },
    tagsParamsFormField: {
        marginLeft: '16px',
        marginRight: '24px',
        marginTop: '24px',
        marginBottom: '24px',
        width: '90%',
        backgroundColor: '#fff',
    },
    tableContainer: {
        marginTop: '10px',
    },
    buttonSuccess: {
        backgroundColor: "green",
        "&:hover": {
          backgroundColor: "green"
        }
    },
    fabProgress: {
        color: "darkblue",
        position: "absolute",
        left: -3,
        zIndex: 1
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
        display:'inline'
    },
});

// Custom Override Theme

export const DeployButton = withStyles({
    root: {
        background: '#1e9ff2',
        borderRadius: '5px',
        border: 0,
        color: '#fff',
        height: 40,
        padding: '0 35px',
        fontSize: '15px',
        boxShadow: '0 3px 5px 2px rgba(0,0,0, .2)',
        "&:hover": {
            backgroundColor: "#424e58",
        }
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

export const NextButton = withStyles({
    root: {
        background: '#1e9ff2',
        borderRadius: '10px',
        border: 0,
        color: '#fff',
        height: 40,
        padding: '0 30px',
        fontSize: '15px',
        boxShadow: '0 3px 5px 2px rgba(0,0,0, .2)',
        "&:hover": {
            backgroundColor: "#424e58",
        },
        "&:disabled": {
            backgroundColor: "#CBCBCB",
            boxShadow: '0 0px 0px 0px',
            color: "000",
        }
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

export const BackButton = withStyles({
    root: {
        background: '#000000',
        borderRadius: '10px',
        border: 0,
        color: '#fff',
        height: 40,
        padding: '0 30px',
        fontSize: '15px',
        boxShadow: '0 3px 5px 2px rgba(0,0,0, .2)',
        "&:hover": {
            backgroundColor: "#424e58",
        }
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

export const NotificationBadge = withStyles(theme => ({
    badge: {
        right: 0,
        top: 3,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        backgroundColor: '#c03d1c',
        color: '#fff',
    },
}))(Badge);

export const BlueRadio = withStyles({
    root: {
        color: '#1e9ff2',
        '&$checked': {
            color: '#1e9ff2',
        },
    },
    checked: {},
})(props => <Radio color="default" {...props} />);

const dialogStyles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        width: '600px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

export const DialogTitle = withStyles(dialogStyles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        textAlign:'center'
    },
    body: {
        fontSize: 14,
        textAlign:'center'        
    },
}))(TableCell);

export const CustomTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        // "&:hover": {
        //     cursor: "pointer"
        // }
    },
}))(TableRow);


export const dashboardStyles = makeStyles(theme => styles(theme));
export const dashboardStylesCore = theme => styles(theme);
