import React, {useEffect} from "react";
import {
    Button,
    CssBaseline,
    Dialog, FormControl,
    Grid, InputLabel, MenuItem, Select,
    TextField
} from "@material-ui/core";
import { Headline } from "./headline";
import {
    CustomTableCell,
    CustomTableRow,
    dashboardStyles,
    DialogActions,
    DialogContent,
    DialogTitle,
    NextButton
} from "../../styles";
import {msalApp} from "../Authentication/auth-utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useForm} from "react-hook-form";
import {useDropzone} from "react-dropzone";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';


export default function Index() {
    const classes = dashboardStyles();

    const [deploymentList, setDeploymentList] = React.useState([]);
    const [deploymentListFlag, setDeploymentListFlag] = React.useState(true);

    useEffect(()=> {
        const account = msalApp.getAccount();
        const org = account.userName.split('@');
        const fields = "Name,Organization,Status,RequestedDate";
        fetch("/api/azure/template.get?fields=" + fields + "&userName=" + account.userName + "&requestedUserName="+account.userName + "&organization=" + org[1], {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            }
        }).then((res)=>{
            return res.text();
        }).then((val)=>{
            setDeploymentListFlag(false);
            if(val){
                try {
                    val = JSON.parse(val);
                    setDeploymentList(val.result);
                }
                catch (e) {
                    console.log("Error : deployment.get.user");
                }
            }
        });
    },[setDeploymentList]);

    const [open, setOpen] = React.useState(false);
    const [redirect, setRedirect] = React.useState(false);
    const [tagSuggestions, setTagSuggestions] = React.useState(["#light_weight", "#shared_memory", "#serial_workload"]);
    const [selectedTags, setSelectedTags] = React.useState([]);
    const [selectedType, setSelectedType] = React.useState("simple");
    const [tagsInputValue, setTagsInputValue] = React.useState("");
    const [armTemplate, setArmTemplate] = React.useState(null);
    const [postData, setPostData] = React.useState(false);
    const [acceptFile,setAcceptFile]=React.useState(0);
    const [acceptFilePath,setAcceptFilePath]=React.useState(null);
    const [jsonStatus,setjsonStatus]=React.useState(true);
    const [fileUploadStatus,setfileUploadStatus]=React.useState(true);
    const { register, handleSubmit, errors } = useForm();

    function UploadFile(props) {

        const {getRootProps, getInputProps, isDragReject, open, acceptedFiles} = useDropzone({
            // Disable click and keydown behavior
            noClick: true,
            noKeyboard: true,
            accept: 'application/json',
            multiple : false,
        });

        if(acceptedFiles.length > 0){

            setAcceptFile(acceptedFiles.length);
            setAcceptFilePath(acceptedFiles[0].path);

            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                fetchData(reader.result);
            }
            reader.readAsText(acceptedFiles[0]);
        }

        function fetchData(data) {
            try{
                setjsonStatus(true);
            }catch (e) {
                setjsonStatus(false);
            }
            setArmTemplate(JSON.parse(data));
        }

        function deleteFile() {
            setAcceptFile(0);
            setArmTemplate(null);
            setjsonStatus(true);
            setAcceptFilePath(null);
            setfileUploadStatus(true);
        }

        return (
            <div className="container">
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <p>Upload ARM Template (Optional)</p>
                    <Button variant="contained" onClick={open}>Choose</Button>
                </div>
                <aside>
                    <h4>Files</h4>
                    {
                        isDragReject ?
                            <p>File type not accepted, sorry!</p>
                            :
                            <div>
                                {
                                    acceptFile ===0 ? <p>No Files Selected.</p> :
                                        <div>
                                            <table style={{ width: 500 }}>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        {acceptFilePath}

                                                    </td>
                                                    <td style={{ width: 30 }}>
                                                        {jsonStatus ? (<CheckCircleTwoToneIcon style={{color:"green"}}  />):(<CancelIcon style={{color:"red"}} />)}
                                                    </td>
                                                    <td style={{ width: 30 }}>
                                                        <DeleteIcon onClick={deleteFile} />
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>



                                        </div>

                                }
                            </div>
                    }
                </aside>
            </div>
        );
    }

    const handleSubmitBlueprintOpen = () => {
        setAcceptFile(0);
        setArmTemplate(null);
        setjsonStatus(true);
        setAcceptFilePath(null);
        setfileUploadStatus(true);
        setOpen(true);
    };

    const handleSubmitBlueprintClose = () => {
        setOpen(false);
    };

    function onSubmitdata(data) {
        setPostData(true);
        const account = msalApp.getAccount();
        data.armTemplate = armTemplate;
        data.status = "Pending";
        data.userName = [account.userName];
        const org = account.userName.split('@');
        data.organization = [org[1]];
        data.tags = selectedTags;
        data.type = selectedType;


        fetch("/api/azure/template.save", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }).then((res) => {
            return res.text();
        }).then((val) => {
            setPostData(false);
            setRedirect(true);
        })
    }

    const onSubmit =  data => {
        jsonStatus?onSubmitdata(data):setfileUploadStatus(false);
    };

    const typeList = [
        {
            "key" : "simple",
            "title" : "Simple",
        },
        {
            "key" : "batch",
            "title" : "Batch",
        },
    ];

    return (
        <div  className={classes.backgroundVector} >
            <CssBaseline/>
            <main className={classes.layout}>
                <Headline classes = {classes} handleSubmitBlueprintOpen={handleSubmitBlueprintOpen} handleSubmitBlueprintClose={handleSubmitBlueprintClose}/>

                <Dialog onClose={handleSubmitBlueprintClose} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={handleSubmitBlueprintClose}>
                        Request Blueprint
                    </DialogTitle>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    type="text"
                                    name="name"
                                    label="Blueprint Name"
                                    variant="outlined"
                                    error={!!errors.name}
                                    className={classes.paramsFormField}
                                    helperText={errors.name && errors.name.message}
                                    inputRef={register({
                                        required: "Blueprint Name is Required",
                                        pattern: {
                                            value: /^[\sa-zA-Z0-9]*$/,
                                            message: "Blueprint Name should contains letters and numbers"
                                        },
                                        maxLength: { value: 20, message: "Blueprint Name not valid" }
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    type="text"
                                    name="tagLine"
                                    label="Tag Line"
                                    variant="outlined"
                                    error={!!errors.tagLine}
                                    className={classes.paramsFormField}
                                    helperText={errors.tagLine && errors.tagLine.message}
                                    inputRef={register({
                                        required: "Tag Line is Required",
                                        pattern: {
                                            value: /^[\sa-zA-Z0-9]*$/,
                                            message: "Tag Line should contains letters and numbers"
                                        },
                                        maxLength: { value: 30, message: "Tag Line length should be 30" }
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    type="text"
                                    name="description"
                                    label="Description"
                                    variant="outlined"
                                    multiline rows={3} rowsMax={3}
                                    error={!!errors.description}
                                    className={classes.paramsFormField}
                                    helperText={errors.description && errors.description.message}
                                    inputRef={register({
                                        required: "Description is Required",
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <input type="text" placeholder="type_hidden" value={selectedType} name="type" ref={register({required: "Select Type"})} readOnly hidden/>
                                <FormControl
                                    variant="outlined"
                                    error={!!errors.type}
                                    className={classes.paramsFormField}
                                >
                                    <InputLabel id="type">Blueprint Type</InputLabel>
                                    <Select
                                        value={selectedType}
                                        onChange={event => {
                                            setSelectedType(event.target.value);
                                            errors.type = false;
                                        }}
                                        label="Operating System"
                                    >
                                        {
                                            typeList.map(resource =>
                                                <MenuItem key={resource["key"]} value={resource["key"]}>{resource["title"]}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                {/*<input type="text" placeholder="tags_hidden" value={selectedTags} name="tags" ref={register()} readOnly hidden/>*/}
                                <Autocomplete
                                    multiple
                                    id="tag_suggestion"
                                    options={tagSuggestions}
                                    getOptionLabel={(option) => option}
                                    filterSelectedOptions
                                    onInputChange={(event, newInputValue) => {
                                        if(newInputValue !== " "){
                                            setTagsInputValue(newInputValue);
                                        }
                                        if(tagsInputValue.length && newInputValue[newInputValue.length-1] === " "){
                                            const tempTags = tagsInputValue.trim();
                                            if(tagSuggestions.indexOf(tempTags) < 0){
                                                let suggestion = tagSuggestions;
                                                suggestion.push(tempTags);
                                                setTagSuggestions(suggestion);
                                            }
                                            if(selectedTags.indexOf(tempTags) < 0){
                                                let tempSelected = selectedTags;
                                                tempSelected.push(tempTags)
                                                setSelectedTags(tempSelected);
                                            }
                                            setTagsInputValue("");
                                        }
                                    }}
                                    onBlur={(event) => {
                                        if(tagsInputValue.length && tagsInputValue !== " "){
                                            const tempTags = tagsInputValue.trim();
                                            if(tagSuggestions.indexOf(tempTags) < 0){
                                                let suggestion = tagSuggestions;
                                                suggestion.push(tempTags);
                                                setTagSuggestions(suggestion);
                                            }
                                            if(selectedTags.indexOf(tempTags) < 0){
                                                let tempSelected = selectedTags;
                                                tempSelected.push(tempTags)
                                                setSelectedTags(tempSelected);
                                            }
                                            setTagsInputValue("");
                                        }
                                    }}
                                    inputValue={tagsInputValue}
                                    value={selectedTags}
                                    onChange={(event, newValue) => {
                                        setSelectedTags(newValue);
                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            type="text"
                                            name="selected_tags"
                                            label="Tags"
                                            value={tagsInputValue}
                                            variant="outlined"
                                            error={!!errors.selected_tags}
                                            className={classes.tagsParamsFormField}
                                            helperText={errors.selected_tags && errors.selected_tags.message}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    type="text"
                                    name="resourceConfig"
                                    label="Resource Config"
                                    variant="outlined"
                                    multiline rows={6} rowsMax={12}
                                    error={!!errors.resourceConfig}
                                    className={classes.paramsFormField}
                                    helperText={errors.resourceConfig && errors.resourceConfig.message}
                                    inputRef={register()}
                                />
                            </Grid>
                            {/* <Link className={classes.submitBlueprint} href={helpFile} target="_blank" rel="noopener noreferrer" download>
                                Download help file
                            </Link> */}
                            <UploadFile />

                            {
                                sessionStorage.getItem("trail") !== "Active" || true ?

                                    redirect ?
                                        // <Redirect to="/user.blueprints.list"/>
                                        window.location.href = "/portal/user.blueprints.list"
                                        :
                                        postData ?
                                            <div>
                                                <CircularProgress /> Saving Data...
                                            </div>
                                            :
                                            <div>

                                                {
                                                    fileUploadStatus  ? <p></p>:<p style={{color:"red",fontSize:12}}>Json Validation failed... Please check the file!!..</p>
                                                }

                                                <NextButton className={classes.subscriptionButton} type="submit">
                                                    Submit
                                                </NextButton>

                                            </div>
                                    :
                                    <div>
                                        <Button disabled={true} variant="contained" >Submit</Button>
                                        {/* <p>
                                            Note : You can't submit your custom blueprint when you have in trail period.
                                            To Activate your account, please contact (
                                            <a href="mailto:'msedu@terawe.com'">msedu@terawe.com</a>)
                                        </p> */}
                                    </div>
                            }
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <form>
                            <Button
                                fullWidth
                                variant="contained"
                                autoFocus
                                onClick={handleSubmitBlueprintClose}
                            >
                                Close
                            </Button>
                        </form>
                    </DialogActions>
                </Dialog>

                {
                    deploymentListFlag ?
                        <div>
                            <CircularProgress />
                        </div>
                        :
                        deploymentList.length ?
                            <TableContainer className={classes.tableContainer}>
                                <Table aria-label="Custom Blueprint">
                                    <TableHead>
                                        <TableRow>
                                            <CustomTableCell>Blueprint Name</CustomTableCell>
                                            <CustomTableCell>Organization</CustomTableCell>
                                            <CustomTableCell>RequestedDate</CustomTableCell>
                                            <CustomTableCell>Status</CustomTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            deploymentList.map((val, index) => (
                                                <CustomTableRow key={index}>
                                                    <CustomTableCell component="th" scope="row">{val.name}</CustomTableCell>
                                                    <CustomTableCell>{val.organization.join(" , ")}</CustomTableCell>
                                                    <CustomTableCell>{val.requestedDate}</CustomTableCell>
                                                    <CustomTableCell>{val.status}</CustomTableCell>
                                                </CustomTableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <p>
                                Your blueprint list is empty.
                            </p>
                }
            </main>
        </div>
    );
}
