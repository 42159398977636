import React, {useEffect, useRef} from "react";
import { useForm } from 'react-hook-form';
import {
    CssBaseline,
    Paper,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField, RadioGroup, FormControlLabel,
} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {dashboardStyles, NextButton, BackButton, BlueRadio} from "../../styles";
import { Headline } from "../Deploy/headline";
import vmImage from "../../assert/images/vm.png";
import {Redirect} from "react-router-dom";
import {
    deployPostMethod,
    fetchMsResourceGroup,
    GRAPH_REQUESTS, isIE,
    msalApp,
    requiresInteraction
} from "../Authentication/auth-utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImageDetails from "../../assert/config/Images.json";
import hpcDetails from "../../assert/config/hpcConfig.json"
import { generate } from "shortid";
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import history from "../../history";
import Tooltip from '@material-ui/core/Tooltip';
import FormHelperText from '@material-ui/core/FormHelperText';

export default function Index() {
    const classes = dashboardStyles();
    const subscriptionId = localStorage.getItem("sid");

    const { register, setValue, handleSubmit, errors, unregister } = useForm();

    const [resourceGroup, setResourceGroup] = React.useState([]);
    const [selectedResourceGroup, setSelectedResourceGroup] = React.useState("");
    const [selectedLocation, setSelectedLocation] = React.useState("");
    const [selectedOS, setSelectedOS] = React.useState("");
    const [selectedMaxThroughput, setSelectedMaxThroughput] = React.useState("");
    const [selectedCacheSize, setSelectedCacheSize] = React.useState("");
    const [selectedEncryptionKeyType, setSelectedEncryptionKeyType] = React.useState("");
    const [selectedVMType, setSelectedVMType] = React.useState("");
    const [vmSize, setVmSize] = React.useState([]);
    const [imageType, setImageType] = React.useState("customImage");

    const [next, setNext] = React.useState(false);
    const [back, setBack] = React.useState(false);
    const [priceFlag, setPriceFlag] = React.useState(false);
    const [vmConfig, setVmConfig] = React.useState("password");
    const [osType, setOsType] = React.useState("");
    const [vmPrice, setVmPrice] = React.useState(0);
    const [vmPriceUnitTime, setVmPriceUnitTime] = React.useState("hour");
    const [vmCount, SetVmCount] = React.useState(1);
    const [vmTime, SetVmTime] = React.useState(720);

    const [deploymentNameStatus,setdeploymentNameStatus]=React.useState("");
    const [deploymentName,setdeploymentName]=React.useState("");
    const [correctdeploymentName,setcorrectdeploymentName]=React.useState(false);
    const [deploymentNameStatusForLength,setdeploymentNameStatusForLength]=React.useState(0);
    const [specialCharacter,setspecialCharacter]=React.useState(false);
    
    const [batchNameStatus,setbatchNameStatus]=React.useState(false);
    const [batchName,setbatchName]=React.useState("");
    const [batchNameStatusForError, setbatchNameStatusForError] = React.useState(false);
    const [selectedBlueprintDetails, setSelectedBlueprintDetails] = React.useState({});
    const [regionList, setRegionList] = React.useState([]);


    const [adminName, setadminName] = React.useState(false);
    const [poolName, setpoolName] = React.useState(false);
    const [specialCharacterforPoolName, setspecialCharacterforPoolName] = React.useState(false);
    const [password, setPassword] = React.useState(false);
    const [upperLetter, setupperLetter] = React.useState(-1);
    const [lowerLetter, setlowerLetter] = React.useState(-1);
    const [specialCharacterforPassword, setspecialCharacterforPassword] = React.useState(-1);
    const [numaric, setnumaric] = React.useState(-1);
    const [authenticationPassword, setauthenticationPassword] = React.useState("");
    const ref = useRef();
    const [open, setOpen] = React.useState(false);
    const [poolIdxToDelete, setPoolIdxToDelete] = React.useState(null)
    const [pageRedirectionFlag, setPageRedirectionFlag] = React.useState(false);
    const [isLocationNeededForMachineType, setIsLocationNeededForMachineType] = React.useState(false);
    const [vmSizes, setVmSizes] = React.useState(null)
    const [organization, setOrganization] = React.useState("");
  
    const [availableHpcCacheList, setAvailableHpcCacheList] = React.useState([]);
    const [selectedHpcCache, setSelectedHpcCache] = React.useState({});
    const [storageAccountType, setStorageAccountType] = React.useState("new")
    const [availableStorageAccountList, setAvailableStorageAccountList] = React.useState([]);
    const [selectedStorageAccount, setSelectedStorageAccount] = React.useState({});
    const [selectedDataPath, setSelectedDataPath] = React.useState("local_path")
    const [storageAccountName, setStorageAccountName] = React.useState("")


    const handleClickOpen = (idx) => {
        setPoolIdxToDelete(idx)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    // const poolSectionFields = {
    //     os: "", poolName: "", vmSize: "", customImage: "", imageType: "general",
    //     adminUsername: "", adminType: "ssh", adminPassword: "", adminSSHKey: ""
    // };

    const poolSectionFields = {
        os: "", vmSize: "", customImage: "", imageType: "customImage",
         adminType: "ssh",id:generate(),
    };

    const [poolSection, setPoolSection] = React.useState([]);
    const [valueOSTab, setValueOSTab] = React.useState([]);
    
    const handleChange = event => {
        unregister(event.target.value === 'ssh' ? 'password' : 'ssh_public_key');
        if(event.target.value === 'ssh'){
            setPassword(false);
            setupperLetter(-1);
            setlowerLetter(-1);
            setnumaric(-1);
            setspecialCharacterforPassword(-1);
        }
        else{
            passwordCheck(authenticationPassword);
        }
        
        setVmConfig(event.target.value);
    };
    
    const addPoolSection = event => {
        setPoolSection([...poolSection, { ...poolSectionFields }]);
        setValueOSTab([...valueOSTab,  0])
    };

    const handlePoolFieldChange = (value,idx,field)=>{
        const updatedPoolSection = [...poolSection];
        if(value==='password'||value==='ssh'){
            setPassword(false);
            setupperLetter(-1);
            setlowerLetter(-1);
            setnumaric(-1);
            setspecialCharacterforPassword(-1);
        }
        updatedPoolSection[idx][field] = value;
        setPoolSection(updatedPoolSection)
    }
    
    function getVMSize(location = "") {
        // Getting VM size in Azure
        msalApp.acquireTokenSilent(GRAPH_REQUESTS.ACTIVE_DIRECTORY).then( response => {
            fetchMsResourceGroup(
                "https://management.azure.com/subscriptions/"+ subscriptionId +"/providers/Microsoft.Compute/locations/"+ (location !== "" ? location : "eastus") +"/vmSizes?api-version=2019-07-01",
                response.accessToken
            ).then( response => {
                function compare(a, b) {
                    const bandA = a.name;
                    const bandB = b.name;

                    let comparison = 0;
                    if (bandA > bandB) {
                        comparison = 1;
                    } else if (bandA < bandB) {
                        comparison = -1;
                    }
                    return comparison;
                }

                let vmSizeData = [];
                let tempBlueprintDetails = JSON.parse(sessionStorage.getItem("selectedBlueprintDetails"));

                function fetchAllowedVMSize(vmDetails) {
                    return (tempBlueprintDetails['allowedVMSize']['series']).includes((vmDetails['name']).substr(0, 10)) || (tempBlueprintDetails['allowedVMSize']['series']).includes((vmDetails['name']).substr(0, 11)) || (tempBlueprintDetails['allowedVMSize']['series']).includes((vmDetails['name']).substr(0, 12)) && (tempBlueprintDetails['allowedVMSize']['min']['cpu'] <= vmDetails['numberOfCores']) && (tempBlueprintDetails['allowedVMSize']['min']['memory'] <= parseInt(vmDetails["memoryInMB"]) / 1024) && (tempBlueprintDetails['allowedVMSize']['max']['memory'] >= parseInt(vmDetails["memoryInMB"]) / 1024); 
                }

                function fetchCustomVMSize(vmDetails) {
                    return(tempBlueprintDetails.allowedVMSize.custom.some(c => c.vmSize === vmDetails.name && c.region === location))
                }

                if(tempBlueprintDetails.allowedVMSize.custom !== null){
                    setIsLocationNeededForMachineType(true)
                    for (let i = 0; i < (response.value).length; i++) {
                        let vm = response.value[i];
                        if (fetchCustomVMSize(vm)) vmSizeData.push(vm);
                    }
                }
                else{
                    for (let i = 0; i < (response.value).length; i++) {
                        let vm = response.value[i];
                        if (fetchAllowedVMSize(vm)) vmSizeData.push(vm);
                    }
                }
                setVmSize(vmSizeData.sort(compare));
            }).catch(error => {
                console.log(error)
            })
        }).catch(error => {
            console.log(error.name);
            if (requiresInteraction(error.errorCode)) {
                return isIE()
                    ? msalApp.acquireTokenRedirect(GRAPH_REQUESTS.ACTIVE_DIRECTORY)
                    : msalApp.acquireTokenPopup(GRAPH_REQUESTS.ACTIVE_DIRECTORY);
            } else {
                console.error('Non-interactive error:', error.errorCode)
            }
        });
    }

    function getStorageAccount(location = "") {
        // Getting Storage Account in Azure
        msalApp.acquireTokenSilent(GRAPH_REQUESTS.ACTIVE_DIRECTORY).then( response => {

            fetchMsResourceGroup(
                "https://management.azure.com/subscriptions/"+ subscriptionId +"/providers/Microsoft.Storage/storageAccounts?api-version=2019-06-01",
                response.accessToken
            ).then( response => {
                let storageAccount = [];
                if(response.value){
                    location = location !== "" ? location : "eastus";
                    for(let i=0; i<(response.value).length; i++){
                        if(response.value[i].location === location)
                            storageAccount.push(response.value[i]);
                    }
                }
                if(storageAccount.length === 0){
                    storageAccount.push({id : "", name : "No storage account in the region for this account"})
                }
              
            }).catch(error => {
                console.log(error)
            })
        }).catch(error => {
            console.log(error.name);
            if (requiresInteraction(error.errorCode)) {
                return isIE()
                    ? msalApp.acquireTokenRedirect(GRAPH_REQUESTS.ACTIVE_DIRECTORY)
                    : msalApp.acquireTokenPopup(GRAPH_REQUESTS.ACTIVE_DIRECTORY);
            } else {
                console.error('Non-interactive error:', error.errorCode)
            }
        });
    }

    const isEmpty = (obj) => {

        // null and undefined are "empty"
        if (obj == null) return true;

        // Assume if it has a length property with a non-zero value
        // that that property is correct.
        if (obj.length > 0) return false;
        if (obj.length === 0) return true;

        // If it isn't an object at this point
        // it is empty, but it can't be anything *but* empty
        // Is it empty?  Depends on your application.
        if (typeof obj !== "object") return true;

        // Otherwise, does it have any properties of its own?
        // Note that this doesn't handle
        // toString and valueOf enumeration bugs in IE < 9
        for (var key in obj) {
            if (hasOwnProperty.call(obj, key)) return false;
        }

        return true;
    };
    
    useEffect(() => {

        const account = msalApp.getAccount();
        
        msalApp.acquireTokenSilent(GRAPH_REQUESTS.LOGIN).then(response => {
            fetch("/api/azure/organization.get", {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ AccessToken: response.accessToken })
            }).then((res) => {
                return res.text();
            }).then((val) => {
                try {
                    let tempdata = JSON.parse(val);
                    setOrganization(tempdata[0].displayName);
                } catch (err) {
                    setOrganization("MaRaaS");
                }
            })
        }).catch(error => {
            console.log(error.name);
            if (requiresInteraction(error.errorCode)) {
                return isIE()
                    ? msalApp.acquireTokenRedirect(GRAPH_REQUESTS.ACTIVE_DIRECTORY)
                    : msalApp.acquireTokenPopup(GRAPH_REQUESTS.ACTIVE_DIRECTORY);
            } else {
                console.error('Non-interactive error:', error.errorCode)
            }
        });

        let tempBlueprintDetails = JSON.parse(sessionStorage.getItem("selectedBlueprintDetails"));

        if (!subscriptionId || isEmpty(tempBlueprintDetails)) {
            setPageRedirectionFlag(true);
            return
        }

        setSelectedBlueprintDetails(tempBlueprintDetails);


        tempBlueprintDetails["allowedLocations"].forEach((val) => {
            let tempRegionList = regionList;

            tempRegionList.push(
                {
                    "key": val,
                    "title": ImageDetails['locationDetails'][val]['title']
                }
            );
            setRegionList(tempRegionList);
        });

        getVMSize();
        getStorageAccount();

        msalApp.acquireTokenSilent(GRAPH_REQUESTS.ACTIVE_DIRECTORY).then(async(response) => {
          //  console.log("Acquired Token", response.accessToken);
            fetchMsResourceGroup(
                "https://management.azure.com/subscriptions/"+ subscriptionId +"/resourcegroups?api-version=2019-10-01",
                response.accessToken
            ).then( response => {
                setResourceGroup(response.value);
            }).catch(error => {
                console.log(error)
            })

            if(sessionStorage.getItem("resource") === "data-ingest" || localStorage.getItem("blueprintName") === "HPC Cache Client"){
                let hpcCacheList = await fetch(
                    "https://management.azure.com/subscriptions/" + subscriptionId + "/providers/Microsoft.StorageCache/caches?api-version=2020-03-01",
                    {
                        method:'get',
                        headers:{
                            Authorization: 'Bearer '+response.accessToken
                        }
                    }
                ).then(res => res.json())
                setAvailableHpcCacheList(hpcCacheList.value)

                if(sessionStorage.getItem("resource") === "data-ingest"){
                    let storageAccountList = await fetch(
                        "https://management.azure.com/subscriptions/" + subscriptionId + "/providers/Microsoft.Storage/storageAccounts?api-version=2019-06-01",
                        {
                            method:'get',
                            headers:{
                                Authorization: 'Bearer '+response.accessToken
                            }
                        }
                    ).then(res => res.json())
                    setAvailableStorageAccountList(storageAccountList.value)
                }
            }
        }).catch(error => {
            console.log(error.name);
            if (requiresInteraction(error.errorCode)) {
                return isIE()
                    ? msalApp.acquireTokenRedirect(GRAPH_REQUESTS.ACTIVE_DIRECTORY)
                    : msalApp.acquireTokenPopup(GRAPH_REQUESTS.ACTIVE_DIRECTORY);
            } else {
                console.error('Non-interactive error:', error.errorCode)
            }
        });

    }, [subscriptionId, regionList, setResourceGroup, setVmSize, setSelectedBlueprintDetails, setRegionList]);
        
    const onSubmit = async data => {
        let dat = {data,resource_group:selectedResourceGroup,location:selectedLocation,pool_section:poolSection};
       
        if (sessionStorage.getItem("resource") === "batch") {
            
            if (specialCharacter === false && specialCharacterforPoolName===false&& poolName===false&&batchName.length >= 3 && batchNameStatus === false && batchName.length !== 0 && batchNameStatusForError === false && batchName.length <= 6 && deploymentNameStatusForLength!==0 && deploymentNameStatusForLength<=15 && deploymentNameStatus===false && adminName===false && password===false && upperLetter!==0 && lowerLetter!==0 && specialCharacterforPassword!==0 && numaric!==0) {
                localStorage.setItem(subscriptionId, JSON.stringify(dat));
                setNext(true);
            }
        }
        else if(sessionStorage.getItem("resource") === "data-ingest"){
            let dataIngestData = {data, selectedHpcCache:selectedHpcCache, selectedStorageAccount:selectedStorageAccount, resource_group:selectedResourceGroup}
            localStorage.setItem(subscriptionId, JSON.stringify(dataIngestData));
            setNext(true);
        }
        else {
           if (specialCharacter === false && deploymentNameStatusForLength !== 0 && deploymentNameStatusForLength <= 15 && deploymentNameStatus === false && adminName === false && password === false && upperLetter !== 0 && lowerLetter !== 0 && specialCharacterforPassword !== 0 && numaric !== 0) {
                localStorage.setItem(subscriptionId, JSON.stringify(data));
                setNext(true);
            }
        }
    };

    const handleBack = () => {
        setBack(true);
        history.goBack();
    };

    if(next) {
        resourceGroup.forEach(resource => {
            if(resource["name"] === selectedResourceGroup){
                localStorage.setItem("slocation", resource["location"]);
            }
        });
        history.push('/deploy/summary');
        return <Redirect to={{pathname: '/deploy/summary', state: {from: ref.location}}}/>;
    }

    if(back) {
       // return <Redirect to={{pathname: '/deploy', state: {from: ref.location}}}/>;
    }

    async function deploymentNameCheck(typeValue, ResourseGroupName) {
     
        setcorrectdeploymentName(false);
        setdeploymentNameStatus(false);
        setspecialCharacter(false);
        setdeploymentName(typeValue);
        setdeploymentNameStatusForLength(typeValue.length);
        if (typeValue !== "") {
            
            if (ResourseGroupName.length > 0) {

                var regex = /^[A-Za-z0-9 ]+$/;

                if (regex.test(typeValue)) {
                    msalApp.acquireTokenSilent(GRAPH_REQUESTS.ACTIVE_DIRECTORY).then(async response => {
                        fetch("/api/azure/deploymentNameCheck?subscriptionId=" + subscriptionId + "&selectedResourceGroup=" + ResourseGroupName + "&AccessToken=" + response.accessToken + "&typeValue=" + typeValue, {
                            method: "get",
                            headers: {
                                "Content-Type": "application/json",
                            }
                        }).then((res) => {
                            return res.text();
                        }).then((val) => {
                            if (val) {
                                val = JSON.parse(val);
                                setcorrectdeploymentName(false);
                                setdeploymentNameStatus(true);
                            }
                            else {
                                setcorrectdeploymentName(true);
                                setdeploymentNameStatus(false);
                            }
                        })
                    }).catch(error => {
                        console.log(error.name);
                        if (requiresInteraction(error.errorCode)) {
                            return isIE()
                                ? msalApp.acquireTokenRedirect(GRAPH_REQUESTS.ACTIVE_DIRECTORY)
                                : msalApp.acquireTokenPopup(GRAPH_REQUESTS.ACTIVE_DIRECTORY);
                        } else {
                            console.error('Non-interactive error:', error.errorCode)
                        }
                    });
                }
                else {
                    setspecialCharacter(true);
                }
            }
        } else {
            setdeploymentNameStatus(false);
            setdeploymentNameStatusForLength(0);
        }


    }

    async function batchNameCheck(typeValue) {

        const regionkeys = Object.keys(ImageDetails['locationDetails']);
        setbatchNameStatus(false);
        setbatchNameStatusForError(false);
        setbatchName(typeValue);


        await msalApp.acquireTokenSilent(GRAPH_REQUESTS.ACTIVE_DIRECTORY).then(response => {

            regionkeys.map(async location => {

                await deployPostMethod(
                    "https://management.azure.com/subscriptions/" + subscriptionId + "/providers/Microsoft.Batch/locations/" + location + "/checkNameAvailability?api-version=2020-05-01",
                    response.accessToken,
                    {
                        name: typeValue,
                        type: "Microsoft.Batch/batchAccounts"
                    }
                ).then(res => {
                    if(typeValue.length<3){
                        setbatchName(typeValue);
                        setbatchNameStatus(false);
                        setbatchNameStatusForError(false);
                    }
                    else if(typeValue.length===0){
                        setbatchName(typeValue);
                        setbatchNameStatus(false);
                        setbatchNameStatusForError(false);
                    }
                    else {
                        if (res) {
                            setbatchName(typeValue);
                            if (res.nameAvailable === true) {

                            } else {

                                if (res.reason === "AlreadyExists") {
                                    setbatchNameStatus(true);
                                    setbatchNameStatusForError(false);

                                } else if (res.reason === "Invalid") {
                                    setbatchNameStatus(false);
                                    setbatchNameStatusForError(true);
                                }

                            }

                        }
                    }
                })


            });



        }).catch(error => {

            if (requiresInteraction(error.errorCode)) {
                return isIE()
                    ? msalApp.acquireTokenRedirect(GRAPH_REQUESTS.ACTIVE_DIRECTORY)
                    : msalApp.acquireTokenPopup(GRAPH_REQUESTS.ACTIVE_DIRECTORY);
            } else {
                console.error('Non-interactive error:', error.errorCode)
            }
        });
    }


    function adminNameCheck(typeValue) {
        setadminName(false);
        if(typeValue!=="") {
            if (typeValue.length >= 5 && typeValue.length <= 12) {
                setadminName(false);
            } else {
                setadminName(true);
            }
        }

    }
    function sshKeycheck(typeValue) {
        setauthenticationPassword(typeValue);
        setPassword(false);
        setupperLetter(-1);
        setlowerLetter(-1);
        setnumaric(-1);
        setspecialCharacterforPassword(-1);
    }

    function passwordCheck(typeValue) {
      
        setPassword(false);
        setupperLetter(0);
        setlowerLetter(0);
        setnumaric(0);
        setspecialCharacterforPassword(0);
        setauthenticationPassword(typeValue);
        if(typeValue!=="") {
          
            if (typeValue.length >= 12 && typeValue.length <= 75) {
                setPassword(false);
                for (let i = 0; i < typeValue.length; i++) {
                    if (typeValue[i] >= 'A' && typeValue[i] <= 'Z') {
                        setupperLetter(1)
                    } else if (typeValue[i] >= 'a' && typeValue[i] <= 'z') {
                        setlowerLetter(1)
                    } else if (typeValue[i] >= 0 && typeValue[i] <= 9) {
                        setnumaric(1)
                    } else if (typeValue[i] === '#' || typeValue[i] === '$' || typeValue[i] === '^' || typeValue[i] === '+' || typeValue[i] === '=' || typeValue[i] === '!' || typeValue[i] === '.' || typeValue[i] === '<' || typeValue[i] === '>' || typeValue[i] === '*' || typeValue[i] === '(' || typeValue[i] === ')' || typeValue[i] === '@' || typeValue[i] === '%' || typeValue[i] === '&') {
                        setspecialCharacterforPassword(1)
                    }
                }
            } else {
                setPassword(true);
            }
        }
        else{
            setupperLetter(-1);
            setlowerLetter(-1);
            setnumaric(-1);
            setspecialCharacterforPassword(-1);
        }


    }

    function poolNameCheck(typeValue) {
        setpoolName(false);
        setspecialCharacterforPoolName(false);
        if(typeValue!=="") {
            if (typeValue.length <= 6) {
                setpoolName(false);

                for(let i=0; i<typeValue.length; i++) {
                    if ((typeValue[i] >= 'A' && typeValue[i] <= 'Z') || (typeValue[i] >= 'a' && typeValue[i] <= 'z' ) || (typeValue[i] >= 0 && typeValue[i] <= 9)) {

                    }
                    else{
                        setspecialCharacterforPoolName(true);
                    }
                }

            } else {
                setpoolName(true);
            }
        }
    }


    return(
        pageRedirectionFlag ?
            <Redirect to="/" /> :
        <div>
            <CssBaseline/>
            <main className={classes.layout}>
                <Headline classes={classes} />
                <form onSubmit={handleSubmit(onSubmit)}>
                <Paper elevation={3} className={classes.paramsPaperBackground}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Grid item xs={1} sm={1}>
                            <div className={classes.paramsTitleIconPlaceholder}>
                                <img src={vmImage} alt="subscription" className={classes.paramsTitleIcon}/>
                            </div>
                        </Grid>

                        <Grid item xs={11} sm={11}>
                            <div className={classes.paramsTitle}>
                                {
                                    sessionStorage.getItem("resource") === "batch" ?
                                        "Batch Account" 
                                    : 
                                    sessionStorage.getItem("resource") === "simple" ?
                                        "Virtual Machines"
                                    :
                                    sessionStorage.getItem("resource") === "hpc-cache" ?
                                        "HPC Cache"
                                    :
                                    sessionStorage.getItem("resource") === "data-ingest" ?
                                        "Data Ingest"
                                    :""
                                }
                            </div>
                        </Grid>
                    </Grid>
                    {
                        sessionStorage.getItem("resource") === "simple" ?
                            <Grid
                                container
                                direction="row"
                                justify="space-evenly"
                                alignItems="stretch"
                            >
                                {/*Resource Group*/}
                                <Grid item xs={12} sm={6}>
                                    <input type="text" placeholder="resource_group_hidden" value={selectedResourceGroup} name="resource_group" ref={register({required: "Select Resource Group"})} readOnly hidden/>
                                    <FormControl
                                        variant="outlined"
                                        error={!!errors.resource_group}
                                        className={classes.paramsFormField}
                                    >
                                        <InputLabel id="resource_group">Resource Group</InputLabel>
                                        <Select
                                            value={selectedResourceGroup}
                                                onChange={event =>
                                                {
                                                    setSelectedResourceGroup(event.target.value);
                                                    setValue("resource_group", event.target.value);
                                                    errors.resource_group = false;
                                                    deploymentNameCheck(deploymentName, event.target.value);
                                                }}
                                            label="Resource Group"
                                        >
                                            {
                                                resourceGroup.map( resource =>
                                                    <MenuItem key={resource["name"]} value={resource["name"]}>{resource["name"]}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {/*Location*/}
                                <Grid item xs={12} sm={6}>
                                    <input type="text" placeholder="location_hidden" value={selectedLocation} name="location" ref={register({required: "Select Location"})} readOnly hidden/>
                                    <FormControl
                                        variant="outlined"
                                        error={!!errors.location}
                                        className={classes.paramsFormField}
                                    >
                                        <InputLabel id="location">Location</InputLabel>
                                        <Select
                                            value={selectedLocation}
                                            onChange={event => {
                                                setSelectedLocation(event.target.value);
                                                setValue("location", event.target.value);
                                                errors.location = false;
                                                getVMSize(event.target.value);
                                                getStorageAccount(event.target.value);
                                            }}
                                            label="Location"
                                        >
                                            {
                                                regionList.map(resource =>
                                                    <MenuItem key={resource["key"]} value={resource["key"]}>{resource["title"]}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {/*Deployment Name*/}
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="text"
                                        name="deployment_name"
                                        label="Deployment Name"
                                        variant="outlined"
                                        onChange={event => {
                                            deploymentNameCheck(event.target.value, selectedResourceGroup);
                                        }}
                                        error={!!errors.deployment_name || deploymentNameStatus || deploymentNameStatusForLength > 15 || specialCharacter || (deploymentName.length>0 && selectedResourceGroup.length === 0) }
                                        className={classes.paramsFormFieldForCheck}
                                        helperText={errors.deployment_name && errors.deployment_name.message}
                                        inputRef={register({

                                            required: "Deployment Name is Required",
                                       
                                        })}
                                    />

                                        {
                                            deploymentNameStatusForLength === 0 ? <p /> : selectedResourceGroup.length > 0 ? (specialCharacter ? <div style={{ color: "red", fontSize: 12, paddingLeft: "15px", paddingTop: "5px", background: "white", marginLeft: "15px", marginRight: "35px" }}> Deployment Name should contains letters and numbers</div> : deploymentNameStatusForLength > 15 ? (<div style={{ color: "red", fontSize: 12, paddingLeft: "15px", paddingTop: "5px", background: "white", marginLeft: "15px", marginRight: "35px" }}>Deployment Name should be between 1 to 15 characters</div>) : (deploymentNameStatus ? <div style={{ color: "red", fontSize: 12, paddingLeft: "15px", paddingTop: "5px", background: "white", marginLeft: "15px", marginRight: "35px" }}>The deployment name already in use!!</div> : (correctdeploymentName ? <div style={{ color: "green", fontSize: 12, paddingLeft: "15px", paddingTop: "5px", background: "white", marginLeft: "15px", marginRight: "35px" }} >{deploymentName} is available</div> : <div />))) : <div style={{ color: "red", fontSize: 12, paddingLeft: "15px", paddingTop: "5px", background: "white", marginLeft: "15px", marginRight: "35px" }}>Resource Group should not be empty</div>
                                    }
                                </Grid>
                                {
                                    sessionStorage.getItem("customImage") === "true" ?
                                        localStorage.getItem("blueprintName")!=="HPC Cache Client" ?
                                            <Grid item xs={12} sm={6} />
                                        :
                                            <Grid item xs={12} sm={6}> {/*HPC Cache Name*/}
                                                <input type="text" placeholder="hpc_cache_hidden" value={selectedHpcCache.name} name="hpc_cache" ref={register({required: "Select HPC cache"})} readOnly hidden/>
                                                <input type="text" placeholder="subnet_to_mount_hidden" value={selectedHpcCache.properties ? selectedHpcCache.properties.subnet : selectedHpcCache.name} name="subnet_to_mount" ref={register({required: "Select HPC cache"})} readOnly hidden/>                                               
                                                <FormControl
                                                    variant="outlined"
                                                    error={!!errors.hpc_cache}
                                                    className={classes.paramsFormField}
                                                >
                                                    <InputLabel id="hpc_cache">HPC Cache</InputLabel>
                                                    <Select
                                                        value={selectedHpcCache.name}
                                                            onChange={event =>
                                                            {
                                                                setSelectedHpcCache(event.target.value);
                                                                setValue("hpc_cache", event.target.value);
                                                                errors.hpc_cache = false;
                                                            }}
                                                        label="HPC Cache"
                                                    >
                                                        {
                                                            availableHpcCacheList.length ? 
                                                            availableHpcCacheList.map( cache =>
                                                                <MenuItem key={cache} value={cache}>{cache["name"] + ", " + cache["location"]}</MenuItem>
                                                            )
                                                            :
                                                            <MenuItem key={""} value={""}>{"Not Found"}</MenuItem>
                                                        }
                                                        {/* <MenuItem key={"cach1"} value={"cahce1"}>{"cache1"}</MenuItem> */}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                    :
                                    <span/>
                                }
                                
                                {/*Select vmType*/}
                                {
                                    sessionStorage.getItem("customImage") === "true" ?
                                        <Grid item xs={12} sm={12} key={"image_type_section"}>
                                            {(localStorage.getItem("blueprintName")!=="HPC Cache Client" && localStorage.getItem("blueprintName")!=="Quantitative Analysis for Mass Spectrometry Data" && localStorage.getItem("blueprintName")!=="Visualize Reservoir Simulation") ?
                                                <Grid item xs={12} sm={12} key={"image_type"}>
                                                    <div className={classes.ImageTypeSelection}>
                                                        Image Type :
                                                        <span className={classes.ImageTypeSelection}>
                                                            <RadioGroup value={imageType} onChange={
                                                                event =>
                                                                {
                                                                    setImageType(event.target.value);
                                                                    // handlePoolFieldChange(event.target.value, idx, "imageType")
                                                                }
                                                            }
                                                            >
                                                                <FormControlLabel key="general" value="general" control={<BlueRadio />} label="General" />
                                                                <FormControlLabel key="customImage" value="customImage" control={<BlueRadio />} label="Custom Image" />
                                                            </RadioGroup>
                                                        </span>
                                                    </div>
                                                </Grid>
                                                :""}
                                            

                                            {
                                                imageType === "general" ?
                                                    <Grid item xs={12} sm={6}  key={"pool_section_os"}>
                                                        <input type="text" placeholder={"os_hidden"} name={"os"} ref={register({required: "Machine Type"})} readOnly hidden/>
                                                        <FormControl
                                                            variant="outlined"
                                                            error={!!errors['os']}
                                                            className={classes.paramsFormField}
                                                        >
                                                            <InputLabel id={'os'}>Operating System</InputLabel>
                                                            <Select
                                                                onChange={event => {
                                                                    errors['os'] = false;
                                                                        setValue("os", event.target.value);
                                                                        setVmConfig((event.target.value).includes("win") ? "password" :"ssh");
                                                                        setOsType((event.target.value).includes("win") ? "windows" :"linux");
                                                                }}
                                                                label="Operating System"
                                                                // value={imageType}
                                                            >
                                                                    {
                                                                        selectedBlueprintDetails["allowedOS"] ?
                                                                            selectedBlueprintDetails["allowedOS"].map(
                                                                                osId => <MenuItem key={osId} value={osId}>{ImageDetails["osDetails"][osId]["name"] + " | " + ImageDetails["osDetails"][osId]["version"] }</MenuItem>
                                                                            )
                                                                            :
                                                                            <MenuItem key={""} value={""}>{"Not Found"}</MenuItem>
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    :
                                                    <Grid item xs={12} sm={6}  key={"custom_image"}>
                                                        <input type="text" placeholder={"custom_image_hidden"} name={"custom_image"} ref={register({required: "Custom Image"})} readOnly hidden/>
                                                        <FormControl
                                                            variant="outlined"
                                                            error={!!errors['custom_image']}
                                                            className={classes.paramsFormField}
                                                        >
                                                            <InputLabel id={'custom_image'}>Image</InputLabel>
                                                            <Select
                                                                onChange={event => {
                                                                    errors['custom_image'] = false;
                                                                    setValue("custom_image", event.target.value);
                                                                    setVmConfig((event.target.value).includes("win")? "password" :"ssh");
                                                                    setOsType((event.target.value).includes("win") ? "windows" :"linux");
                                                                }}
                                                                label="Image"
                                                                // value={poolSection[idx]["customImage"]}
                                                            >
                                                                    {
                                                                        selectedBlueprintDetails["allowedCustomImages"] ?
                                                                            selectedBlueprintDetails["allowedCustomImages"].map(
                                                                                customImageId => <MenuItem key={customImageId} value={customImageId}>{ImageDetails["customImageDetails"][customImageId]["name"] + " | " + ImageDetails["customImageDetails"][customImageId]["os"] + " | " + ImageDetails["customImageDetails"][customImageId]["version"]}</MenuItem>
                                                                            )
                                                                            :
                                                                            <MenuItem key={""} value={""}>{"Not Found"}</MenuItem>
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                            }
                                        </Grid>
                                        :
                                        <Grid item xs={12} sm={6} name={"os_section"}>
                                            <input type="text" placeholder="os_hidden" value={selectedOS} name="os" ref={register({required: "Select OS"})} readOnly hidden/>
                                            <FormControl
                                                variant="outlined"
                                                error={!!errors.os}
                                                className={classes.paramsFormField}
                                            >
                                                <InputLabel id="os">Operating System</InputLabel>
                                                <Select
                                                    value={selectedOS}
                                                        onChange={event => {
                                                        setSelectedOS(event.target.value);
                                                        setValue("os", event.target.value);
                                                        errors.os = false;
                                                        setVmConfig((event.target.value).includes("win")? "password" :"ssh");
                                                        setOsType((event.target.value).includes("win") ? "windows" :"linux");
                                                    }}
                                                    label="Operating System"
                                                    >
                                                        {
                                                            selectedBlueprintDetails["allowedOS"] ?
                                                                selectedBlueprintDetails["allowedOS"].map(
                                                                    osId => <MenuItem key={osId} value={osId}>{ImageDetails["osDetails"][osId]["name"] + " | " + ImageDetails["osDetails"][osId]["version"]}</MenuItem>
                                                                )
                                                                :
                                                                <MenuItem key={""} value={""}>{"Not Found"}</MenuItem>
                                                        }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                }

                                {/*VM type*/}
                                <Grid item xs={12} sm={6}>
                                   
                                    <input type="text" placeholder="vm_type_hidden" value={selectedVMType} name="vm_type" ref={register({required: "Machine Type"})} readOnly hidden/>
                                    <FormControl
                                        variant="outlined"
                                        error={!!errors.vm_type}
                                        className={classes.paramsFormField}
                                    >
                                        <InputLabel id="vm_type">Machine Type</InputLabel>
                                        <Select
                                            disabled={selectedLocation === "" && isLocationNeededForMachineType ? true : false}
                                            value={selectedVMType}
                                            onChange={event => {
                                                setSelectedVMType(event.target.value);
                                                setValue("vm_type", event.target.value);             
                                                errors.vm_type = false;
                                                setPriceFlag(true);

                                                  /*  if (ImageDetails['locationDetails'][chckLocation]['meterRegion'] == "northeurope")
                                                    setSelectedLocation("EU North");*/

                                                let chckLocation = selectedLocation ? selectedLocation : "eastus";
                                                
                                                let bodyParams = {
                                                    "MeterName": ((event.target.value).replace("Standard_", "").replace(/_/g, " ")),
                                                    "MeterRegion": ImageDetails['locationDetails'][chckLocation]['meterRegion'] === "North Europe" ? "EU North" : "Canada Central" ? "CA Central"  :ImageDetails['locationDetails'][chckLocation]['meterRegion'],
                                                    "Os": selectedOS? selectedOS.includes("windows")? "Windows" : "Linux" : "Linux",
                                                    "Sku": "Standard"
                                                };
                                                fetch("/api/azure/meter", {
                                                    method: "post",
                                                    headers: {
                                                        "Content-Type": "application/json",
                                                    },
                                                    body: JSON.stringify(bodyParams),
                                                }).then(function (response) {
                                                    try {
                                                        return response.text();
                                                    } catch (ex) {
                                                        console.log(ex);
                                                    }

                                                }).then(function (val) {
                                                    setPriceFlag(false);
                                                    if (val) {
                                                        val = JSON.parse(val);
                                                        // console.log(val);
                                                        if (val["meterRates"] == null) {
                                                            setVmPrice(0);
                                                            setVmPriceUnitTime("hour");
                                                            
                                                            // console.log("ACTUAL COST + 15% = " + ((0 * vmCount * vmTime) + (((0 * vmCount * vmTime) / 100)*15) ).toFixed(2) + " ----- ACTUAL COST = " + (0 * vmCount * vmTime).toFixed(2));
                                                        } else {
                                                            let tempPrice = JSON.parse(val["meterRates"]);
                                                            setVmPrice(tempPrice["0"]);
                                                            setVmPriceUnitTime("(" + val["unit"] + ")");
                                                            // console.log("ACTUAL COST + 15% = " + ((tempPrice["0"] * vmCount * vmTime) + (((tempPrice["0"] * vmCount * vmTime) / 100)*15) ).toFixed(2) + " ----- ACTUAL COST = " + (tempPrice["0"] * vmCount * vmTime).toFixed(2));
                                                        }
                                                        
                                                    } else {
                                                        setVmPrice(0);
                                                        setVmPriceUnitTime("hour");
                                                        // console.log("ACTUAL COST + 15% = " + ((0 * vmCount * vmTime) + (((0 * vmCount * vmTime) / 100)*15) ).toFixed(2) + " ----- ACTUAL COST = " + (0 * vmCount * vmTime).toFixed(2));

                                                    }
                                                });
                                            }}
                                            label="Machine Type"
                                        >
                                            {
                                                vmSize.map( resource =>
                                                    <MenuItem key={resource["name"]} value={
                                                        resource["name"]}>{
                                                        (resource["name"].replace("Standard_", "").replace(/_/g," "))+ " (" +
                                                        resource["numberOfCores"].toString() + " vCPU(s), " +
                                                        (parseInt(resource["memoryInMB"])/1024).toFixed() + " GB RAM, " +
                                                        (parseInt(resource["resourceDiskSizeInMB"])/1024).toFixed() + " GB Temporary Storage)"
                                                    }
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                        {selectedLocation === "" && isLocationNeededForMachineType ? <FormHelperText>* Select Location</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} />

                                {/*VM Calculator - start */}
                                <Grid item xs={12} sm={1}>
                                    <TextField
                                        type="lable"
                                        name="vmCount"
                                        label="VM Count"
                                        variant="outlined"
                                        error={!!errors.vmCount}
                                        value={vmCount}
                                        min="0"
                                        className={classes.paramsFormField}
                                        helperText={errors.vmCount && errors.vmCount.message}
                                        inputRef={register({
                                            required: "VM Count is Required",
                                            pattern: {
                                                value: /^[0-9]*$/,
                                                message: "VM Count should contains numbers"
                                            },
                                            maxLength: { value: 1000, message: "VM Count is over limit" }
                                        })}
                                        onChange={event => {
                                            //if (event.target.value && event.target.value >= 0)
                                            //    SetVmCount(parseInt(event.target.value));
                                            //else
                                            //    SetVmCount(0);
                                        }}
                                    />
                                </Grid>
                                <Grid>
                                    <div className={classes.priceLabel}>X</div>
                                </Grid>
                                <Grid item xs={12} sm={1}>
                                    <TextField
                                        type="number"
                                        name="vmTime"
                                        label={vmPriceUnitTime.toLowerCase().includes("hour") ? "Hour(s)" : (vmPriceUnitTime.includes("day") ? "Day(s)" : vmPriceUnitTime)}
                                        variant="outlined"
                                        error={!!errors.vmTime}
                                        value={vmTime}
                                        className={classes.paramsFormField}
                                        helperText={errors.vmTime && errors.vmTime.message}
                                        inputRef={register({
                                            required: "VM Time is Required",
                                            pattern: {
                                                value: /^[0-9]*$/,
                                                message: "VM Time should contains numbers"
                                            },
                                            maxLength: { value: 100000000, message: "VM Time is over limit" }
                                        })}
                                        min="0"
                                        onChange={event => {
                                            if (event.target.value && event.target.value >= 0) {
                                                SetVmTime(parseInt(event.target.value));
                                                // console.log("ACTUAL COST + 15% = " + ((vmPrice * vmCount * parseInt(event.target.value)) + (((vmPrice * vmCount * parseInt(event.target.value)) / 100)*15) ).toFixed(2) + " ----- ACTUAL COST = " + (vmPrice * vmCount * parseInt(event.target.value)).toFixed(2));
                                            }
                                            else {
                                                SetVmTime(0);
                                                // console.log("ACTUAL COST + 15% = " + ((vmPrice * vmCount * 0) + (((vmPrice * vmCount * 0) / 10)*15) ).toFixed(2) + " ----- ACTUAL COST = " + (vmPrice * vmCount * 0).toFixed(2));
                                            }
                                                
                                        }}
                                    />
                                </Grid>
                                <Grid>
                                    <div className={classes.priceLabel}>X</div>
                                </Grid>
                                <Grid item xs={12} sm={1}>
                                    <TextField
                                        type="lable"
                                        name="vmPrice"
                                        label={"Price/ " + (vmPriceUnitTime.toLowerCase().includes("hour") ? "hr" : (vmPriceUnitTime.includes("day") ? "dy" : vmPriceUnitTime))}
                                        variant="outlined"
                                        error={!!errors.vmPrice}
                                        value={vmPrice}
                                        className={classes.paramsFormField}
                                        helperText={errors.vmPrice && errors.vmPrice.message}
                                    />
                                </Grid>
                                <Grid>
                                    <div className={classes.priceLabel}>=</div>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    {
                                        priceFlag ?
                                            <div className={classes.loadingPrice}>
                                                <CircularProgress />
                                            </div>
                                            :
                                                    <div>
                                                        <div className={classes.priceLabel}>US${organization === "Uniades" ? ((vmPrice * vmCount * vmTime) + (((vmPrice * vmCount * vmTime) / 100))*15).toFixed(2) : (vmPrice * vmCount * vmTime).toFixed(2)}
                                                    &nbsp;<span className={classes.priceLabelEstimate}>(Est for STD Tier)</span>
                                                </div>
                                            </div>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={5} />
                                {/*VM Calculator - End */}

                                {/*Admin Config - Start */}
                                <Grid item xs={12} sm={12}>
                                    <div className={classes.paramsAdministratorTitle}>
                                        Administrator Account
                                    </div>
                                </Grid>
                                {/* {
                                    localStorage.getItem("blueprintName")!=="Quantitative Analysis for Mass Spectrometry Data" ? */}
                                    <Grid item xs={12} sm={12}>
                                        <div className={classes.paramsAdministratorRadioButtonHolder}>
                                            Authentication Type :
                                            <span className={classes.paramsAdministratorRadioButtons}>
                                                <RadioGroup value={vmConfig} onChange={handleChange}>
                                                    <FormControlLabel disabled={osType==="windows"?true:false} key="ssh" value="ssh" control={<BlueRadio />} label="SSH Public Key"  />
                                                    <FormControlLabel key="password" value="password"  control={<BlueRadio />} label="Password"  />
                                                </RadioGroup>
                                            </span>
                                        </div>
                                    </Grid>
                                    {/* :"" */}
                                {/* } */}

                                <Grid item xs={6} sm={6}>
                                    <TextField
                                        name="admin_name"
                                        label="Admin Name"
                                        variant="outlined"
                                        error={!!errors.admin_name || adminName}
                                        onChange={event => {
                                            adminNameCheck(event.target.value);
                                        }}
                                        className={classes.paramsFormFieldForCheck}
                                        helperText={errors.admin_name && errors.admin_name.message}
                                        inputRef={register({required: "Admin Name is Required",
                                          })}
                                    />
                                    {
                                        adminName?<div style={{color:"red",fontSize:12,paddingLeft:"15px",paddingTop:"5px",background:"white",marginLeft:"15px",marginRight:"35px"}}>Username should be between 5 to 12 characters</div>:<div/>
                                    }
                                </Grid>
                                {
                                    vmConfig === "ssh" ?
                                        <Grid item xs={6} sm={6}>
                                            <TextField
                                                name="ssh_public_key"
                                                label="SSH Public Key"
                                                variant="outlined"
                                               onChange={event=>{
                                                   sshKeycheck(event.target.value);
                                               }}
                                                error={!!errors.ssh_public_key}
                                                className={classes.paramsFormFieldForAutentication}
                                                helperText={errors.ssh_public_key && errors.ssh_public_key.message}
                                                inputRef={register({
                                                    required: "SSH Public Key is Required"
                                                })}
                                            />
                                        </Grid>
                                        :
                                        <Grid item xs={6} sm={6}>
                                            <TextField
                                                inputProps={{
                                                    form: {
                                                      autocomplete: 'off',
                                                    },
                                                  }}
                                                name="password"
                                                label="Password"
                                                variant="outlined"
                                                type="password"
                                                error={!!errors.password || password || upperLetter===0||lowerLetter===0||specialCharacterforPassword===0||numaric===0}
                                                onChange={event => {
                                                    passwordCheck(event.target.value);
                                                }}
                                                className={ (password || upperLetter===0||lowerLetter===0||specialCharacterforPassword===0||numaric===0)?classes.paramsFormFieldForCheck:classes.paramsFormFieldForAutentication}
                                                helperText={errors.password && errors.password.message}
                                                inputRef={register({
                                                    required: "Password is Required",
                                                     })}

                                            />
                                            {
                                                password?<div  style={{color:"red",fontSize:12,paddingLeft:"15px",paddingTop:"5px",background:"white",marginBottom:"9px",marginLeft:"15px",marginRight:"35px"}}>Password should be atleast 12 characters long, contain at least one uppercase, one lowercase, one numeric and one special character </div>:((upperLetter===0||lowerLetter===0||specialCharacterforPassword===0||numaric===0)?<div  style={{color:"red",fontSize:12,paddingLeft:"15px",paddingTop:"5px",background:"white",marginLeft:"15px",marginRight:"35px",marginBottom:"9px"}}>Password should be atleast 12 characters long, contain at least one uppercase, one lowercase, one numeric and one special character.</div>:<div/>)
                                            }
                                        </Grid>
                                }
                                {/*Admin Config - End */}
                            </Grid>
                        :
                        sessionStorage.getItem("resource") === "batch" ?
                            <Grid
                                container
                                direction="row"
                                justify="space-evenly"
                                alignItems="stretch"
                            >
                                {/*Resource Group*/}
                                <Grid item xs={12} sm={6}>
                                    <input type="text" placeholder="resource_group_hidden" value={selectedResourceGroup} name="resource_group" ref={register({required: "Select Resource Group"})} readOnly hidden/>
                                    <FormControl
                                        variant="outlined"
                                        error={!!errors.resource_group}
                                        className={classes.paramsFormField}
                                    >
                                        <InputLabel id="resource_group">Resource Group</InputLabel>
                                        <Select
                                            value={selectedResourceGroup}
                                                onChange={event =>
                                                {
                                                    setSelectedResourceGroup(event.target.value);
                                                    setValue("resource_group", event.target.value);
                                                    errors.resource_group = false;                  
                                                    deploymentNameCheck(deploymentName, "event.target.value");
                                                }}
                                            label="Resource Group"
                                        >
                                            {
                                                resourceGroup.map( resource =>
                                                    <MenuItem key={resource["name"]} value={resource["name"]}>{resource["name"]}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {/*Location*/}
                                <Grid item xs={12} sm={6}>
                                    <input type="text" placeholder="location_hidden" value={selectedLocation} name="location" ref={register({required: "Select Location"})} readOnly hidden/>
                                    <FormControl
                                        variant="outlined"
                                        error={!!errors.location}
                                        className={classes.paramsFormField}
                                    >
                                        <InputLabel id="location">Location</InputLabel>
                                        <Select
                                            value={selectedLocation}
                                            onChange={event => {
                                                setSelectedLocation(event.target.value);
                                                setValue("location", event.target.value);
                                                errors.location = false;
                                                getVMSize(event.target.value);
                                                getStorageAccount(event.target.value);
                                            }}
                                            label="Location"
                                        >
                                            {
                                                regionList.map(resource =>
                                                    <MenuItem key={resource["key"]} value={resource["key"]}>{resource["title"]}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {/*Deployment Name*/}
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="text"
                                        name="deployment_name"
                                        label="Deployment Name"
                                        variant="outlined"
                                            error={!!errors.deployment_name || deploymentNameStatus || deploymentNameStatusForLength > 15 || specialCharacter || (deploymentName.length > 0 && selectedResourceGroup.length === 0) }
                                            onChange={event => {
                                                deploymentNameCheck(event.target.value, selectedResourceGroup);
                                        }}
                                        className={classes.paramsFormFieldForCheck}
                                        helperText={errors.deployment_name && errors.deployment_name.message}
                                        inputRef={register({
                                             required: "Deployment Name is Required",
                                                })}
                                    />
                                        {
                                            deploymentNameStatusForLength === 0 ? <p /> : selectedResourceGroup.length > 0 ? (specialCharacter ? <div style={{ color: "red", fontSize: 12, paddingLeft: "15px", paddingTop: "5px", background: "white", marginLeft: "15px", marginRight: "35px" }}> Deployment Name should contains letters and numbers</div> : deploymentNameStatusForLength > 15 ? (<div style={{ color: "red", fontSize: 12, paddingLeft: "15px", paddingTop: "5px", background: "white", marginLeft: "15px", marginRight: "35px" }}>Deployment Name should be between 1 to 15 characters</div>) : (deploymentNameStatus ? <div style={{ color: "red", fontSize: 12, paddingLeft: "15px", paddingTop: "5px", background: "white", marginLeft: "15px", marginRight: "35px" }}>The deployment name already in use!!</div> : (correctdeploymentName ? <div style={{ color: "green", fontSize: 12, paddingLeft: "15px", paddingTop: "5px", background: "white", marginLeft: "15px", marginRight: "35px" }} >{deploymentName} is available</div> : <div />))) : <div style={{ color: "red", fontSize: 12, paddingLeft: "15px", paddingTop: "5px", background: "white", marginLeft: "15px", marginRight: "35px" }}>Resourse Group should not Empty</div>
                                        }
                                </Grid>

                                {/*Batch Name*/}
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="text"
                                        name="batch_name"
                                        label="Batch Name"
                                        variant="outlined"
                                        onChange={event => {
                                            batchNameCheck(event.target.value);
                                        }}
                                        error={!!errors.batch_name||batchName.length>6||batchNameStatus||batchNameStatusForError||(batchName.length<3&&batchName.length>0)}
                                        className={classes.paramsFormFieldForCheck}
                                        helperText={errors.batch_name && errors.batch_name.message}
                                        inputRef={register({
                                             required: "Batch Name is Required",
                                          })}
                                    />
                                    {
                                        batchName.length===0?<div/>:
                                            batchName.length<3&& batchName.length>0?
                                                <div style={{color:"red",fontSize:12,paddingLeft:"15px",paddingTop:"5px",background:"white",marginLeft:"15px",marginRight:"35px"}}>The batch name require minimum 3 character</div> : <div/>
                                    }


                                    {
                                        batchNameStatus &&batchName.length!==0 ? <div style={{color:"red",fontSize:12,paddingLeft:"15px",paddingTop:"5px",background:"white",marginLeft:"15px",marginRight:"35px"}}>The batch name already in use</div>:<div/>
                                    }
                                    {
                                        batchNameStatusForError?<div style={{color:"red",fontSize:12,paddingLeft:"15px",paddingTop:"5px",background:"white",marginLeft:"15px",marginRight:"35px"}}>Batch Name should contains letters and numbers</div>:( batchName.length>6? <div style={{color:"red",fontSize:12,paddingLeft:"15px",paddingTop:"5px",background:"white",marginLeft:"15px",marginRight:"35px"}}>Batch Name should be between 3 to 6 characters</div> : <div/>)
                                    }
                                </Grid>
                                {/*Storage Account*/}
                                <Grid item xs={12} sm={6}>
                                    {/* <input type="text" placeholder="storage_account_hidden" value={selectedStorage} name="storage_account" readOnly hidden/>
                                    <FormControl
                                        variant="outlined"
                                        error={!!errors.storage_account}
                                        className={classes.paramsFormField}
                                    >
                                        <InputLabel id="storage">Storage Account <span className={classes.optional}>(optional)</span></InputLabel>
                                        <Select
                                            value={selectedStorage}
                                            onChange={event => {setSelectedStorage(event.target.value); setValue("storage_account", event.target.value); errors.storage_account = false}}
                                            label="Storage Account (Optional)"
                                        >
                                            {
                                                storageAccount.map( resource =>
                                                    <MenuItem key={resource["id"]} value={resource["id"]}>{resource["name"]}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl> */}
                                </Grid>
                                <Grid item xs={12} sm={6} />

                                {/*Pool Section - Start*/}
                                <Grid item xs={12} sm={12}>
                                    <div className={classes.paramsAdministratorTitle}>
                                        Pool Section
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <NextButton className={classes.addPoolButton} onClick={addPoolSection}>
                                        <AddIcon className={classes.addPoolButtonAddIcon}/>
                                        Add a Pool to the account
                                    </NextButton>
                                </Grid>
                                <Grid item xs={12} sm={12}><p/></Grid>
                                {
                                    poolSection.map( (pool, idx) =>
                                        <Paper className={classes.poolSectionPaper} variant="outlined" style={{width: "100%"}} elevation={3} key={"pool_section_"+idx}>
                                           <Grid container>
                                                <Grid item xs={10} >
                                                    <span className={classes.poolSectionTitle}>
                                                            { "Pool " + (idx +1).toString()}
                                                    </span>
                                                </Grid>
                                                <Grid item xs={2} style={{ textAlign: 'right' }} >
                                                    <Tooltip title="Delete">
                                                        <Button
                                                            style={{ fontSize: 25, marginLeft: 'auto', }}
                                                            onClick={() => handleClickOpen(idx)}
                                                        >
                                                            <ClearIcon />
                                                        </Button>
                                                    </Tooltip>
                                                <Dialog
                                                    open={open}
                                                    onClose={handleClose}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogTitle id="alert-dialog-title">{"Pool Delete?"}</DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="alert-dialog-description">
                                                           Do you want to delete this pool?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={handleClose} color="primary">
                                                            Cancel
                                                        </Button>
                                                        <Button color="primary" 
                                                            autoFocus 
                                                            onClick={()=>{
                                                                const PoolSection = [...poolSection];
                                                                PoolSection.splice(poolIdxToDelete,1)
                                                                setPoolSection(PoolSection);
                                                                setOpen(false);
                                                                setPoolIdxToDelete(null);
                                                            }} 
                                                        >
                                                            Ok
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                               </Grid>
                                            </Grid>
                                          
                                            <Grid container
                                                  direction="row"
                                                  justify="space-between"
                                                  alignItems="stretch">

                                                {/*Pool Name*/}
                                                <Grid item xs={12} sm={6} key={"pool_section_pool_name_"+idx}>
                                                    <TextField
                                                        type="text"
                                                        name={"pool_name_" + idx}
                                                        label="Pool Name"
                                                        variant="outlined"
                                                        error={!!errors['pool_name_'+ idx]||poolName||specialCharacterforPoolName}
                                                        onChange={event => {
                                                            poolNameCheck(event.target.value);
                                                        }}
                                                        className={classes.paramsFormFieldForCheck}
                                                        helperText={errors['pool_name_' + idx] && errors['pool_name_' + idx].message}
                                                        inputRef={register({
                                                             required: "Pool Name is Required",
                                                          })}
                                                    />
                                                    {
                                                        poolName?<div style={{color:"red",fontSize:12,paddingLeft:"15px",paddingTop:"5px",background:"white",marginLeft:"15px",marginRight:"35px"}}>Pool Name should be between 1 to 6 characters</div>:specialCharacterforPoolName?<div style={{color:"red",fontSize:12,paddingLeft:"15px",paddingTop:"5px",background:"white",marginLeft:"15px",marginRight:"35px"}}>Pool Name should contains letters and numbers</div>:<div/>
                                                    }
                                                    
                                                </Grid>

                                                {/*VM type*/}
                                                <Grid item xs={12} sm={6}   key={"pool_section_vm_"+idx}>
                                                    <input type="text" placeholder="vm_type_hidden" value={poolSection[idx]["vmSize"]} name="vm_type" ref={register({ required: "Vm Type" })} readOnly hidden/>
                                                    <FormControl
                                                        variant="outlined"
                                                        error={!!errors.vm_type}
                                                        className={classes.paramsFormField}
                                                    >
                                                        <InputLabel id="vm_type">Machine Type</InputLabel>
                                                        <Select
                                                            disabled={selectedLocation === "" && isLocationNeededForMachineType ? true : false}
                                                            value={poolSection[idx]["vmSize"]}
                                                            onChange={event => {
                                                                errors.vm_type = false;
                                                                handlePoolFieldChange(event.target.value,idx,"vmSize")
                                                                setPriceFlag(true);
                                                                let chckLocation = selectedLocation ? selectedLocation : "eastus";
                                                                let bodyParams = {
                                                                    "MeterName": ((event.target.value).replace("Standard_", "").replace(/_/g, " ")),
                                                                    "MeterRegion": chckLocation ? ImageDetails['locationDetails'][chckLocation]['meterRegion'] : "",
                                                                    "Os":  "Linux",
                                                                    "Sku": "Standard"
                                                                };
                                                                fetch("/api/azure/meter", {
                                                                    method: "post",
                                                                    headers: {
                                                                        "Content-Type": "application/json",
                                                                    },
                                                                    body: JSON.stringify(bodyParams),
                                                                }).then(function (response) {
                                                                    try {
                                                                        return response.text();
                                                                    } catch (ex) {
                                                                        console.log(ex);
                                                                    }

                                                                }).then(function (val) {
                                                                    setPriceFlag(false);
                                                                    if (val) {
                                                                        val = JSON.parse(val);
                                                                        if (val["meterRates"] == null) {
                                                                            setVmPrice(0);
                                                                            setVmPriceUnitTime("hour");
                                                                        } else {
                                                                            let tempPrice = JSON.parse(val["meterRates"]);
                                                                            setVmPrice(tempPrice["0"]);
                                                                            setVmPriceUnitTime("(" + val["unit"] + ")");
                                                                        }

                                                                    } else {
                                                                        setVmPrice(0);
                                                                        setVmPriceUnitTime("hour");
                                                                    }
                                                                });
                                                            }}
                                                            label="Machine Type"
                                                        >
                                                            {
                                                                vmSize.map( resource =>
                                                                    <MenuItem key={resource["name"]} value={
                                                                        resource["name"]}>{
                                                                        (resource["name"].replace("Standard_", "").replace(/_/g," "))+ " (" +
                                                                        resource["numberOfCores"].toString() + " vCPU(s), " +
                                                                        (parseInt(resource["memoryInMB"])/1024).toFixed() + " GB RAM, " +
                                                                        (parseInt(resource["resourceDiskSizeInMB"])/1024).toFixed() + " GB Temporary Storage)"
                                                                    }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                        {selectedLocation === "" && isLocationNeededForMachineType ? <FormHelperText>* Select Location</FormHelperText> : ""}

                                                    </FormControl>
                                                </Grid>
    
                                                {/*Select vmType*/}
                                                <Grid item xs={12} sm={12} key={"pool_section_image_type_" + idx}>
                                                    <div className={classes.paramsAdministratorRadioButtonHolder}>
                                                        Image Type :
                                                        <span className={classes.paramsAdministratorRadioButtons}>
                                                            <RadioGroup value={poolSection[idx]["imageType"]} onChange={ event => {handlePoolFieldChange(event.target
                                                                .value, idx, "imageType")}}>
                                                                <FormControlLabel key="general" value="general" control={<BlueRadio />} label="General" />
                                                                <FormControlLabel key="customImage" value="customImage" control={<BlueRadio />} label="Custom Image" />
                                                            </RadioGroup>
                                                        </span>
                                                    </div>
                                                </Grid>
                                                
                                                {/*Image Type*/}
                                                {
                                                    poolSection[idx]["imageType"] === "general" ?
                                                        <Grid item xs={12} sm={6}  key={"pool_section_os_"+idx}>
                                                            <input type="text" placeholder={"os" + idx + "_hidden"} value={poolSection[idx]["os"]} name={"os_"+idx} ref={register({required: "Machine Type"})} readOnly hidden/>
                                                            <FormControl
                                                                variant="outlined"
                                                                error={!!errors['os_'+ idx]}
                                                                className={classes.paramsFormField}
                                                            >
                                                                <InputLabel id={'os_'+ idx}>Operating System</InputLabel>
                                                                <Select
                                                                    onChange={event => {
                                                                        errors['os_'+ idx] = false;
                                                                        handlePoolFieldChange(event.target.value,idx,"os")
                                                                    }}
                                                                    label="Operating System"
                                                                    value={poolSection[idx]["os"]}
                                                                >
                                                                    {
                                                                        ImageDetails["osType"].map( osId =>
                                                                            <MenuItem key={osId} value={osId}>{ImageDetails["osDetails"][osId]["name"] + " | " + ImageDetails["osDetails"][osId]["version"] }</MenuItem>
                                                                        )
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        :
                                                        <Grid item xs={12} sm={6}  key={"pool_section_custom_image_"+idx}>
                                                            <input type="text" placeholder={"custom_image" + idx + "_hidden"} value={poolSection[idx]["customImage"]} name={"custom_image_"+idx} ref={register({required: "Custom Image"})} readOnly hidden/>
                                                            <FormControl
                                                                variant="outlined"
                                                                error={!!errors['custom_image_'+ idx]}
                                                                className={classes.paramsFormField}
                                                            >
                                                                <InputLabel id={'custom_image_'+ idx}>Image</InputLabel>
                                                                <Select
                                                                    onChange={event => {
                                                                        handlePoolFieldChange(event.target.value,idx,"customImage")
                                                                        errors['custom_image_'+ idx] = false;
                                                                    }}
                                                                    label="Image"
                                                                    value={poolSection[idx]["customImage"]}
                                                                >
                                                                    {
                                                                        selectedBlueprintDetails["allowedCustomImages"] ?
                                                                            selectedBlueprintDetails["allowedCustomImages"].map(
                                                                                customImageId => <MenuItem key={customImageId} value={customImageId}>{ImageDetails["customImageDetails"][customImageId]["name"] + " | " + ImageDetails["customImageDetails"][customImageId]["os"] + " | " + ImageDetails["customImageDetails"][customImageId]["version"]}</MenuItem>
                                                                            )
                                                                            :
                                                                            <MenuItem key={""} value={""}>{"Not Found"}</MenuItem>
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                }


                                            {/*Admin Config - Start */}
                                            <Grid item xs={12} sm={12} key={"pool_section_admin_section_" + idx}>
                                                <div className={classes.paramsAdministratorTitle}>
                                                    Administrator Account
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} key={"pool_section_admin_type_" + idx}>
                                                <div className={classes.paramsAdministratorRadioButtonHolder}>
                                                    Authentication Type :
                                                    <span className={classes.paramsAdministratorRadioButtons}>
                                                            <RadioGroup value={poolSection[idx]["adminType"]} onChange={ event => {handlePoolFieldChange(event.target
                                                                .value, idx, "adminType")}}>
                                                                <FormControlLabel key="ssh" value="ssh" control={<BlueRadio />} label="SSH Public Key" />
                                                                <FormControlLabel key="password" value="password" control={<BlueRadio />} label="Password" />
                                                            </RadioGroup>
                                                        </span>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6} key={"pool_section_admin_name_" + idx}>
                                                <TextField
                                                    name={"admin_name_" + idx}
                                                    label="Admin Name"
                                                    variant="outlined"
                                                    error={!!errors["admin_name_" + idx] || adminName}
                                                    onChange={event => {
                                                        adminNameCheck(event.target.value);
                                                    }}
                                                    className={classes.paramsFormFieldForCheck}
                                                    helperText={errors["admin_name_" + idx] && errors["admin_name_" + idx].message}
                                                    inputRef={register({ required: "Admin Name is Required",
                                                    })}
                                                />

                                                {
                                                    adminName?<div style={{color:"red",fontSize:12,paddingLeft:"15px",paddingTop:"5px",background:"white",marginLeft:"15px",marginRight:"35px"}}>Username should be between 5 to 12 characters</div>:<div/>
                                                }
                                            </Grid>
                                            {
                                                poolSection[idx]["adminType"] === "ssh" ?
                                                    <Grid item xs={12} sm={6} key={"pool_section_ssh_" + idx}>
                                                        <TextField
                                                            name={"ssh_public_key" + idx}
                                                            label="SSH Public Key"
                                                            variant="outlined"
                                                            error={!!errors["ssh_public_key" + idx]}
                                                            className={classes.paramsFormFieldForAutentication}
                                                            helperText={errors["ssh_public_key" + idx] && errors["ssh_public_key" + idx].message}
                                                            inputRef={register({
                                                                 required: "SSH Public Key is Required"
                                                            })}
                                                        />
                                                    </Grid>
                                                    :
                                                    <Grid item xs={12} sm={6} key={"pool_section_admin_password_" + idx}>
                                                        <TextField
                                                            name={"password_" + idx}
                                                            label="Password"
                                                            variant="outlined"
                                                            type="password"
                                                            onChange={event => {
                                                                passwordCheck(event.target.value);
                                                            }}
                                                            error={!!errors["password_" + idx]||password||upperLetter===0||lowerLetter===0||specialCharacterforPassword===0||numaric===0}
                                                            className={ (password || upperLetter===0||lowerLetter===0||specialCharacterforPassword===0||numaric===0)?classes.paramsFormFieldForCheck:classes.paramsFormFieldForAutentication}

                                                            helperText={errors["password_" + idx] && errors["password_" + idx].message}
                                                            inputRef={register({
                                                                 required: "Password is Required",
                                                                  })}

                                                            />
                                                        {
                                                            password?<div  style={{color:"red",fontSize:12,paddingLeft:"15px",paddingTop:"5px",background:"white",marginLeft:"15px",marginRight:"35px"}}>Password should be atleast 12 characters long, contain at least one uppercase, one lowercase, one numeric and one special character </div>:(upperLetter===0||lowerLetter===0||specialCharacterforPassword===0||numaric===0)?<div  style={{color:"red",fontSize:12,paddingLeft:"15px",paddingTop:"5px",background:"white",marginLeft:"15px",marginRight:"35px"}}>Password should be atleast 12 characters long, contain at least one uppercase, one lowercase, one numeric and one special character.</div>:<div/>
                                                        }
                                                    </Grid>
                                                }
                                                {/*Admin Config - End */}
                                            </Grid>
                                        </Paper>
                                    )
                                }
                                {/*Pool Section - End*/}
                            </Grid>
                        :
                        sessionStorage.getItem("resource") === "hpc-cache" ?
                            <Grid
                                container
                                direction="row"
                                justify="space-evenly"
                                alignItems="stretch"
                            >
                                {/*Resource Group*/}
                                <Grid item xs={12} sm={6}>
                                    <input type="text" placeholder="resource_group_hidden" value={selectedResourceGroup} name="resource_group" ref={register({required: "Select Resource Group"})} readOnly hidden/>
                                    <FormControl
                                        variant="outlined"
                                        error={!!errors.resource_group}
                                        className={classes.paramsFormField}
                                    >
                                        <InputLabel id="resource_group">Resource Group</InputLabel>
                                        <Select
                                            value={selectedResourceGroup}
                                                onChange={event =>
                                                {
                                                    setSelectedResourceGroup(event.target.value);
                                                    setValue("resource_group", event.target.value);
                                                    errors.resource_group = false;
                                                    deploymentNameCheck(deploymentName, event.target.value);
                                                }}
                                            label="Resource Group"
                                        >
                                            {
                                                resourceGroup.map( resource =>
                                                    <MenuItem key={resource["name"]} value={resource["name"]}>{resource["name"]}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {/*Location*/}
                                <Grid item xs={12} sm={6}>
                                    <input type="text" placeholder="location_hidden" value={selectedLocation} name="location" ref={register({required: "Select Location"})} readOnly hidden/>
                                    <FormControl
                                        variant="outlined"
                                        error={!!errors.location}
                                        className={classes.paramsFormField}
                                    >
                                        <InputLabel id="location">Location</InputLabel>
                                        <Select
                                            value={selectedLocation}
                                            onChange={event => {
                                                setSelectedLocation(event.target.value);
                                                setValue("location", event.target.value);
                                                errors.location = false;
                                                getVMSize(event.target.value);
                                                getStorageAccount(event.target.value);
                                            }}
                                            label="Location"
                                        >
                                            {
                                                regionList.map(resource =>
                                                    <MenuItem key={resource["key"]} value={resource["key"]}>{resource["title"]}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                
                                {/*Cache Name*/}
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="text"
                                        name="cache_name"
                                        label="Cache Name"
                                        variant="outlined"
                                        onChange={event => {
                                            deploymentNameCheck(event.target.value, selectedResourceGroup);
                                        }}
                                        error={!!errors.cache_name}
                                        className={classes.paramsFormFieldForCheck}
                                        helperText={errors.cache_name && errors.cache_name.message}
                                        inputRef={register({
                                            required: "Cache Name is Required",
                                        })}
                                    />
                                    {
                                        deploymentNameStatusForLength === 0 ? <p /> : selectedResourceGroup.length > 0 ? (specialCharacter ? <div style={{ color: "red", fontSize: 12, paddingLeft: "15px", paddingTop: "5px", background: "white", marginLeft: "15px", marginRight: "35px" }}> Deployment Name should contains letters and numbers</div> : deploymentNameStatusForLength > 15 ? (<div style={{ color: "red", fontSize: 12, paddingLeft: "15px", paddingTop: "5px", background: "white", marginLeft: "15px", marginRight: "35px" }}>Deployment Name should be between 1 to 15 characters</div>) : (deploymentNameStatus ? <div style={{ color: "red", fontSize: 12, paddingLeft: "15px", paddingTop: "5px", background: "white", marginLeft: "15px", marginRight: "35px" }}>The deployment name already in use!!</div> : (correctdeploymentName ? <div style={{ color: "green", fontSize: 12, paddingLeft: "15px", paddingTop: "5px", background: "white", marginLeft: "15px", marginRight: "35px" }} >{deploymentName} is available</div> : <div />))) : <div style={{ color: "red", fontSize: 12, paddingLeft: "15px", paddingTop: "5px", background: "white", marginLeft: "15px", marginRight: "35px" }}>Resource Group should not be empty</div>
                                    }
                                </Grid>

                                {/* Maximum throughput */}
                                <Grid item xs={12} sm={6} name={"maximum_throughput"}>
                                    <input type="text" placeholder="maximum_throughput_hidden" value={selectedMaxThroughput} name="maximum_throughput" ref={register({required: "Select Maximum Throughput"})} readOnly hidden/>
                                    <FormControl
                                        variant="outlined"
                                        error={!!errors.maximum_throughput}
                                        className={classes.paramsFormField}
                                    >
                                        <InputLabel id="maximum_throughput">Maximum Throughput</InputLabel>
                                        <Select
                                            value={selectedMaxThroughput}
                                                onChange={event => {
                                                setSelectedMaxThroughput(event.target.value);
                                                setValue("maximum_throughput", event.target.value);
                                                errors.maximum_throughput = false;
                                            }}
                                            label="Maximum Throughput"
                                            >
                                                {
                                                    selectedBlueprintDetails["allowedMaximumThroughput"] ?
                                                        selectedBlueprintDetails["allowedMaximumThroughput"].map(
                                                            maxThroughput => <MenuItem key={maxThroughput} value={maxThroughput}>{maxThroughput}</MenuItem>
                                                        )
                                                        :
                                                        <MenuItem key={""} value={""}>{"Not Found"}</MenuItem>
                                                }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {/* Cache Size */}
                                <Grid item xs={12} sm={6} name={"cache_size"}>
                                    <input type="text" placeholder="cache_size_hidden" value={selectedCacheSize} name="cache_size" ref={register({required: "Select Cache Size"})} readOnly hidden/>
                                    <FormControl
                                        variant="outlined"
                                        error={!!errors.cache_size}
                                        className={classes.paramsFormField}
                                    >
                                        <InputLabel id="cache_size">Cache Size</InputLabel>
                                        <Select
                                            value={selectedCacheSize}
                                            disabled={selectedMaxThroughput === "" ? true : false}
                                            onChange={event => {
                                                setSelectedCacheSize(event.target.value);
                                                setValue("cache_size", event.target.value);
                                                errors.cache_size = false;
                                            }}
                                            label="Cache Size"
                                            >
                                                {
                                                    selectedBlueprintDetails["cacheSize"] && selectedMaxThroughput !== "" ?
                                                        selectedBlueprintDetails.cacheSize[hpcDetails.cacheSize[selectedMaxThroughput]].map(
                                                            size => <MenuItem key={size} value={size}>{size}</MenuItem>
                                                        )
                                                        :
                                                        <MenuItem key={""} value={""}>{"Not Found"}</MenuItem>
                                                }
                                        </Select>
                                        {selectedMaxThroughput === "" ? <FormHelperText>* Select Maximum Throughput</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>

                                {/* Disk encryption key type */}
                                <Grid item xs={12} sm={6} name={"disk_encryption_key_type"}>
                                    <input type="text" placeholder="disk_encryption_key_type" value={selectedEncryptionKeyType} name="disk_encryption_key_type" ref={register({required: "Select Disk Encryption Key Type"})} readOnly hidden/>
                                    <FormControl
                                        variant="outlined"
                                        error={!!errors.disk_encryption_key_type}
                                        className={classes.paramsFormField}
                                    >
                                        <InputLabel id="disk_encryption_key_type">Disk Encryption Key Type</InputLabel>
                                        <Select
                                            value={selectedEncryptionKeyType}
                                            onChange={event => {
                                                setSelectedEncryptionKeyType(event.target.value);
                                                setValue("disk_encryption_key_type", event.target.value);
                                                errors.disk_encryption_key_type = false;
                                            }}
                                            label="Disk Encryption Key Type"
                                            >

                                            <MenuItem key={"system_provided"} value={"system_provided"}>{"System Provided"}</MenuItem>
                                            <MenuItem disabled key={"customer_managed"} value={"customer_managed"}>{"Customer Managed"}</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>

                        :sessionStorage.getItem("resource") === "data-ingest"?
                            <Grid
                                container
                                direction="row"
                                justify="space-evenly"
                                alignItems="flex-end"
                            >

                            {/*HPC Cache Name*/}
                            <Grid item xs={12} sm={6}>
                                <input type="text" placeholder="hpc_cache_hidden" value={selectedHpcCache.name} name="hpc_cache" ref={register({required: "Select HPC cache"})} readOnly hidden/>
                                <FormControl
                                    variant="outlined"
                                    error={!!errors.hpc_cache}
                                    className={classes.paramsFormField}
                                >
                                    <InputLabel id="hpc_cache">HPC Cache</InputLabel>
                                    <Select
                                        value={selectedHpcCache.name}
                                            onChange={event =>
                                            {
                                                // console.log(event.target.value)
                                                setSelectedHpcCache(event.target.value);
                                                setValue("hpc_cache", event.target.value);
                                                errors.hpc_cache = false;
                                            }}
                                        label="HPC Cache"
                                    >
                                        {/* {
                                            availableHpcCacheList.length ? 
                                            availableHpcCacheList.map( cache =>
                                                // console.log(cache)
                                                <MenuItem key={cache["name"]} value={cache}>{cache["name"] + ", " + cache["location"]}</MenuItem>
                                            )
                                            :
                                            <MenuItem key={""} value={""}>{"Not Found"}</MenuItem>
                                        } */}

                                        <MenuItem key={"cach1"} value={"cahce1"}>{"cache1"}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                            </Grid>

                            {/* Storage Account Type */}
                            <Grid item xs={12} sm={6}>
                                <div className={classes.paramsAdministratorRadioButtonHolder}>
                                    Storage Account Type :
                                    <span className={classes.paramsAdministratorRadioButtons}>
                                        <input type="text" placeholder="storage_account_type_hidden" value={storageAccountType} name="storage_account_type" ref={register({required: "Select Storage Account Type"})} readOnly hidden/>
                                        <RadioGroup value={storageAccountType} onChange={event => {setStorageAccountType(event.target.value);setValue("storage_account_type", event.target.value)}}>
                                            <FormControlLabel key="new" value="new" control={<BlueRadio />} label="New"  />
                                            <FormControlLabel key="existing" value="existing"  control={<BlueRadio />} label="Existing"  />
                                        </RadioGroup>
                                    </span>
                                </div>
                            </Grid>
                            
                            {/*Storage Account Name*/}
                            {
                                storageAccountType === "new" ?
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            type="text"
                                            name="storage_account_name"
                                            label="Storage Account Name"
                                            variant="outlined"
                                            value={storageAccountName}
                                            onChange={event => {
                                                setStorageAccountName(event.target.value);
                                                setValue("storage_account_name", event.target.value.name);
                                                errors.storage_account_name = false;
                                            }}
                                            error={!!errors.storage_account_name}
                                            className={classes.paramsFormFieldForCheck}
                                            helperText={errors.storage_account_name && errors.storage_account_name.message}
                                            inputRef={register({
                                                required: "Storage Account Name is Required",
                                            })}
                                        />
                                    </Grid>
                                :
                                    <Grid item xs={12} sm={6}>
                                        <input type="text" placeholder="storage_account_name_hidden" value={selectedStorageAccount.name} name="storage_account_name" ref={register({required: "Select Storage Account Name"})} readOnly hidden/>
                                        <FormControl
                                            variant="outlined"
                                            error={!!errors.storage_account_name}
                                            className={classes.paramsFormField}
                                        >
                                            <InputLabel id="hpc_cache">Storage Account Name</InputLabel>
                                            <Select
                                                value={selectedStorageAccount.name}
                                                    onChange={event =>
                                                    {
                                                        setSelectedStorageAccount(event.target.value);
                                                        setValue("storage_account_name", event.target.value.name);
                                                        errors.storage_account_name = false;
                                                    }}
                                                label="Storage Account Name"
                                            >
                                                {
                                                    availableStorageAccountList.length ? 
                                                    availableStorageAccountList.filter(sa => sa.location.includes("westus") || sa.location.includes("eastus") || sa.location.includes("southcentralus")).map( storageAccount =>
                                                        <MenuItem key={storageAccount["name"]} value={storageAccount}>{storageAccount["name"] + ", " + storageAccount["location"]}</MenuItem>
                                                    )
                                                    :
                                                    <MenuItem key={""} value={""}>{"Not Found"}</MenuItem>
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                            }
                          

                            {
                                storageAccountType === "new" ?
                                
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-evenly"
                                    alignItems="flex-end"
                                >

                                    {/*Resource Group*/}
                                    <Grid item xs={12} sm={6}>
                                        <input type="text" placeholder="resource_group_hidden" value={selectedResourceGroup} name="resource_group" ref={register({required: "Select Resource Group"})} readOnly hidden/>
                                        <FormControl
                                            variant="outlined"
                                            error={!!errors.resource_group}
                                            className={classes.paramsFormField}
                                        >
                                            <InputLabel id="resource_group">Resource Group</InputLabel>
                                            <Select
                                                value={selectedResourceGroup}
                                                    onChange={event =>
                                                    {
                                                        setSelectedResourceGroup(event.target.value);
                                                        setValue("resource_group", event.target.value);
                                                        errors.resource_group = false;
                                                    }}
                                                label="Resource Group"
                                            >
                                                {
                                                    resourceGroup.map( resource =>
                                                        <MenuItem key={resource["name"]} value={resource["name"]}>{resource["name"]}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    {/*Location*/}
                                    <Grid item xs={12} sm={6}>
                                        <input type="text" placeholder="location_hidden" value={selectedLocation} name="location" ref={register({required: "Select Location"})} readOnly hidden/>
                                        <FormControl
                                            variant="outlined"
                                            error={!!errors.location}
                                            className={classes.paramsFormField}
                                        >
                                            <InputLabel id="location">Location</InputLabel>
                                            <Select
                                                value={selectedLocation}
                                                onChange={event => {
                                                    setSelectedLocation(event.target.value);
                                                    setValue("location", event.target.value);
                                                    errors.location = false;
                                                }}
                                                label="Location"
                                            >
                                                {
                                                    regionList.map(resource =>
                                                        <MenuItem key={resource["key"]} value={resource["key"]}>{resource["title"]}</MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    
                                </Grid>
                                :
                                <span></span>
                            }
                                
                            {/*Path from data to be ingested*/}
                            {/* <Grid item xs={12} sm={6} name={"data_path"}>
                                <input type="text" placeholder="data_path_hidden" value={selectedDataPath} name="data_path" ref={register({required: "Select Data Path"})} readOnly hidden/>
                                <FormControl
                                    variant="outlined"
                                    error={!!errors.data_path}
                                    className={classes.paramsFormField}
                                >
                                    <InputLabel id="data_path">Data Path</InputLabel>
                                    <Select
                                        value={selectedDataPath}
                                            onChange={event =>
                                            {
                                                setSelectedDataPath(event.target.value);
                                                console.log(event.target.value)
                                                setValue("data_path", event.target.value);
                                                errors.data_path = false;
                                            }}
                                        label="Data Path"
                                    >
                                        <MenuItem key={"url"} value={"url"}>Url</MenuItem>
                                        <MenuItem key={"blob_sas_key"} value={"blob_sas_key"}>Blob SAS key</MenuItem>
                                        <MenuItem key={"local_path"} value={"local_path"}>Local Path</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid> */}

                            {/* <Grid item xs={12} sm={6}></Grid> */}
                            
                        </Grid>
                        :''
                    }
                </Paper>

                <div className={classes.subscriptionButtons}>
                    <BackButton className={classes.subscriptionButton} onClick={handleBack}>
                        <ArrowBackIcon className={classes.subscriptionButtonBackIcon} />
                        Back
                    </BackButton>
                    <NextButton className={classes.subscriptionButton} type="submit">
                        Next
                        <ArrowForwardIcon className={classes.subscriptionButtonNextIcon}  />
                    </NextButton>
                </div>
            </form>
            </main>
        </div>
    )
}