import {Grid} from "@material-ui/core";
import React from "react";
import Link from "@material-ui/core/Link";

export const Headline = props => (
    <React.Fragment>
        <div className={props.classes.headingAppBarSpace}/>
        <Grid container
              direction="row"
              justify="flex-start"
              alignItems="center">
            <Grid item xs={6} sm={6}>
                <div className={props.classes.heading}>
                    Custom Blueprints
                </div>
            </Grid>
            <Grid item xs={6} sm={6}>
                <Link className={props.classes.submitBlueprint} onClick={props.handleSubmitBlueprintOpen}>
                    Request Blueprint
                </Link>
            </Grid>
        </Grid>
    </React.Fragment>
);