import React from "react";
import { Grid, Button } from "@material-ui/core";
import vmimg from "../../assert/images/vm.png";
import hpcimg from "../../assert/images/hpcCache.png";
import batchimg from "../../assert/images/batch.png";
import { Link } from 'react-router-dom';
import TextTruncate from 'react-text-truncate';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const Blueprints = props => (
    <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        style={{marginTop:'10px',color:'black'}}
    >
        {
            props.data.length > 0 ?
                props.data.map(value => {
                    const url = "/blueprint/" + (value.name).split(' ').join('-');
                    return (
                        <Grid key={value.id} item={true} xs={12} sm={3} component={Link} to={url} style={{ padding: '20px',margin:"20px", boxShadow: "1px 2px 6px #9E9E9E", borderRadius: '4px' , textDecoration: "none", backgroundColor: 'white',height:'150px' }} id={value.id} onClick={()=>props.handleMenu(value.name)} >
                            <div style={{ }}>
                                    <div  >
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="stretch"
                                        style={{ marginBottom: '10px'}}
                                    >
                                        <Grid item xs={2}>
                                            {
                                                value.type === 'simple' ? <img id={value.id} src={vmimg} alt="vm" className={props.classes.blueprintIcon} /> 
                                                : value.type === 'batch' ? <img id={value.id} src={batchimg} alt="vm" className={props.classes.blueprintIcon} />
                                                : value.type === 'hpc-cache' ? <img id={value.id} src={hpcimg} alt="vm" className={props.classes.blueprintIcon} /> 
                                                :''
                                            }
                                        </Grid>
                                        <Grid item xs={10}>
                                            <div id={value.id} style={{ fontSize: '14px',paddingLeft:'10px',color:'black' }} >   {value.name}</div>
                                        </Grid>
                                    </Grid>
                                        <hr id={value.id}
                                        style={{
                                                marginBottom:'10px',
                                                color: '#E9E1E2',
                                                height: '0.2px',
                                            }}
                                    />
                                    <TextTruncate
                                        style={{ fontSize: '14px', color: 'black' }}
                                        line={3}
                                        element="span"
                                        truncateText="…"
                                        text={value.tagLine}
                                    />
                                   
                                        </div> 
                                           
                            </div>
                        </Grid>
                        )
                }) : <div />
            
        }
        <Grid item xs={12} style={{ textAlign:'center' }}>
            {
                props.count === props.data.length ? <div /> : <div onClick={() => props.fetchingBlueprint("", props.data.length)}><Button>Load More&nbsp;<ExpandMoreIcon /></Button></div>
            }
           
        </Grid>
        </Grid>
        )
