import { Grid } from "@material-ui/core";
import React from "react";

export const Headline = props => (
    <React.Fragment>
        <div className={props.classes.headingAppBarSpace} />
        <Grid container
            direction="row"
            justify="flex-start"
            alignItems="center">
            <Grid item xs={6} sm={6}>
                <div className={props.classes.heading}>
                    Cost Analysis <sup style={{ fontSize: 12}}>(Preview)</sup>
                </div>
            </Grid>
        </Grid>
    </React.Fragment>
);