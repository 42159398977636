import {Grid } from "@material-ui/core";
import React from "react";

export const Headline = props => (
    <React.Fragment>
        <div className={props.classes.headingAppBarSpace}/>
        <Grid container
              direction="row"
              justify="flex-start"
              alignItems="center">
            <Grid item xs={12} sm={12}>
                <div className={props.classes.deployHeading}>
                    Blueprints {` / ${localStorage.getItem("blueprintName")}`}
                </div>
            </Grid>
        </Grid>
    </React.Fragment>
);