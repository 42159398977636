import React, { Component } from "react";
import {
    msalApp,
    requiresInteraction,
    fetchMsGraph,
    isIE,
    GRAPH_ENDPOINTS,
    GRAPH_SCOPES,
    GRAPH_REQUESTS
} from "./auth-utils";

// If you support IE, our recommendation is that you sign-in using Redirect APIs
const useRedirectFlow = isIE();
// const useRedirectFlow = true;

export async function CheckAccountTrailPeriod() {
    const account = msalApp.getAccount();
    if (account.userName) {
        await fetch("/api/customer/user?email=" + account.userName + "&fields=Status,TrailDetails", {
            headers: {
                "Content-Type": "application/json",
            },
        }).then(res => {
            return res.text();
        }).then(val => {
            if (val) {
                const response = JSON.parse(val);
                let status = response.status;

                if (response.trailDetails)
                    status = (!(response.status === "Active" && response.trailDetails.status === "Active") ? status : ((Date.now()) <= (response.trailDetails.startTime + (response.trailDetails.trailPeriod * 10) ? status : "TrailExpired")))

                if (response.trailDetails && response.trailDetails.status === "Active")
                    sessionStorage.setItem("trail", "Active");

                sessionStorage.setItem("accountStatus", status);
            }
        });
    }
}

export default C =>
    class AuthProvider extends Component {
        constructor(props) {
            super(props);

            this.state = {
                account: null,
                error: null,
                graphProfile: null,
                subscriptionList: null,
                accountStatus: null,
                message: null,
                fetchData:false
            };
        }

        async acquireToken(request, redirect) {
            return msalApp.acquireTokenSilent(request).catch(error => {
                // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
                // due to consent or interaction required ONLY
                if (requiresInteraction(error.errorCode)) {
                    return redirect
                        ? msalApp.acquireTokenRedirect(request)
                        : msalApp.acquireTokenPopup(request);
                } else {
                    console.error('Non-interactive error:', error.errorCode)
                }
            });
        }
      

        async onSignIn(redirect) {
            if (redirect) {
                return msalApp.loginRedirect(GRAPH_REQUESTS.LOGIN);
            }

            const loginResponse = await msalApp
                .loginPopup(GRAPH_REQUESTS.LOGIN)
                .catch(error => {
                    this.setState({
                        error: error.message
                    });
                });

            if (loginResponse) {
                this.setState({
                    account: loginResponse.account,
                    error: null,
                    fetchData:true
                });

                const tokenResponse = await this.acquireToken(
                    GRAPH_REQUESTS.LOGIN
                ).catch(error => {
                    this.setState({
                        error: error.message
                    });
                });

                if (tokenResponse) {
                    const graphProfile = await fetchMsGraph(
                        GRAPH_ENDPOINTS.ME,
                        tokenResponse.accessToken
                    ).catch(() => {
                        this.setState({
                            error: "Unable to fetch Graph profile."
                        });
                    });

                    if (graphProfile) {
                        sessionStorage.setItem('graph_profile_id', graphProfile.id);
                        this.setState({
                            graphProfile
                        });
                    }

                    if (tokenResponse.scopes.indexOf(GRAPH_SCOPES.MAIL_READ) > 0) {
                        return this.readMail(tokenResponse.accessToken);
                    }
                }

                const Name = loginResponse.account.userName.split('@');

                const OrgBodyParams = {
                    "OrganizationId": Name[1],
                    "Name": loginResponse.account.name,
                };

                await fetch("/api/customer/organization", {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(OrgBodyParams)
                }).then(res => {
                    return res.text();
                }).then(response => {
                    try {
                        if (response) {
                            const tempData = JSON.parse(response);
                            sessionStorage.setItem("orgPermission", JSON.stringify(tempData.permission));
                        }
                        return response;
                    } catch (ex) {
                        console.log(ex);
                    }

                });


                const bodyParams = {
                    "email" : loginResponse.account.userName,
                    "name" : loginResponse.account.name,
                    "loginTime" : Date.now(),
                    "organization" : "all",
                    "Location" : "--",
                    "root": "login"
                };
                await fetch("/api/customer/user", {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(bodyParams),
                }).then(res => {
                    return res.text();
                }).then(response=> {
                    try {
                        if(!response || response === "null"){
                            this.setState({
                                accountStatus: "Not Found",
                                fetchData:false
                            })
                            sessionStorage.clear();
                            return
                        }
                        let tempData = JSON.parse(response);
                       
                        if(tempData.permission){
                            sessionStorage.setItem("permission", JSON.stringify(tempData.permission));
                        }
                                                 
                        let status = tempData.status;

                        if (tempData.trailDetails)
                            status = (!(tempData.status === "Active" && tempData.trailDetails.status === "Active") ? status : ((Date.now()) <= (tempData.trailDetails.startTime + (tempData.trailDetails.trailPeriod * 10))) ? status : "TrailExpired")

                        if (tempData.trailDetails && tempData.trailDetails.status === "Active")
                            sessionStorage.setItem("trail", "Active");

                        sessionStorage.setItem("accountStatus", status);

                        if(status !== "Active")
                            sessionStorage.clear();

                        this.setState({
                            accountStatus: status,
                            fetchData:false
                        })

                        return response;
                    }catch (ex) {
                        console.log(ex);
                    }

                });
            }
        }

        onSignOut() {
            msalApp.logout();
            sessionStorage.clear();
        }

        render() {
            
            return (
                <C
                    {...this.props}
                    account={this.state.account}
                    accountStatus={this.state.accountStatus}
                    error={this.state.error}
                    graphProfile={this.state.graphProfile}
                    onSignIn={() => this.onSignIn(useRedirectFlow)}
                    onSignOut={() => this.onSignOut()}
                    onRequestEmailToken={() => this.onRequestEmailToken()}
                    fetchData={this.state.fetchData}
                    />
            );
        }
    };
