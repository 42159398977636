import React from "react";



export default function Index() {
    
    return(
        <div>
            <h2>Frequently Asked Questions</h2>
            <ul>
                <li>  <h3>How can I track the deployment status?</h3> </li>
                    <p>Once a deployment has been initiated, you can keep track of it from the History page. When a deployment is completed, you will receive a notification email with update on status and additional information such as a direct link to the portal, or if there is an issue, the specific error message.</p>
            </ul>
            <h2>Troubleshooting</h2>
            <ul>
                <li><h3>Subscription list is empty</h3></li>
                    <p> Please check with your IT Administrator if you have Owner or Contributor access on at least one subscription. You can verify this by logging into the Azure <a href="https://portal.azure.com/" target="_blank"  rel="noopener noreferrer">portal</a> and clicking on Subscriptions.</p>
                <li><h3>  Subscription list takes too long to load</h3></li>
                     <p> This can be caused by a pop-up blocker on your browser. To address this, refresh the page or disable the pop-up blocker for the site.</p>
            </ul>
        </div>
);
    
}