import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {msalApp} from "../components/Authentication/auth-utils";


/**
 * @return {boolean}
 */
export function CheckToken() {
    return !!msalApp.getAccount();
}

export function CheckAccountTrailPeriod() {
    return (sessionStorage.getItem("accountStatus") !== "TrailExpired");
}

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={ (props) => {
            if(!CheckToken()) {
                return <Redirect to={{pathname: '/', state: {from: props.location}}}/>;
            }
            else {
                if (CheckAccountTrailPeriod())
                    return <Component {...props} />;
                else {
                    if(props.location.state === undefined || (props.location.state.from.pathname !== "/account.expired" )) {
                        return <Redirect to={{pathname: '/account.expired', state: {from: props.location}}}/>;
                    }
                    else{
                        return <Component {...props} />;
                    }
                }
            }
        }
    }
    />
);

export default PrivateRoute;