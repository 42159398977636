import React from "react";
import { Grid, Paper } from "@material-ui/core";
import vmimg from "../../assert/images/vm.png";
import batchimg from "../../assert/images/batch.png";
import { DeployButton } from "../../styles";
import jsonData from "../../assert/config/Images.json";
import hpcimg from "../../assert/images/hpcCache.png";


const BlueprintHeading = props => (
    <div className={props.classes.blueprintDetailsHeading}>
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            style={{ paddingLeft: '10px' }}
        >
            <Grid item xs={1} sm={1}>
                <div className={props.classes.blueprintHeadingIconCircle}>
                    {/* {props.type === 'simple' ? <img id={props.id} src={vmimg} alt="vm" className={props.classes.blueprintIcon} /> : <img id={props.id} src={batchimg} alt="vm" className={props.classes.blueprintIcon} />} */}
                {   
                    props.type === 'simple' ? <img id={props.id} src={vmimg} alt="vm" className={props.classes.blueprintIcon} /> 
                    : props.type === 'batch' ? <img id={props.id} src={batchimg} alt="vm" className={props.classes.blueprintIcon} />
                    : props.type === 'hpc-cache' ? <img id={props.id} src={hpcimg} alt="vm" className={props.classes.blueprintIcon} /> 
                    :''
                }
                </div>
            </Grid>

            <Grid item xs={11} sm={11}>
                {props.activeMenuName}
            </Grid>
        </Grid>
    </div>
);

const Deploy = props => (
    <div className={props.classes.blueprintDetailsDeployButton}>
        <DeployButton onClick={() => props.redirectHandler(props.activeMenuName)}>
            Deploy >>
        </DeployButton>
    </div>
);


export const BlueprintDetails = props => (
    
    <Paper elevation={3} className={props.classes.blueprintDetailsPaper} key="key">
        {
            props.data.map((value, index) => {
                if (props.activeMenu === value.id) {
                    localStorage.setItem("templateId", value.id);
                    localStorage.setItem("tags", value.tags);
                    sessionStorage.setItem("resource", value.type);
                    sessionStorage.setItem("customImage", value.customImage);
                    sessionStorage.setItem("selectedBlueprintDetails", JSON.stringify(value));
                    let content = value.description;
                    return (
                        <React.Fragment key={index} >

                            <BlueprintHeading classes={props.classes} activeMenuName={value.name} type={value.type} />

                            <div className={props.classes.blueprintDetailsParagraph} key={index}>
                                <div key={value.id} className={props.classes.blueprintDetailsParagraphStyle}>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="stretch"
                                        spacing= {2}
                                    >
                                        <Grid item xs={7} style={{ textAlign:'justify',paddingLeft:'10px' }}>
                                            {content}
                                        </Grid>
                                        <Grid item xs={5} >
                                            <Grid
                                                container
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="center"
                                                style={{paddingLeft:'40px'}}
                                            >
                                                <Grid item xs={12}>
                                                    <div><strong>Type </strong> : {(value.type).toUpperCase()} </div>
                                                </Grid>
                                                <Grid item xs={12} >
                                                   
                                                    {value.allowedOS.length>0?
                                                        <div>
                                                            <div style={{ paddingTop: '10px' }}><strong>Operating System</strong> </div>
                                                            {
                                                                (value.allowedOS).map((data) => {
                                                                    return (<li style={{paddingLeft:"20px"}}>{jsonData["osDetails"][data]["name"]} | {jsonData["osDetails"][data]["version"]} </li>)
                                                                })
                                                            }   
                                                        </div> :""
                                                    }
                                                </Grid>

                                                <Grid item xs={12} >
                                                    {
                                                        value.customImage ? < div style={{ paddingTop: '10px' }}><strong> Custom Image</strong></div> : <div />
                                                    }
                                                </Grid>
                                                
                                                <Grid item xs={12}>
                                                    {
                                                        value.customImage ?
                                                            (value.allowedCustomImages).map((data) => {
                                                                // console.log(data)
                                                                return (<li style={{ paddingLeft: "20px" }}>{jsonData["customImageDetails"][data]["name"]} | {jsonData["customImageDetails"][data]["osType"]} | {jsonData["customImageDetails"][data]["version"]} </li>)
                                                            })
                                                            : <div/>

                                                        }
                                                </Grid>
                                                </Grid>
                                        </Grid>

                                    </Grid>
                                    
                                </div>
                            </div>

                            <Deploy classes={props.classes} redirectHandler={props.redirectHandler} activeMenuName={value.name} />

                        </React.Fragment>
                    )
                } else {
                    return null
                }
            })
        }
    </Paper>


);
